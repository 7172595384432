import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress, ToggleButtonGroup, ToggleButton, Button, Popover } from '@mui/material';
import Chart from 'react-apexcharts';
import { useGetFilterUserPlansMutation } from 'services/auth-service';
import { DropDown } from 'assets/general/DropDown';
import { EDSCalendarIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSCalendarIcon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const {
  REACT_APP_SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID,
  REACT_APP_SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID,
  REACT_APP_SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID,
  REACT_APP_SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID,
  REACT_APP_SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID,
  REACT_APP_SENDING_WARMUP_YEARLY_SCALE_PRICE_ID,
  REACT_APP_LEADS_MONTHLY_SKYROCKET_PRICE_ID,
  REACT_APP_LEADS_MONTHLY_GROWTH_PRICE_ID,
  REACT_APP_LEADS_MONTHLY_SCALE_PRICE_ID,
  REACT_APP_LEADS_YEARLY_SKYROCKET_PRICE_ID,
  REACT_APP_LEADS_YEARLY_GROWTH_PRICE_ID,
  REACT_APP_LEADS_YEARLY_SCALE_PRICE_ID
} = process.env;

const PlanChart = () => {
  const [userPlans] = useGetFilterUserPlansMutation();
  const [planDetailsResponse, setPlanDetailsResponse] = useState();
  const [plans, setPlans] = useState();
  const [date, setDate] = useState();
  const [alignment, setAlignment] = useState('Sending Warmups');

  const getUserPlans = async () => {
    const { data } = await userPlans({
      ...(date?.start && { start: date?.start }),
      ...(date?.end && { end: date?.end })
    });
    setPlanDetailsResponse(data);
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const planDetailsChartOption = () => {
    return {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      colors: ['#E91E63', '#9C27B0', '#008ffb', '#7E36AF', '#D9534F', '#E91E63'],

      plotOptions: {
        bar: {
          borderRadius: 3,
          horizontal: true
        }
      },
      xaxis: {
        categories: ['Monthly Growth', 'Monthly Skyrocket', 'Monthly 10X Scale', 'Yearly Growth', 'Yearly Skyrocket', 'Yearly 10X Scale']
      },

      yaxis: {
        labels: {
          style: {
            fontSize: '13px',
            fontWeight: 600
          }
        }
      },
      fill: {
        colors: [
          function () {
            const color = ['#027EF4', '#F3A712', '#E4572E', '#546E7A', '#E91E63'];
            const rand = Math.floor(Math.random() * color.length);
            return color[rand];
          }
        ]
      },
    };
  };

  const planDetailsChartSeries = () => {
    if (planDetailsResponse) {
      if (alignment === 'Sending Warmups') {
        return [
          {
            name: 'Total User',
            data:
              Object.keys(plans)?.length <= 0
                ? []
                : [
                    plans?.sendingWarmups?.MonthlyGrowth?.length,
                    plans?.sendingWarmups?.MonthlySkyrocket?.length,
                    plans?.sendingWarmups?.Monthly10XScale?.length,
                    plans?.sendingWarmups?.YearlyGrowth?.length,
                    plans?.sendingWarmups?.YearlySkyrocket?.length,
                    plans?.sendingWarmups?.Yearly10XScale?.length
                  ]
          }
        ];
      } else if (alignment === 'Leads') {
        return [
          {
            name: 'Total User',
            data:
              Object.keys(plans)?.length <= 0
                ? []
                : [
                    plans?.leads?.MonthlyGrowthLeads?.length,
                    plans?.leads?.MonthlySkyrocketLeads?.length,
                    plans?.leads?.Monthly10XScaleLeads?.length,
                    plans?.leads?.YearlyGrowthLeads?.length,
                    plans?.leads?.YearlySkyrocketLeads?.length,
                    plans?.leads?.Yearly10XScaleLeads?.length
                  ]
          }
        ];
      }
    } else {
      return [];
    }
  };
  useEffect(() => {
    let sendingWarmups = {
      MonthlyGrowth: [],
      MonthlySkyrocket: [],
      Monthly10XScale: [],
      YearlyGrowth: [],
      YearlySkyrocket: [],
      Yearly10XScale: []
    };

    let leads = {
      MonthlyGrowthLeads: [],
      MonthlySkyrocketLeads: [],
      Monthly10XScaleLeads: [],
      YearlyGrowthLeads: [],
      YearlySkyrocketLeads: [],
      Yearly10XScaleLeads: []
    };

    if (planDetailsResponse?.allUsers) {
      planDetailsResponse?.allUsers?.sendingWarmups?.map((item) => {
        switch (item?.subscription?.sendingWarmup?.planId) {
          case REACT_APP_SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID:
            sendingWarmups.MonthlyGrowth.push(item);
            break;

          case REACT_APP_SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID:
            sendingWarmups.MonthlySkyrocket.push(item);
            break;

          case REACT_APP_SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID:
            sendingWarmups.Monthly10XScale.push(item);
            break;

          case REACT_APP_SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID:
            sendingWarmups.YearlyGrowth.push(item);
            break;

          case REACT_APP_SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID:
            sendingWarmups.YearlySkyrocket.push(item);
            break;

          case REACT_APP_SENDING_WARMUP_YEARLY_SCALE_PRICE_ID:
            sendingWarmups.Yearly10XScale.push(item);
            break;
        }
      });
    }

    if (planDetailsResponse?.allUsers) {
      planDetailsResponse?.allUsers?.leads?.map((item) => {
        switch (item?.subscription?.leads?.planId) {
          case REACT_APP_LEADS_MONTHLY_GROWTH_PRICE_ID:
            leads.MonthlyGrowthLeads.push(item);
            break;

          case REACT_APP_LEADS_MONTHLY_SKYROCKET_PRICE_ID:
            leads.MonthlySkyrocketLeads.push(item);
            break;

          case REACT_APP_LEADS_MONTHLY_SCALE_PRICE_ID:
            leads.Monthly10XScaleLeads.push(item);
            break;

          case REACT_APP_LEADS_YEARLY_GROWTH_PRICE_ID:
            leads.YearlyGrowthLeads.push(item);
            break;

          case REACT_APP_LEADS_YEARLY_SKYROCKET_PRICE_ID:
            leads.YearlySkyrocketLeads.push(item);
            break;

          case REACT_APP_LEADS_YEARLY_SCALE_PRICE_ID:
            leads.Yearly10XScaleLeads.push(item);
            break;
        }
      });
    }
    setPlans({ sendingWarmups, leads });
  }, [planDetailsResponse]);

  useEffect(() => {
    getUserPlans();
  }, [date]);
  return (
    <Box
      sx={{
        width: 1,
        backgroundColor: 'white',
        color: '#262626',
        overflow: 'hidden',
        borderRadius: '8px',
        borderColor: '#e6ebf1',
        mt: 2,
        p: 2
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          sx={{
            color: '#28287B',
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: '0px',
            mt: 1
          }}
        >
          Plans
        </Typography>

        <FilterDayWeekMonth setDate={setDate} />
      </Box>
      <ToggleButtonGroup
        sx={{
          width: { xs: '100%', md: '23rem' },
          height: '2.8rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #f2f4f6',
          backgroundColor: '#f2f4f6',
          borderRadius: '0.5rem',
          mt: '24px'
        }}
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton
          sx={{
            width: 1,
            height: '2.3rem',
            borderRadius: '8px',
            ml: '0.2rem',
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: '#ffffff',
              color: '#1e82f7',
              fontSize: '1rem',
              fontWeight: 600
            }
          }}
          value="Sending Warmups"
        >
          Sending Warmups
        </ToggleButton>
        <ToggleButton
          sx={{
            width: 1,
            height: '2.3rem',
            borderRadius: '8px',
            mr: '0.2rem',
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: '#ffffff',
              color: '#1e82f7',
              fontSize: '1rem',
              fontWeight: 600
            }
          }}
          value="Leads"
        >
          Leads
        </ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{ p: 1, mt: 2, borderRadius: '8px', border: '1px solid #f1f1f1' }}>
        {!plans ? (
          <CircularProgress />
        ) : alignment === 'Sending Warmups' ? (
          <Chart options={planDetailsChartOption()} series={planDetailsChartSeries()} type="bar" height={350} />
        ) : (
          <Chart options={planDetailsChartOption()} series={planDetailsChartSeries()} type="bar" height={350} />
        )}
      </Box>
    </Box>
  );
};

export default PlanChart;

function FilterDayWeekMonth({ setDate }) {
  const timelineButtons = [
    {
      name: 'Select Date Range',
      value: {
        start: null,
        end: null
      }
    },
    {
      name: 'Last 7 days',
      value: {
        start: new Date().setDate(new Date().getDate() - 7),
        end: Date.now()
      }
    },
    {
      name: 'Month to date',
      value: { start: new Date().setDate(1), end: Date.now() }
    },
    {
      name: 'Last 4 weeks',
      value: {
        start: new Date().setDate(new Date().getDate() - 28),
        end: Date.now()
      }
    },
    {
      name: 'Last 3 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 3),
        end: Date.now()
      }
    },
    {
      name: 'Last 6 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 6),
        end: Date.now()
      }
    },
    {
      name: 'Last 12 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 12),
        end: Date.now()
      }
    }
  ];
  const [timeline, setTimeline] = React.useState(timelineButtons[0]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          fontSize: '13px',
          fontWeight: 700,
          lineHeight: '16.38px',
          color: '#28287B',
          backgroundColor: '#fff',
          px: 1.5,
          '&:hover': {
            backgroundColor: '#fff'
          },
          border: '1px solid #E4E4E5',
          height: '36px'
        }}
        onClick={handleClick}
      >
        {timeline?.name}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml: 1
          }}
        >
          <DropDown />
        </Box>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1.1,
            width: '200px'
          }}
        >
          <CustomFilter setDate={setDate} setAnchor={setAnchorEl} />
          {timelineButtons.map((item, i) => {
            return (
              <Button
                key={i}
                fullWidth
                sx={{
                  py: 1.1,
                  px: 2,
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#101828',
                  fontSize: '13px',
                  backgroundColor: timeline?.name === item.name && 'rgb(33, 111, 237, 0.1)'
                }}
                onClick={() => {
                  setTimeline(item);
                  setDate({
                    name: item?.name,
                    ...item?.value
                  });

                  setAnchorEl(null);
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  {item.name}
                </Box>
              </Button>
            );
          })}
        </Box>
      </Popover>
    </>
  );
}


function CustomFilter({ setDate, setAnchor }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl);
  const id2 = open2 ? 'simple-popover' : undefined;

  const handleApplyClick = () => {
    //Date, month, year of start date
    const startMonth = startDate.toDate().getMonth() + 1;
    const startDateNum = startDate.toDate().getDate();
    const startYear = startDate.toDate().getFullYear();

    //Date, month, year of end date
    const endMonth = endDate.toDate().getMonth() + 1;
    const endDateNum = endDate.toDate().getDate();
    const endYear = endDate.toDate().getFullYear();

    // formatted date strings
    const startDateString = `${startYear}-${startMonth < 10 ? '0' + startMonth : startMonth}-${
      startDateNum < 10 ? '0' + startDateNum : startDateNum
    }`;
    const endDateString = `${endYear}-${endMonth < 10 ? '0' + endMonth : endMonth}-${endDateNum < 10 ? '0' + endDateNum : endDateNum}`;

    // date to milliseconds
    const start = new Date(startDateString).getTime();
    const end = new Date(endDateString).getTime();
    setDate({ name: 'Custom Range', start, end });
    setAnchorEl(null);
    setAnchor(null);
  };

  return (
    <>
      <Button
        sx={{
          width: '11rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          fontSize: '13px',
          fontWeight: 700,
          lineHeight: '16.38px',
          color: '#28287B',
          backgroundColor: '#fff',
          mb: 1,
          '&:hover': {
            backgroundColor: '#fff'
          },
          border: '1px solid #E4E4E5',
          height: '36px'
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mr: 1
          }}
        >
          <EDSCalendarIcon />
        </Box>
        Custom Range
      </Button>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            p: 2,
            width: 'fit-content'
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              letterSpacing: '0em',
              color: '#28287B',
              mr: 2,
              mb: 1
            }}
          >
            From:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker disableFuture maxDate={endDate} value={startDate} onChange={(date) => setStartDate(date)} />
          </LocalizationProvider>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              letterSpacing: '0em',
              color: '#28287B',
              mr: 2,
              mt: 2,
              mb: 1
            }}
          >
            To:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker disableFuture minDate={startDate} value={endDate} onChange={(date) => setEndDate(date)} />
          </LocalizationProvider>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              mt: 2
            }}
          >
            <Button
              onClick={() => {
                setStartDate(null);
                setEndDate(null);

                setDate({
                  name: 'Month to date',
                  start: null,
                  end: null
                });
                setAnchorEl(null);
                setAnchor(null);
              }}
            >
              Clear
            </Button>
            <Button variant="contained" disabled={!(startDate && endDate)} onClick={handleApplyClick}>
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

