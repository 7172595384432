import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Typography, IconButton, TablePagination } from '@mui/material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as SendImg } from 'components/image.svg';
import { ReactComponent as MailImg } from 'components/image1.svg';
import { ReactComponent as ReplayImg } from 'components/image2.svg';
import { useGetCampaignsPaginationMutation } from 'services/campaign-service';
import { useSearchParams } from 'react-router-dom';
import { useGetUserTokenQuery } from 'services/auth-service';
import LoaderCircle from 'components/LoaderCircle';
import { useNavigate } from 'react-router-dom';
import { setUserAccessToken } from 'utils/localstorage.helper';
import { useSelector } from 'react-redux';

const CampaignSettings = () => {
  const [params] = useSearchParams();
  const userId = params.get('id');
  const { data } = useGetUserTokenQuery({ id: userId });
  const [getCampaigns] = useGetCampaignsPaginationMutation();
  const [campaigns, setCampaigns] = useState([]);
  const [offset, setOffset] = useState(10);
  const { searchData } = useSelector((state) => state.search);

  const [filter] = useState(null);
  const [totalDocs, setTotalDocs] = useState(0);
  const limit = 10;
  const [page, setPage] = useState(0);
  const [requestNewPage, setRequestNewPage] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const fetchAccounts = async () => {
    isLoading;
    const {
      docs,
      totalDocs,
      offset: rows
    } = await getCampaigns({
      ...(searchData?.length && { search: searchData }),
      ...(filter && { filter: filter?.value }),
      limit,
      offset: requestNewPage ? offset : 0
    }).unwrap();
    setRequestNewPage(false);
    setIsLoading(false);
    setCampaigns(docs);
    setTotalDocs(totalDocs);
    setOffset(Number(rows));
  };

  useEffect(() => {
    if (data) {
      setUserAccessToken(data.authToken);
      fetchAccounts();
    }
  }, [data, page, searchData]);

  const handleChangePage = (_event, newPage) => {
    setIsLoading(true)
    if (newPage < page) {
      const updatedOffset = offset - limit;
      setOffset(updatedOffset < 0 ? 0 : updatedOffset);
    } else {
      setOffset(offset + limit);
    }
    setPage(newPage);
    setRequestNewPage(true);
  };

  const navigate = useNavigate();

  const onClickHandler = (id) => {
    navigate(`/manage/campaign?campaignId=${id}&id=${userId}`);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h5" component="h2">
          Campaign Settings
        </Typography>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10]}
          rowsPerPage={10}
          count={totalDocs}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>
      {isLoading ? (
        <LoaderCircle />
      ) : campaigns.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh'
          }}
        >
          <Typography variant="h5">No data found</Typography>
        </Box>
      ) : (
        campaigns?.map((item) => {
          return (
            <Box key={item?.id}>
              <Box
                onClick={() => {
                  onClickHandler(item?.id);
                }}
                sx={{
                  py: 1,
                  px: 2,
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  alignItems: 'center',
                  boxShadow: ' 0px 12px 15px 0px #4b71970d',
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: 'none'
                  },
                  mt: 2
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box>
                    <Typography variant="p" component="p" color="#28287b" sx={{ fontWeight: 700, cursor: 'pointer' }}>
                      {item.name}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="p"
                      component="p"
                      color="#8181B0"
                      sx={{
                        fontSize: '13px',
                        fontWeight: 700,
                        lineHeight: '16px',
                        color: '#0071f6',
                        border: '1px solid #d8e7fe',
                        borderRadius: '6px',
                        padding: '8px 12px'
                      }}
                    >
                      {item.status}
                    </Typography>

                    <IconButton sx={{ marginLeft: '4px' }}>
                      <SettingsOutlinedIcon sx={{ color: '#28287b', cursor: 'pointer' }} />
                    </IconButton>

                    {/* <MoreVertIcon sx={{ cursor: 'pointer' }} /> */}
                  </Box>
                </Box>
                <Box Box sx={{ display: 'flex', paddingTop: '20px', flexWrap: ' wrap' }}>
                  <Box sx={{ display: 'flex', marginRight: '20px', cursor: 'pointer' }}>
                    <SendImg />

                    <Box sx={{ marginLeft: '8px' }}>
                      <Typography variant="p" component="p" color="#9e9e9e" sx={{ fontWeight: 400 }}>
                        Sent
                      </Typography>
                      <Typography variant="p" component="p" color="#28287b" sx={{ fontWeight: 400 }}>
                        {item?.analytics?.total?.sent}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', marginRight: '20px', cursor: 'pointer' }}>
                    <MailImg />

                    <Box sx={{ marginLeft: '8px' }}>
                      <Typography variant="p" component="p" color="#9e9e9e" sx={{ fontWeight: 400 }}>
                        Opened
                      </Typography>
                      <Typography variant="p" component="p" color="#28287b" sx={{ fontWeight: 400 }}>
                        {item?.analytics?.total?.open}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', marginRight: '20px', cursor: 'pointer' }}>
                    <ReplayImg />

                    <Box sx={{ marginLeft: '8px' }}>
                      <Typography variant="p" component="p" color="#9e9e9e" sx={{ fontWeight: 400 }}>
                        Replied
                      </Typography>
                      <Typography variant="p" component="p" color="#28287b" sx={{ fontWeight: 400 }}>
                        {item?.analytics?.total?.reply}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })
      )}
    </>
  );
};

export default CampaignSettings;
