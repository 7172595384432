import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useGetAllPartnersQuery } from 'services/user-service';
import { planNames } from '../../../config';
import Search from '../../../layout/MainLayout/Header/HeaderContent/Search';
import {
    Box,
    Link,
    Table,
    TableSortLabel,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import GlobalStyles from '@mui/material/GlobalStyles';
import LoaderCircle from 'components/LoaderCircle';
import { useDispatch, useSelector } from 'react-redux';
import { setPartnerUsers } from 'store/reducers/users';

function createData(email, name, plan, managePlan, warmupTag, createdAt, lastLogout, status, action, id, EarlyBirdPlan) {
    return {
        email,
        name,
        plan,
        managePlan,
        warmupTag,
        createdAt,
        lastLogout,
        status,
        action,
        id,
        EarlyBirdPlan
    };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getFormaDate(dateTimeString) {
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const date = new Date(dateTimeString);
    const formattedDate = `${monthNames[String(date.getMonth())]} ${String(
        date.getDate()
    ).padStart(2, '0')}, ${date.getFullYear()}`;
    return formattedDate;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'email',
        align: 'left',
        disablePadding: false,
        label: 'E-MAIL',
    },
    {
        id: 'name',
        align: 'left',
        disablePadding: true,
        label: 'NAME',
    },
    {
        id: 'leads',
        align: 'center',
        disablePadding: true,
        label: 'LEADS PLAN',
    },
    {
        id: 'sendingWarmup',
        align: 'center',
        disablePadding: true,
        label: 'SENDING WARMUP PLAN',
    },
    {
        id: 'appSumo',
        align: 'center',
        disablePadding: true,
        label: 'APPSUMO',
    },
    {
        id: 'EarlyBirdPlan',
        align: 'center',
        disablePadding: true,
        label: 'Life Time Deal',
    },
    {
        id: 'appSumoRefund',
        align: 'center',
        disablePadding: true,
        label: 'APPSUMO REFUND',
    },
    {
        id: 'warmupTag',
        align: 'left',
        disablePadding: false,
        label: 'WARMUPTAG',
    },
    {
        id: 'createdAt',
        align: 'left',
        disablePadding: false,
        label: 'CREATED AT',
    },
    {
        id: 'lastLogout',
        align: 'left',
        disablePadding: false,
        label: 'LAST LOGOUT',
    }
];


function OrderTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            backgroundColor: 'rgb(242, 244, 246)',
                            color: 'rgb(40, 40, 123)',
                            fontSize: '13px',
                            fontWeight: '500',
                        }}
                    >
                        {headCell.id !== 'action' &&
                            headCell.id !== 'warmupTag' &&
                            headCell.id !== 'plan' &&
                            headCell.id !== 'managePlan' &&
                            headCell.id !== 'appSumo' ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id && (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                )}
                            </TableSortLabel>
                        ) : (
                            headCell.label
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

OrderTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string,
};

export default function OrderTable() {
    const [orderBy, setOrderBy] = useState('email');
    const [order, setOrder] = useState('asc');
    const [page, setPage] = useState(0);
    const [totalUser, setTotaluser] = useState(0);
    const dispatch = useDispatch();
    const { dateRange } = useSelector((state) => state.users);
    const { searchData } = useSelector((state) => state.search);
    const [refetchUser, setRefetchUser] = useState();
    const [statusLoading, setStatusLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [pagePerRows, setPagePerRows] = useState(15);

    const {
        data,
        refetch,
        isLoading: refetchLoader,
    } = useGetAllPartnersQuery({
        sortBy: orderBy,
        page: page + 1,
        limit: pagePerRows,
        order,
        userType: 'All',
        ...(dateRange?.start && { start: dateRange?.start }),
        ...(dateRange?.end && { end: dateRange?.end }),
        ...(searchData?.length && { search: searchData }),
    });

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleRowsPerPageChange = (event) => {
        setPagePerRows(event.target.value);
    };

    useEffect(() => {
        const handleRefetchData = async () => {
            setStatusLoading(true);
            await refetch();
            setStatusLoading(false);
        };

        if (refetchUser) {
            handleRefetchData();
            setRefetchUser(false);
        } else {
            handleRefetchData();
        }
    }, [
        dateRange,
        page,
        orderBy,
        order,
        searchData?.length > 0,
        refetchUser,
    ]);

    useEffect(() => {
        if (data?.users?.docs.length) {

            const rows = data?.users?.docs?.map((cur) => {
                return createData(
                    cur.email,
                    cur.name.first + ' ' + cur.name.last,
                    cur.plan,
                    cur.managePlan,
                    cur.warmupTag,
                    cur.createdAt,
                    cur.lastLogout,
                    cur.isDeleted,
                    cur.action,
                    cur._id,
                    cur?.appSumoCode,
                    cur?.EarlyBirdPlan,
                );
            });
            setRows(rows);
            dispatch(setPartnerUsers(rows));
            setTotaluser(Number(data?.users?.totalDocs) || 0);
        } else {
            setTotaluser(0);
        }
    }, [data]);

    if (refetchLoader) {
        return <LoaderCircle />;
    }

    return (
        <>
            <>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        alignItems: { xs: 'flex-start', sm: 'center' },
                        mr: 3,
                        my: 2,
                        gap: 2
                    }}
                >
                    <Box sx={{ width: { xs: '97%', sm: '30%' } }}>
                        <Search />
                    </Box>
                </Box>
                {statusLoading ? (
                    <LoaderCircle />
                ) : totalUser === 0 ? (
                    <>
                        <Box
                            sx={{
                                width: 1,
                                height: '70vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '2rem',
                            }}
                        >
                            No Partner Users Available
                        </Box>
                    </>
                ) : (
                    <>
                        <Box
                            sx={{
                                border: '1px solid #ebebeb',
                                borderRadius: '12px',
                                width: { xs: '100%' },
                            }}
                        >
                            <TableContainer
                                sx={{
                                    width: '100%',
                                    // height: 'calc(100vh - 200px)',
                                    overflowx: 'auto',
                                    maxHeight: '100vh', // 80vh
                                    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                    boxShadow: 'rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
                                    borderRadius: '6px',
                                    color: 'rgb(16, 24, 40)',
                                    '& td, & th': { whiteSpace: 'nowrap' }
                                }}
                            >
                                <GlobalStyles
                                    styles={{
                                        '*::-webkit-scrollbar': {
                                            width: '8px',
                                            height: '8px',
                                        },
                                        '*::-webkit-scrollbar-thumb': {
                                            backgroundColor: 'rgb(228, 228, 229)',
                                            borderRadius: '10px',
                                            border: '1px solid rgba(0, 0, 0, 0)',
                                        },
                                        '*::-webkit-scrollbar-track': {
                                            borderRadius: '60px',
                                            width: '4px',
                                            backgroundColor: 'rgb(242, 244, 246)',
                                        },
                                    }}
                                />
                                <Table
                                    aria-labelledby="tableTitle"
                                    sx={{
                                        '& .MuiTableCell-root:first-of-type': {
                                            pl: 2,
                                        },
                                        '& .MuiTableCell-root:last-of-type': {
                                            pr: 3,
                                        },
                                    }}
                                    stickyHeader
                                >
                                    <OrderTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy)).map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        sx={{
                                                            '&:last-child td, &:last-child th': {
                                                                border: 0,
                                                            },
                                                        }}
                                                        tabIndex={-1}
                                                        key={row.email}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            align="left"
                                                        >
                                                            <Link
                                                                color="secondary"
                                                                component={RouterLink}
                                                                to={`/manage/partnerUsers/${row.id}`}
                                                            >
                                                                {row.email}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: '12px !important',
                                                                align: 'left',
                                                            }}
                                                        >
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography sx={{ fontSize: '11px' }}>
                                                                {row.plan?.subscription?.leads?.active
                                                                    ? planNames[
                                                                    row.plan?.subscription?.leads?.planId
                                                                    ]
                                                                    : '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography sx={{ fontSize: '11px' }}>
                                                                {row.plan?.subscription?.sendingWarmup?.active
                                                                    ? planNames[
                                                                    row.plan?.subscription?.sendingWarmup
                                                                        ?.planId
                                                                    ]
                                                                    : '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.Appsumo ? row.Appsumo : '-'}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.EarlyBirdPlan ? row.EarlyBirdPlan : '-'}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {row.appSumoRefund === true
                                                                ? 'Yes'
                                                                : row.appSumoRefund === false
                                                                    ? 'No'
                                                                    : '-'}
                                                        </TableCell>
                                                        <TableCell align="left">{row.warmupTag}</TableCell>
                                                        <TableCell align="left">
                                                            {getFormaDate(row.createdAt)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {getFormaDate(row.lastLogout)}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination
                                sx={{
                                    border: '1px solid #f0f0f0',
                                    borderRadius: 2,
                                }}
                                component="div"
                                rowsPerPageOptions={[15, 25, 50, 100]}
                                rowsPerPage={pagePerRows}
                                onRowsPerPageChange={handleRowsPerPageChange}
                                count={totalUser}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </Box>
                    </>
                )}
            </>
        </>
    );
}
