import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Invoice from 'pages/invoice/Invoice';
import { useGetExportUserPlansMutation, useGetUserPlansMutation } from 'services/auth-service';
import LoaderCircle from 'components/LoaderCircle';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Table,
  TableSortLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Popover
  // Grid
} from '@mui/material';
import Search from 'layout/MainLayout/Header/HeaderContent/Search';
import { visuallyHidden } from '@mui/utils';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { setExportUsers, setUsers } from 'store/reducers/users';
import { planNames } from 'config';

function getFormaDate(dateTimeString) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const date = new Date(dateTimeString);
  const formattedDate = `${monthNames[String(date.getMonth())]} ${String(date.getDate()).padStart(2, '0')}, ${date.getFullYear()}`;
  return formattedDate;
}

function createData(email, name, sendingWarmup, leads, expireAt, stripeCustomerId) {
  return {
    email,
    name,
    sendingWarmup,
    leads,
    expireAt,
    stripeCustomerId
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'email',
    align: 'center',
    disablePadding: true,
    label: 'EMAIL'
  },
  {
    id: 'name',
    align: 'center',
    disablePadding: true,
    label: 'NAME'
  },
  {
    id: 'sendingWarmup',
    align: 'center',
    disablePadding: true,
    label: 'SENDING WARMUP PLAN'
  },
  {
    id: 'leads',
    align: 'center',
    disablePadding: true,
    label: 'LEADS PLAN'
  },
  {
    id: 'expireAt',
    align: 'center',
    disablePadding: false,
    label: 'FREE TRIAL PLAN EXPIRED AT'
  },
  {
    id: 'viewInvoice',
    align: 'center',
    disablePadding: true,
    label: 'VIEW INVOICE'
  }
];

function OrderTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: 'rgb(242, 244, 246)',
              color: 'rgb(40, 40, 123)',
              fontSize: '13px',
              fontWeight: '500'
            }}
          >
            {headCell.id !== 'sendingWarmup' && headCell.id !== 'leads' && headCell.id !== 'plan' && headCell.id !== 'viewInvoice' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string
};

const InvoiceTable = () => {
  const navigate = useNavigate();
  const [userPlans, { isLoading }] = useGetUserPlansMutation();
  const [allUsersPlans] = useGetExportUserPlansMutation();
  const [orderBy, setOrderBy] = useState('email');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [totalUser, setTotaluser] = useState(0);
  const dispatch = useDispatch();
  const { searchData } = useSelector((state) => state.search);
  const { dateRange } = useSelector((state) => state.users);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownName, setDropdownName] = useState('All');

  const getUserPlans = async () => {
    const response = await userPlans({
      page: page + 1,
      planType: dropdownName.toLocaleLowerCase(),
      ...(searchData?.length && { search: searchData }),
      ...(dateRange?.start && { start: dateRange?.start }),
      ...(dateRange?.end && { end: dateRange?.end })
    });
    setData(response);
  };

  const getExportUsers = async () => {
    const { data } = await allUsersPlans();
    if (data.allUsers) {
      dispatch(setExportUsers(data));
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const opened = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onClickHandler = (id) => {
    let encoded = window.btoa(id);
    navigate(`/manage/user?id=${encoded}`);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  useEffect(() => {
    getExportUsers();
  }, []);

  useEffect(() => {
    getUserPlans();
  }, [page, dateRange, searchData, dropdownName]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (data?.data?.plans?.docs?.length !== 0) {
      const rows = data?.data?.plans?.docs?.map((cur) => {
        return createData(
          cur?.user?.email,
          cur?.user?.name?.first + ' ' + cur?.user?.name?.last,
          `${cur?.subscription?.sendingWarmup?.active ? cur?.subscription?.sendingWarmup?.planId : '-'}`,
          `${cur?.subscription?.leads?.active ? cur?.subscription?.leads?.planId : '-'}`,
          cur?.freeTrialExpiresAt,
          cur?.stripeCustomerId
        );
      });
      setRows(rows);
      dispatch(setUsers(rows));
      setTotaluser(Number(data?.data?.plans?.totalDocs) || 0);
    } else {
      setTotaluser(0);
    }
  }, [data]);

  return (
    <>
      <Invoice page={page} />
      <Box
        sx={{
          backgroundColor: 'white',
          color: '#262626',
          overflow: 'hidden',
          borderRadius: '8px',
          borderColor: '#e6ebf1',
          mt: 2
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
            mr: 3,
            my: 2,
            gap: 2
          }}
        >
          <Box sx={{ width: { xs: '97%', sm: '30%' } }}>
            <Search />
          </Box>
          <Box sx={{ px: 1 }}>
            <Box
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              onClick={handleClick}
              sx={{
                width: 120,
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                p: 1,
                border: '2px solid rgba(33, 111, 237, 0.5)',
                fontSize: '15px',
                borderRadius: '12px',
                color: '#28287B',
                fontWeight: '600'
              }}
            >
              <Box>{dropdownName}</Box>
              <Box sx={{ display: 'flex' }}>{opened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</Box>
            </Box>
          </Box>

          <Popover
            sx={{ mt: 0.7 }}
            id={id}
            open={opened}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom'
            }}
          >
            <Box sx={{ width: 120, p: 1, gap: 1 }}>
              <Typography
                onClick={() => {
                  setPage(0);
                  setDropdownName('All');
                  handleClosePopover();
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white'
                  }
                }}
              >
                All
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setDropdownName('Growth');
                  setPage(0);
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white'
                  }
                }}
              >
                Growth
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setDropdownName('Skyrocket');
                  setPage(0);
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white'
                  }
                }}
              >
                Skyrocket
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setDropdownName('Scale');
                  setPage(0);
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white'
                  }
                }}
              >
                Scale
              </Typography>
            </Box>
          </Popover>
        </Box>

        {isLoading ? (
          <LoaderCircle />
        ) : totalUser === 0 ? (
          <>
            <Box
              sx={{
                width: 1,
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '2rem'
              }}
            >
              No User Available
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                border: '1px solid #ebebeb',
                borderRadius: '12px'
              }}
            >
              <TableContainer
                sx={{
                  width: '100%',
                  // height: 'calc(100vh - 200px)',
                  overflow: 'auto',
                  maxHeight: '100vh', // 80vh
                  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  boxShadow: 'rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
                  borderRadius: '6px',
                  color: 'rgb(16, 24, 40)',
                  '& td, & th': { whiteSpace: 'nowrap' }
                }}
              >
                <GlobalStyles
                  styles={{
                    '*::-webkit-scrollbar': {
                      width: '8px',
                      height: '8px'
                    },
                    '*::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgb(228, 228, 229)',
                      borderRadius: '10px',
                      border: '1px solid rgba(0, 0, 0, 0)'
                    },
                    '*::-webkit-scrollbar-track': {
                      borderRadius: '60px',
                      width: '4px',
                      backgroundColor: 'rgb(242, 244, 246)'
                    }
                  }}
                />
                <Table
                  aria-labelledby="tableTitle"
                  sx={{
                    '& .MuiTableCell-root:first-of-type': {
                      pl: 2
                    },
                    '& .MuiTableCell-root:last-of-type': {
                      pr: 3
                    }
                  }}
                  stickyHeader
                >
                  <OrderTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0
                            }
                          }}
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell align="center">{row.email}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.sendingWarmup === '-' ? '-----' : planNames[row.sendingWarmup]}</TableCell>
                          <TableCell align="center">{row.leads === '-' ? '-----' : planNames[row.leads]}</TableCell>
                          <TableCell align="center">{getFormaDate(row.expireAt.slice(0, 10))}</TableCell>
                          <TableCell align="center">
                            <InfoOutlinedIcon
                              sx={{ width: '3rem', height: '2rem', cursor: 'pointer' }}
                              onClick={() => {
                                onClickHandler(row.stripeCustomerId);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                sx={{
                  border: '1px solid #f0f0f0',
                  borderRadius: 2
                }}
                component="div"
                rowsPerPageOptions={[15]}
                rowsPerPage={15}
                count={totalUser}
                page={page}
                onPageChange={handleChangePage}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default InvoiceTable;
