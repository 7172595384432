const config = {
  defaultPath: '/',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  UNIPILE_ACCESS_TOKEN: process.env.REACT_APP_UNIPILE_ACCESS_TOKEN,
  UNIPILE_DSN: process.env.REACT_APP_UNIPILE_DSN,
  SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID: process.env.REACT_APP_SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID,
  SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID: process.env.REACT_APP_SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID,
  SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID: process.env.REACT_APP_SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID,
  SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID: process.env.REACT_APP_SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID,
  SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID: process.env.REACT_APP_SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID,
  SENDING_WARMUP_YEARLY_SCALE_PRICE_ID: process.env.REACT_APP_SENDING_WARMUP_YEARLY_SCALE_PRICE_ID,
  LEADS_MONTHLY_SKYROCKET_PRICE_ID: process.env.REACT_APP_LEADS_MONTHLY_SKYROCKET_PRICE_ID,
  LEADS_MONTHLY_GROWTH_PRICE_ID: process.env.REACT_APP_LEADS_MONTHLY_GROWTH_PRICE_ID,
  LEADS_MONTHLY_SCALE_PRICE_ID: process.env.REACT_APP_LEADS_MONTHLY_SCALE_PRICE_ID,
  LEADS_YEARLY_SKYROCKET_PRICE_ID: process.env.REACT_APP_LEADS_YEARLY_SKYROCKET_PRICE_ID,
  LEADS_YEARLY_GROWTH_PRICE_ID: process.env.REACT_APP_LEADS_YEARLY_GROWTH_PRICE_ID,
  LEADS_YEARLY_SCALE_PRICE_ID: process.env.REACT_APP_LEADS_YEARLY_SCALE_PRICE_ID,
  APP_SUMO_TIER_1_PRICE_ID: process.env.REACT_APP_APP_SUMO_TIER_1_PRICE_ID,
  APP_SUMO_TIER_2_PRICE_ID: process.env.REACT_APP_APP_SUMO_TIER_2_PRICE_ID,
  APP_SUMO_TIER_3_PRICE_ID: process.env.REACT_APP_APP_SUMO_TIER_3_PRICE_ID,
  APP_SUMO_TIER_4_PRICE_ID: process.env.REACT_APP_APP_SUMO_TIER_4_PRICE_ID,
  BACKEND_BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL,
  YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID: process.env.REACT_APP_YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID,
  YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID: process.env.REACT_APP_YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID,
  YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID: process.env.REACT_APP_YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID
};

export default config;
export const drawerWidth = 260;
export const planNames = {
  [config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID]: 'Monthly Growth',
  [config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID]: 'Monthly Skyrocket',
  [config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID]: 'Monthly 10X Scale',
  [config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID]: 'Yearly Growth',
  [config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID]: 'Yearly Skyrocket',
  [config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID]: 'Yearly 10X Scale',
  [config.LEADS_MONTHLY_SKYROCKET_PRICE_ID]: 'Monthly Skyrocket Leads',
  [config.LEADS_MONTHLY_GROWTH_PRICE_ID]: 'Monthly Growth Leads',
  [config.LEADS_MONTHLY_SCALE_PRICE_ID]: 'Monthly 10X Scale Leads',
  [config.LEADS_YEARLY_SKYROCKET_PRICE_ID]: 'Yearly Skyrocket Leads',
  [config.LEADS_YEARLY_GROWTH_PRICE_ID]: 'Yearly Skyrocket Leads',
  [config.LEADS_YEARLY_SCALE_PRICE_ID]: 'Yearly 10X Scale Leads',
  [config.YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID]: 'Weekly Growth',
  [config.YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID]: 'Weekly Skyrocket',
  [config.YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID]: 'Weekly 10X Scale'
};

export const appSumoPlans = [
  {
    name: 'AppSumo Tier 1',
    price: '$49',
    id: config.APP_SUMO_TIER_1_PRICE_ID
  },
  {
    name: 'AppSumo Tier 2',
    price: '$149',
    id: config.APP_SUMO_TIER_2_PRICE_ID
  },
  {
    name: 'AppSumo Tier 3',
    price: '$349',
    id: config.APP_SUMO_TIER_3_PRICE_ID
  },
  {
    name: 'AppSumo Tier 4',
    price: '$649',
    id: config.APP_SUMO_TIER_4_PRICE_ID
  }
];
