// types
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  searchData: ''
};

const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchByEmail(state, action) {
      state.searchData = action.payload.searchData;
    }
  }
});

export default search.reducer;

export const { searchByEmail } = search.actions;
