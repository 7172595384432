import PropTypes from "prop-types";
import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Button,
    TextField,
    Typography
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

export function ConfigurePartnerDialog({ open, onClose, onSubmit }) {
    const validationSchema = Yup.object({
        remainingSubPartners: Yup.number()
            .required("Required")
            .min(0, "Value cannot be negative"),
        freeTrialExpiresAt: Yup.date()
            .nullable()
            .required("Required"),
    });

    const formik = useFormik({
        initialValues: {
            remainingSubPartners: "",
            freeTrialExpiresAt: "",
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            onSubmit(values)
            resetForm();
        },
    });

    const handleCancel = () => {
        formik.resetForm();
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            maxWidth="sm"
            fullWidth
            aria-labelledby="configure-partner-dialog-title"
        >
            <DialogTitle id="configure-partner-dialog-title">
                <Typography
                    variant="h4"
                    component="h3"
                    sx={{ cursor: "pointer", color: "rgba(40, 40, 123, 1)" }}
                >
                    Configure Partner User
                </Typography>
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent dividers>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="remainingSubPartners"
                            label="Remaining Sub Partners"
                            placeholder="Enter remaining sub-partners"
                            value={formik.values.remainingSubPartners}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                !!(
                                    formik.touched.remainingSubPartners &&
                                    formik.errors.remainingSubPartners
                                )
                            }
                            helperText={
                                formik.touched.remainingSubPartners &&
                                formik.errors.remainingSubPartners
                            }
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    padding: '4px 8px',
                                    borderRadius: '8px',
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            type="date"
                            variant="outlined"
                            name="freeTrialExpiresAt"
                            label="Expiration Date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={formik.values.freeTrialExpiresAt}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                !!(
                                    formik.touched.freeTrialExpiresAt &&
                                    formik.errors.freeTrialExpiresAt
                                )
                            }
                            helperText={
                                formik.touched.freeTrialExpiresAt &&
                                formik.errors.freeTrialExpiresAt
                            }
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    padding: '4px 8px',
                                    borderRadius: '8px',
                                }
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: '16px' }}>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={handleCancel}
                        sx={{
                            mx: 1,
                            borderRadius: '8px',
                            padding: '8px 16px',
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{
                            mx: 1,
                            borderRadius: '8px',
                            padding: '8px 16px',
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

ConfigurePartnerDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ConfigurePartnerDialog;
