export const OnCheckboxCustomIcon = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="#E7F0FF" />
        <path
          d="M14.6666 6.5L8.24998 12.9167L5.33331 10"
          stroke="#0071F6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="5.5"
          stroke="#0071F6"
        />
      </svg>
    </>
  );
};
