// types
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userList: [],
  dateRange: {
    name: "Select Date Range",
    start: null,
    end: null,
  },
  exportUsers: [],
  exportUserInvoices: [],
  userType: null
};

const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers(state, action) {
      state.userList = action.payload;
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setExportUsers(state, action) {
      state.exportUsers = action.payload;
    },
    setExportUserInvoices(state, action) {
      state.exportUserInvoices = action.payload;
    },
    setUserType(state, action) {
      state.userType = action.payload;
    },
    setPartnerUsers(state, action) {
      state.partnerUserList = action.payload;
    },
  },
});

export default users.reducer;

export const { setUsers, setDateRange, setExportUsers, setExportUserInvoices, setUserType, setPartnerUsers } = users.actions;
