export const EDSCancelIcon = () => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
          <path
            d="M15.375 15.375L8.625 8.625M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke="#28287B"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </>
  );
};

export const EDSCancelIconBlue = () => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.9">
          <path
            d="M15.375 15.375L8.625 8.625M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke="#0071F6"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </>
  );
};
