export const Completed = () => {
  return (
    <>
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="32" height="32" rx="16" fill="#DAEFDF" />
        <path
          d="M22 16.5C22 19.8137 19.3137 22.5 16 22.5C12.6863 22.5 10 19.8137 10 16.5C10 13.1863 12.6863 10.5 16 10.5C16.9414 10.5 17.8321 10.7168 18.625 11.1032M20.875 12.75L15.625 18L14.125 16.5"
          stroke="#00AA38"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
