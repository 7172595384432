import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  useMediaQuery
} from '@mui/material';
import { Save } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useGetMeQuery, useUpdateAdminAccountMutation, useUpdateAdminAccountPasswordMutation } from 'services/auth-service';

const Profile = () => {
  const { data, refetch: refetchUser } = useGetMeQuery();
  const [updateName] = useUpdateAdminAccountMutation();
  const [upadatePassword] = useUpdateAdminAccountPasswordMutation();

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  // Name Update
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showNameSave, setShowNameSave] = useState(false);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    if (e.target.value !== firstName) {
      setShowNameSave(true);
    } else {
      setShowNameSave(false);
    }
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    if (e.target.value !== lastName) {
      setShowNameSave(true);
    } else {
      setShowNameSave(false);
    }
  };

  const handleSaveNameClick = async () => {
    await updateName({
      name: {
        first: firstName,
        last: lastName
      }
    }).unwrap();
    setShowNameSave(false);
    toast.success('Name Updated!');
    refetchUser();
  };

  // Password Update
  const [openUpdatePasswordDialog, setOpenUpdatePasswordDialog] = useState(false);

  const formikUpdatePassword = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required('Current password is required'),
      newPassword: Yup.string().required('New password is required').min(8, 'Password must be at least 8 characters long'),
      confirmNewPassword: Yup.string()
        .required('Confirm new password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const { message } = await upadatePassword(values).unwrap();
        setOpenUpdatePasswordDialog(false);
        toast.success(message);
        helpers.resetForm();
      } catch (err) {
        toast.error(err.data.error.message);
      }
    }
  });

  // Reset Password
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const formikResetPassword = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string().required('New password is required').min(8, 'Password must be at least 8 characters long'),
      confirmNewPassword: Yup.string()
        .required('Confirm new password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    }),
    onSubmit: async (values) => {
      try {
        const { message } = await resetPassword(values).unwrap();
        setOpenResetPasswordDialog(false);
        toast.success(message);
      } catch (err) {
        toast.error(err.data.error.message);
      }
    }
  });

  const handleUpdatePasswordClick = () => {
    setOpenUpdatePasswordDialog(true);
  };

  const handleCloseUpdatePasswordDialog = () => {
    setOpenUpdatePasswordDialog(false);
  };
  useEffect(() => {
    if (data) {
      setFirstName(data?.name?.first);
      setLastName(data?.name?.last);
    }
  }, [data]);

  return (
    <>
      <Typography
        sx={{
          fontSize: '32px',
          color: 'rgb(40, 40, 123)',
          fontWeight: '700',
          letterSpacing: '0px',
          lineHeight: '40px',
          fontFamily: 'Plus Jakarta Sans',
          mb: 2
        }}
      >
        Edit Profile
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          boxShadow: '0px 12px 15px 0px #4B71970D',
          borderRadius: '12px',
          backgroundColor: 'white',
          width: '100%',
          p: 2
        }}
      >
        <Grid container spacing={1} columnSpacing={3} sx={{ width: '100%' }}>
          <Grid item xs={12} sm={6} sx={{}}>
            <Stack spacing={2} sx={{ mt: 2, alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <Typography
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  color: '#28287B',
                  mt: 0
                }}
              >
                First Name
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="First Name"
                sx={{
                  backgroundColor: 'white',
                  '& div': { pl: 0.3 },
                  '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                  '& div input': {
                    py: 1.3,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    '&::placeholder': {
                      color: 'rgba(40, 40, 123, 0.5)'
                    }
                  }
                }}
                value={firstName}
                onChange={handleFirstNameChange}
              />
            </Stack>
            <Stack spacing={2} sx={{ mt: 6, alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <Typography
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  color: '#28287B',
                  mt: 2
                }}
              >
                Last Name
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Last name"
                sx={{
                  height: 48,
                  backgroundColor: 'white',
                  '& div': { pl: 0.3 },
                  '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                  '& div input': {
                    py: 1.3,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    '&::placeholder': {
                      color: 'rgba(40, 40, 123, 0.5)'
                    }
                  }
                }}
                value={lastName}
                onChange={handleLastNameChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} sx={{}}>
            <Stack spacing={2} sx={{ mt: 2, alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <Typography
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  color: '#28287B',
                  mt: 0
                }}
              >
                Email
              </Typography>
              <TextField
                name="email"
                variant="outlined"
                fullWidth
                value={data?.email}
                disabled
                placeholder="Email address"
                sx={{
                  backgroundColor: 'white',
                  '& div': { pl: 0.3 },
                  '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                  '& div input': {
                    py: 1.3,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    '&::placeholder': {
                      color: 'rgba(40, 40, 123, 0.5)'
                    }
                  }
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}
              ></Box>
            </Stack>
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                mt: 2,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    color: '#28287B',
                    mt: 2
                  }}
                >
                  Password
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'left',
                    mt: 2,
                    fontSize: '13px',
                    fontWeight: 700,
                    lineHeight: '16px',
                    cursor: 'pointer',
                    color: '#0071F6'
                  }}
                  onClick={handleUpdatePasswordClick}
                >
                  Change Password
                </Typography>
              </Box>

              <TextField
                defaultValue="password"
                variant="outlined"
                fullWidth
                type="password"
                disabled
                sx={{
                  height: 48,
                  backgroundColor: 'white',
                  '& div': { pl: 0.3 },
                  '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                  '& div input': {
                    py: 1.3,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    '&::placeholder': {
                      color: 'rgba(40, 40, 123, 0.5)'
                    }
                  }
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: showNameSave ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Button
                sx={{
                  height: '2.5rem',
                  width: '5.5vw',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgb(33, 111, 237)',
                  '&:hover': {
                    backgroundColor: 'rgb(22 96 215)'
                  }
                }}
                onClick={handleSaveNameClick}
              >
                <Save sx={{ width: '1.1rem', height: '1.3rem', color: 'white' }} />
                <Typography sx={{ fontSize: '14px', color: 'white', ml: 0.5 }}>Save</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Password Update Dialog */}
      <Dialog
        open={openUpdatePasswordDialog}
        onClose={handleCloseUpdatePasswordDialog}
        sx={{
          backgroundColor: 'rgba(4, 4, 30, 0.5)'
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px',
            color: '#28287B',
            mt: 1
          }}
        >
          Update Password
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: isMdUp ? 'row' : 'column',
              justifyContent: 'space-between',
              gap: '10px'
            }}
          >
            <Stack sx={{ width: isMdUp ? '32%' : '100%' }}>
              <Typography
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  color: '#28287B',
                  mt: 2
                }}
              >
                Current password
              </Typography>
              <TextField
                fullWidth
                type="password"
                placeholder="Enter password"
                id="currentPassword"
                name="currentPassword"
                variant="outlined"
                onChange={formikUpdatePassword.handleChange}
                onBlur={formikUpdatePassword.handleBlur}
                value={formikUpdatePassword.values.currentPassword}
                error={formikUpdatePassword.touched.currentPassword && Boolean(formikUpdatePassword.errors.currentPassword)}
                helperText={formikUpdatePassword.touched.currentPassword && formikUpdatePassword.errors.currentPassword}
                sx={{
                  mt: 2,
                  width: '100%',
                  height: 40,
                  backgroundColor: 'white',
                  '& div': { pl: 0.3 },
                  '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                  '& div input': {
                    py: 1.3,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    '&::placeholder': {
                      color: 'rgba(40, 40, 123, 0.5)'
                    }
                  }
                }}
              />
            </Stack>
            <Stack sx={{ width: isMdUp ? '32%' : '100%' }}>
              <Typography
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  color: '#28287B',
                  mt: 2
                }}
              >
                New password
              </Typography>
              <TextField
                fullWidth
                type="password"
                placeholder="Enter new password"
                id="newPassword"
                name="newPassword"
                variant="outlined"
                onChange={formikUpdatePassword.handleChange}
                onBlur={formikUpdatePassword.handleBlur}
                value={formikUpdatePassword.values.newPassword}
                error={formikUpdatePassword.touched.newPassword && Boolean(formikUpdatePassword.errors.newPassword)}
                helperText={formikUpdatePassword.touched.newPassword && formikUpdatePassword.errors.newPassword}
                sx={{
                  mt: 2,
                  width: '100%',
                  height: 40,
                  backgroundColor: 'white',
                  '& div': { pl: 0.3 },
                  '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                  '& div input': {
                    py: 1.3,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    '&::placeholder': {
                      color: 'rgba(40, 40, 123, 0.5)'
                    }
                  }
                }}
              />
            </Stack>
            <Stack sx={{ width: isMdUp ? '32%' : '100%' }}>
              <Typography
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  color: '#28287B',
                  mt: 2
                }}
              >
                Confirm new password
              </Typography>
              <TextField
                fullWidth
                type="password"
                placeholder="Confirm new password"
                id="confirmNewPassword"
                name="confirmNewPassword"
                variant="outlined"
                onChange={formikUpdatePassword.handleChange}
                onBlur={formikUpdatePassword.handleBlur}
                value={formikUpdatePassword.values.confirmNewPassword}
                error={formikUpdatePassword.touched.confirmNewPassword && Boolean(formikUpdatePassword.errors.confirmNewPassword)}
                helperText={formikUpdatePassword.touched.confirmNewPassword && formikUpdatePassword.errors.confirmNewPassword}
                sx={{
                  mt: 2,
                  width: '100%',
                  height: 40,
                  backgroundColor: 'white',
                  '& div': { pl: 0.3 },
                  '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                  '& div input': {
                    py: 1.3,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    '&::placeholder': {
                      color: 'rgba(40, 40, 123, 0.5)'
                    }
                  }
                }}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 3, mx: 2 }}>
          <Button onClick={handleCloseUpdatePasswordDialog} variant="outlined" fullWidth sx={{ p: 1 }}>
            Cancel
          </Button>
          <Button onClick={formikUpdatePassword.handleSubmit} variant="contained" fullWidth sx={{ p: 1 }}>
            Update Password
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reset Password Dialog */}
      <Dialog open={openResetPasswordDialog}>
        <DialogTitle sx={{ fontSize: '24px' }}>Reset Password</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            type="password"
            label="New Password"
            id="newPassword"
            name="newPassword"
            variant="outlined"
            onChange={formikResetPassword.handleChange}
            onBlur={formikResetPassword.handleBlur}
            value={formikResetPassword.values.newPassword}
            error={formikResetPassword.touched.newPassword && Boolean(formikResetPassword.errors.newPassword)}
            helperText={formikResetPassword.touched.newPassword && formikResetPassword.errors.newPassword}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            type="password"
            label="Confirm New Password"
            id="confirmNewPassword"
            name="confirmNewPassword"
            variant="outlined"
            onChange={formikResetPassword.handleChange}
            onBlur={formikResetPassword.handleBlur}
            value={formikResetPassword.values.confirmNewPassword}
            error={formikResetPassword.touched.confirmNewPassword && Boolean(formikResetPassword.errors.confirmNewPassword)}
            helperText={formikResetPassword.touched.confirmNewPassword && formikResetPassword.errors.confirmNewPassword}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ mb: 3 }}>
          <Button onClick={formikResetPassword.handleSubmit} variant="contained">
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Profile;
