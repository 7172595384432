// third-party
import { combineReducers } from "redux";
import menu from "./menu";
import search from "./search";
import users from "./users";
import { userApi } from "services/user-service";
import { authApi } from "services/auth-service";
import { accountApi } from "services/account-service";
import { campaignsApi } from "services/campaign-service";
import account from "./account";

const reducers = combineReducers({
  menu,
  [userApi.reducerPath]: userApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [campaignsApi.reducerPath]: campaignsApi.reducer,
  search,
  account,
  users,
});

export default reducers;
