export const EACloseIcon = () => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
          <path d="M18 6L6 18M18 18L6 6" stroke="#8181B0" strokeWidth="2" strokeLinecap="round" />
        </g>
      </svg>
    </>
  );
};
