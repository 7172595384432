import React, { useEffect, useState } from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Typography, IconButton, Box } from '@mui/material';
import SettingsModal from 'pages/components/emailWarmup/SettingsModal';
import { useEnableWarmupMutation, useGetAccountsMutation, usePauseWarmupMutation } from 'services/account-service';
import { useSearchParams } from 'react-router-dom';
import { useGetUserTokenQuery } from 'services/auth-service';
import { TablePagination } from '@mui/material';
import LoaderCircle from 'components/LoaderCircle';
import { setUserAccessToken } from 'utils/localstorage.helper';
import CustomCheckbox from 'pages/components/campaign/CustomCheckbox';
import { useSelector } from 'react-redux';

const UserDataManage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [getAccounts] = useGetAccountsMutation();
  const [params] = useSearchParams();
  const [accounts, setAccounts] = useState([]);
  const [offset, setOffset] = useState(10);
  const userId = params.get('id');
  const { data } = useGetUserTokenQuery({ id: userId });
  const { searchData } = useSelector((state) => state.search);

  const [filter] = useState(null);
  const [total, setTotal] = useState(0);
  const limit = 10;
  const [page, setPage] = useState(0);
  const [userData, setUserData] = useState({});
  const [open, setOpen] = useState(false);
  const [pauseWarmup] = usePauseWarmupMutation();
  const [enableWarmup] = useEnableWarmupMutation();
  const [requestNewPage, setRequestNewPage] = useState(false);

  const handleChangePage = (_event, newPage) => {
    setIsLoading(true);
    if (newPage < page) {
      const updatedOffset = offset - limit;
      setOffset(updatedOffset < 0 ? 0 : updatedOffset);
    } else {
      setOffset(offset + limit);
    }
    setPage(newPage);
    setRequestNewPage(true);
    setPage(newPage);
  };
  const fetchAccounts = async () => {
    setIsLoading(true);
    const {
      docs,
      total,
      offset: rows
    } = await getAccounts({
      ...(searchData?.length && { search: searchData }),
      filter: filter?.value,
      limit,
      offset: requestNewPage ? offset : 0
    }).unwrap();
    setRequestNewPage(false);
    setIsLoading(false);
    setAccounts(docs);
    setTotal(total);
    setOffset(Number(rows));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const sendData = async (id, toggle) => {
    try {
      setIsLoading(true);
      if (toggle === 'enabled') {
        await pauseWarmup({
          id: id
        });
      } else if (toggle === 'paused') {
        await enableWarmup({
          id: id
        });
      }
      setIsLoading(false);
      fetchAccounts();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data) {
      setUserAccessToken(data.authToken);
      fetchAccounts();
    }
  }, [data, page, searchData]);

  const handleClick = async (id, toggle) => {
    await sendData(id, toggle);
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h5" component="h2">
          Manage User Email Settings
        </Typography>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10]}
          rowsPerPage={10}
          count={total}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>

      {isLoading ? (
        <LoaderCircle />
      ) : (
        <Box>
          {accounts.length === 0 ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '80vh'
                }}
              >
                <Typography variant="h5">No data found</Typography>
              </Box>
            </>
          ) : (
            <>
              {accounts.map((curr_account) => (
                <Box
                  key={curr_account.id}
                  sx={{
                    py: 1,
                    px: 2,
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    boxShadow: ' 0px 12px 15px 0px #4b71970d',
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: 'none'
                    },
                    mt: 2
                  }}
                >
                  <Box>
                    <Typography variant="p" component="p" color="#28287b" sx={{ fontWeight: 700 }}>
                      {curr_account.name.first + ' ' + curr_account.name.last}
                    </Typography>
                    <Typography variant="p" component="p" color="#8181B0" sx={{ fontWeight: 400 }}>
                      {curr_account.email}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', mr: '2rem' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="p" component="p" color="#8181B0" sx={{ fontWeight: 400, mx: 3 }}>
                        Warmup
                      </Typography>

                      <CustomCheckbox
                        name="warmup"
                        checked={curr_account?.warmup?.status === 'enabled' ? true : false}
                        onChange={() => handleClick(curr_account?.id, curr_account?.warmup?.status)}
                      />
                    </Box>
                    <IconButton
                      onClick={() => {
                        handleClickOpen();
                        setUserData(curr_account);
                      }}
                    >
                      <SettingsOutlinedIcon sx={{ color: '#28287b' }} />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </>
          )}
        </Box>
      )}

      <SettingsModal open={open} onClose={handleClose} userData={userData} token={data} />
    </>
  );
};

export default UserDataManage;
