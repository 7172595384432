import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Button, Typography, Modal, TextField } from "@mui/material";
import { useUpdateUserPasswordMutation } from "services/user-service";
import toast from "react-hot-toast";
export function ChangePassword({ id, email }) {
    const [updateUserPassword] = useUpdateUserPasswordMutation();
    const [open, setOpen] = useState(false);
    const [updatedData, setUpdatedData] = useState({
        newPassword: "",
        confirmPassword: "",
    });

    const onChangeValues = (e) => {
        setUpdatedData({
            ...updatedData,
            [e.target.name]: e.target.value,
        });
    };

    const handleCancel = () => {
        setUpdatedData({
            newPassword: "",
            confirmPassword: "",
        });
        setOpen(false);
    };

    const handleChange = async () => {
        try {
            const { data } = await updateUserPassword({
                id,
                email,
                newPassword: updatedData.newPassword,
            });
            if (data?.status === 200) {
                handleCancel();
                toast.success(data?.message);
            } else {
                toast.error(data?.message);
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        }
    };

    return (
        <div>
            <Box display="flex" justifyContent="center" >
                <Button
                    variant="contained"
                    onClick={() => setOpen(true)}
                    sx={{ bgcolor: "#216fed", "&:hover": { bgcolor: "#1a56d2" } }}
                >
                    Change Password
                </Button>
            </Box>
            <Modal
                open={open}
                onClose={handleCancel}
                aria-labelledby="modal-modal-title"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        component="h2"
                        sx={{ color: "#28287B", textAlign: 'center', mb: 3 }}
                    >
                        Change User Password
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            id="new-password"
                            label="Enter new password"
                            variant="outlined"
                            name="newPassword"
                            type="password"
                            onChange={onChangeValues}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            id="confirm-password"
                            label="Enter confirm password"
                            variant="outlined"
                            name="confirmPassword"
                            type="password"
                            onChange={onChangeValues}
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                    <Box display="flex" justifyContent="flex-end" gap={2}>
                        <Button
                            variant="contained"
                            sx={{
                                bgcolor: "red",
                                "&:hover": { bgcolor: "darkred" },
                            }}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                bgcolor: "green",
                                "&:hover": { bgcolor: "darkgreen" },
                                "&:disabled": {
                                    backgroundColor: "#ccc",
                                    color: "#999",
                                    cursor: "not-allowed",
                                },
                            }}
                            disabled={
                                !updatedData.newPassword ||
                                updatedData.newPassword !== updatedData.confirmPassword
                            }
                            onClick={handleChange}
                        >
                            Change
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

const style = {
    position: "relative",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    outline: "none",
};

ChangePassword.propTypes = {
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

export default ChangePassword;
