import React from "react";
import { Dialog } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomCheckbox from "components/CustomCheckbox";
import toast from "react-hot-toast";

import { useEnableWarmupMutation } from "services/user-service";
const WarmupSettings = ({ onClose, open, usageData }) => {
  const [enableWarmup] = useEnableWarmupMutation();
  const handleUpdateWarmupStatus = async (account) => {
    const { message, account: updatedAccount } = await enableWarmup(
      account._id
    ).unwrap();
    console.log("updated updatedAccount", updatedAccount);
    toast.success(message, { duration: 2000 });
  };

  const emailAccounts = usageData?.filteredAccounts;
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email Account</TableCell>
                <TableCell align="left">Error Count</TableCell>
                <TableCell align="left">Enable Warmup</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emailAccounts && emailAccounts.length > 0 ? (
                emailAccounts.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.email}
                    </TableCell>
                    <TableCell align="left">
                      {item.warmup?.warmupRejectTotal}
                    </TableCell>
                    <TableCell align="left">
                      <CustomCheckbox
                        name="warmup.basicSetting.alertBlock"
                        onChange={() => handleUpdateWarmupStatus(item)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </>
  );
};

export default WarmupSettings;
