import data from './spam2.json';

function checkSpamWords(paragraph) {
  // const trie = new Trie();
  // let newArr = [];

  // for (const phrase of data) {
  //   trie.insert(phrase);
  // }
  let spamArray = data
    ?.map((item) => {
      if (paragraph?.toLowerCase()?.includes(item?.toLocaleLowerCase())) {
        return item;
      }
    })
    ?.filter((ele) => ele !== undefined);

  // const foundPhrases = trie.search(paragraph);
  return spamArray.length;
  // Output: ["Apply now!", "Action required", "Apply here"]
}

export default checkSpamWords;
