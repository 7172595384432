export const Sent = () => {
  return (
    <>
      <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#F7E5FF" />
        <path
          d="M18.0469 5.95315L10.9376 13.0625M6.18074 9.49027L17.2513 5.64945C17.9331 5.41293 18.5872 6.06701 18.3506 6.74877L14.5098 17.8194C14.2467 18.5778 13.1817 18.5986 12.8892 17.851L11.1313 13.3585C11.0435 13.1342 10.866 12.9566 10.6416 12.8688L6.14909 11.1109C5.40153 10.8184 5.42233 9.7534 6.18074 9.49027Z"
          stroke="#C867F4"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};
