import { EDSCancelIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSCancelIcon';
import React from 'react';
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { EDSMessagingIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSMessagingIcon';
import { EDSMeterIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSMeterIcon';
import { OffCheckboxCustomIcon } from 'assets/general/OffCheckboxCustomIcon';
import { OnCheckboxCustomIcon } from 'assets/general/OnCheckboxCustomIcon';
import { EDSGrowthIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSGrowthIcon';
import CustomCheckbox from 'components/CustomCheckbox';
const BasicWarmup = ({ formik }) => {
  return (
    <Box
      sx={{
        borderRadius: '12px',
        p: 3,
        mt: 3,
        border: '1px solid #E4E4E5'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: 'full',
              borderBottom: '1px solid #E4E4E5',
              pb: 2
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '20px',
                color: '#28287B'
              }}
            >
              Warmup Settings | Basics
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: '1rem'
          }}
        >
          <Box>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '26px',
                color: '#28287B'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 1.5
                }}
              >
                <EDSGrowthIcon />
              </Box>
              Increase per day
            </Typography>
            <Typography
              sx={{
                mt: 1.5,
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '20px',
                color: '#8181B0'
              }}
            >
              Suggested 1, Max 4
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.warmup.basicSetting.slowWarmupDisabled}
                  name="warmup.basicSetting.slowWarmupDisabled"
                  icon={<OffCheckboxCustomIcon />}
                  checkedIcon={<OnCheckboxCustomIcon />}
                />
              }
              label="Disable Slow Warmup"
              sx={{
                mt: 1,
                '& .MuiFormControlLabel-label': {
                  fontSize: '13px',
                  fontWeight: 500,
                  lineHeight: '16px',
                  color: '#28287B',
                  ml: 1
                }
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mt: 1
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              name="warmup.basicSetting.increasePerDay"
              onChange={formik.handleChange}
              value={formik.values.warmup.basicSetting.increasePerDay}
              error={formik.touched.warmup?.basicSetting?.increasePerDay && !!formik.errors.warmup?.basicSetting?.increasePerDay}
              helperText={formik.touched.warmup?.basicSetting?.increasePerDay && formik.errors.warmup?.basicSetting?.increasePerDay}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-4]*'
              }}
              sx={{
                width: 80,
                height: 40,
                backgroundColor: 'white',
                '& div': { pl: 0.3, borderRadius: '0.7rem' },
                '& div fieldset': {
                  border: '1px solid #E4E4E5'
                },
                '& div input': {
                  py: 1.3,
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '12px',
                  letterSpacing: '0em',
                  '&::placeholder': {
                    color: 'rgba(40, 40, 123, 0.5)'
                  }
                },
                mr: 1.5
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: 'flex',

            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}
        >
          <Box>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '26px',
                color: '#28287B'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 1
                }}
              >
                <EDSMeterIcon />
              </Box>
              Daily Warmup Limit
            </Typography>
            <Typography
              sx={{
                mt: 1.5,
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '20px',
                color: '#8181B0'
              }}
            >
              Suggested 20, Max 200
            </Typography>
            <FormControlLabel control={<Checkbox />} label="No Slow Warmup" sx={{ fontSize: '14px', visibility: 'hidden' }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mt: 1
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              onChange={formik.handleChange}
              name="warmup.basicSetting.limitPerDay"
              value={formik.values.warmup.basicSetting.limitPerDay}
              error={formik.touched.warmup?.basicSetting?.limitPerDay && !!formik.errors.warmup?.basicSetting?.limitPerDay}
              helperText={formik.touched.warmup?.basicSetting?.limitPerDay && formik.errors.warmup?.basicSetting?.limitPerDay}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
              sx={{
                width: 80,
                height: 40,
                backgroundColor: 'white',
                '& div': { pl: 0.3, borderRadius: '0.7rem' },
                '& div fieldset': {
                  border: '1px solid #E4E4E5'
                },
                '& div input': {
                  py: 1.3,
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  letterSpacing: '0em',
                  '&::placeholder': {
                    color: 'rgba(40, 40, 123, 0.5)'
                  }
                }
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: 'flex',
            gap: '2.5rem',

            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}
        >
          <Box>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '26px',
                color: '#28287B'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 1
                }}
              >
                <EDSMessagingIcon />
              </Box>
              Reply rate %
            </Typography>
            <Typography
              sx={{
                mt: 1.5,
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '20px',
                color: '#8181B0'
              }}
            >
              Suggested 30
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mt: 1
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              name="warmup.basicSetting.replyRate"
              value={formik.values.warmup.basicSetting.replyRate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.warmup?.basicSetting?.replyRate && !!formik.errors.warmup?.basicSetting?.replyRate}
              helperText={formik.touched.warmup?.basicSetting?.replyRate && formik.errors.warmup?.basicSetting?.replyRate}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
              sx={{
                width: 80,
                height: 40,
                backgroundColor: 'white',
                '& div': { pl: 0.3, borderRadius: '0.7rem' },
                '& div fieldset': {
                  border: '1px solid #E4E4E5'
                },
                '& div input': {
                  py: 1.3,
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  letterSpacing: '0em',
                  '&::placeholder': {
                    color: 'rgba(40, 40, 123, 0.5)'
                  }
                },
                mr: 1.5
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: 'flex',
            gap: '2.5rem',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
            // mt: 5,
          }}
        >
          <Box>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '12px',
                color: '#28287B'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 1
                }}
              >
                <EDSCancelIcon />
              </Box>
              Monitor Blacklists
            </Typography>
            <Typography
              sx={{
                mt: 1.5,
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '20px',
                color: '#8181B0'
              }}
            >
              Alert when blocked
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              mr: '-26px'
            }}
          >
            <CustomCheckbox
              name="warmup.basicSetting.alertBlock"
              checked={formik.values.warmup.basicSetting.alertBlock}
              onChange={formik.handleChange}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicWarmup;
