import React from "react";

// material-ui
import {
  Grid,
  Button,
  Typography,
  Checkbox,
  Popover,
  Box,
  Modal,
  TextField,
  Link
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import lodash from "lodash";
import DetailsUserTable from "./DetailsUserTable";
import MainCard from "components/MainCard";
import { useSelector, useDispatch } from "react-redux";
import { DropDown } from "assets/general/DropDown";
import { EDSCalendarIcon } from "assets/emailAccounts/emailDrawer/EDSettingsTab/EDSCalendarIcon";
import { useGetExportUsersQuery } from "services/user-service";
import { downloadCsv } from "utils/util";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useAddCouponsMutation } from "services/auth-service";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { appSumoPlans } from "../../../config";
import Papa from "papaparse";
import { setDateRange } from "store/reducers/users";
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const style = {
  width: { sx: "100%" },
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 500,
  bgcolor: "background.paper",
  // border: '1px solid #fafafb',
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};
const isDesktop = () => {
  // You can use any logic here to determine if it's a desktop or not
  // For simplicity, let's assume if the window width is greater than 768px, it's desktop
  return window.innerWidth > 768;
};

const UserDetails = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // This goes back to the previous path
  };

  return (
    <Grid container gap={3}>
        <Link
          color="secondary"
          component={RouterLink}
          to=""
          onClick={goBack}
          sx={{
            color: "#28287B",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          Back
        </Link>
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "#28287B",
            fontSize: "30px",
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "0px",
          }}
        >
          User Details
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <FilterDayWeekMonth />
          <CustomFilter />
        </Box>
        <DownloadCsv />
      </Grid>
      <Grid item xs={12}>
        <MainCard content={false}>
          <DetailsUserTable />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default UserDetails;

/*
 *
 *Implement download csv file & Promocode
 *
 */
function DownloadCsv() {
  const { userList } = useSelector((state) => state.users);
  const { userType } = useSelector((state) => state.users);
  const [downloadType, setDownloadType] = React.useState(null);
  const [show, setShow] = React.useState(null);
  const [promocode, setPromocode] = React.useState("");
  const [promocodeList, setPromocodeList] = React.useState([]);
  const [promocodeListCSV, setPromocodeListCSV] = React.useState([]);
  const [openPromoCodePop, setOpenPromoCodePop] = React.useState(false);
  const [uploadPromocode] = useAddCouponsMutation();
  const [loadingButton, setLoadingButton] = React.useState(false);

  const { dateRange } = useSelector((state) => state.users);
  const { searchData } = useSelector((state) => state.search);

  const { data, loading } = useGetExportUsersQuery({
    userType,
    ...(searchData?.length && { search: searchData }),
    ...(dateRange?.start && { start: dateRange?.start }),
    ...(dateRange?.end && { end: dateRange?.end }),
  });

  const [planName, setPlanName] = React.useState(appSumoPlans[0].id);
  const handlePromocode = (event) => {
    const value = event?.target?.value;
    setPromocode(event?.target?.value);
    if (value?.length > 0) {
      setPromocodeList(value.split(","));
    } else {
      setPromocodeList([]);
    }
  };
  const uploadPromocodeHandler = async () => {
    setLoadingButton(true);
    const combineCoupons = [...promocodeList, ...promocodeListCSV].reduce(
      (cur, ele) => {
        if (ele.trim()) {
          cur.push(ele.trim());
        }
        return cur;
      },
      []
    );
    await uploadPromocode({ priceId: planName, coupons: combineCoupons });
    setLoadingButton(false);
    setOpenPromoCodePop(false);
  };

  const handleChangeForDropDown = (event) => {
    setPlanName(event.target.value);
  };
  const handleClick = (event) => {
    setShow(event.currentTarget);
  };

  const handleClose = () => {
    setShow(null);
  };
  const handleClickForPromoCode = () => {
    setOpenPromoCodePop(true);
  };

  const handleCloseForPromoCode = () => {
    setOpenPromoCodePop(false);
  };



  //csv download function
  const handleChange = (type) => {
    setDownloadType(type);
    let datas = null;
    if (type === "page") {
      datas = userList;
    } else if (type === "all") {
      if (loading) {
        console.log("loading-------------");
      } else {
        datas = data?.users;
      }
    }

    if (datas) {
      const list = datas?.map((data) => {
        let dataList = {};
        if (typeof data?.name === "object") {
          dataList = {
            email: data?.email || "",
            id: data?._id || "",
            name: data?.name?.first + " " + data?.name?.last || "",
            warmupTag: data?.warmupTag || "",
            updatedAt: data?.updatedAt || "",
            lastLogout: data?.lastLogout || "",
          };

          if (dataList != undefined) {
            return lodash.pick(dataList, [
              "email",
              "id",
              "name",
              "managePlan",
              "warmupTag",
              "updatedAt",
              "lastLogout",
            ]);
          }
        } else {
          return lodash.pick(data, [
            "email",
            "id",
            "name",
            "managePlan",
            "warmupTag",
            "updatedAt",
            "lastLogout",
          ]);
        }
      });
      handleClose();
      downloadCsv("users", list);
    }
    setDownloadType(null);
  };

  const open = Boolean(show);
  const id = open ? "simple-popover" : undefined;

  const handleOnChange = (e) => {
    e.preventDefault();
    const fileValue = e.target.files[0];
    if (fileValue) {
      Papa.parse(fileValue, {
        header: false,
        skipEmptyLines: true,
        complete: function (results) {
          const importedCoupons = results.data?.map((cur) => cur[0]);
          setPromocodeListCSV(importedCoupons);
        },
      });
    }
  };

  return (
    <>
      <Box>
        <Button
          variant="contained"
          style={{
            display: "none",
          }}
          onClick={handleClickForPromoCode}
          sx={{ mr: 2 }}
        >
          Add Coupons
        </Button>
        <Button variant="contained" onClick={handleClick}>
          Download CSV
        </Button>
      </Box>
      <Modal
        open={openPromoCodePop}
        onClose={handleCloseForPromoCode}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h2"
              component="h2"
              sx={{ color: "#28287B" }}
            >
              Add Coupons
            </Typography>
            <CloseIcon
              sx={{ fontSize: "2rem", color: "#28287B", cursor: "pointer" }}
              onClick={handleCloseForPromoCode}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "3px",
              pt: 1,
              pb: 2,
            }}
          >
            <Box sx={{ width: 1, borderTop: "1px solid #c2c2cb" }}></Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ color: "#28287B" }}
            >
              Select Plan
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: { sx: "none", md: "flex" },
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                mt: "8px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <FormControl
                  sx={{
                    width: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: "12px",
                  }}
                >
                  <Select
                    value={planName}
                    onChange={handleChangeForDropDown}
                    sx={{
                      width: 1,
                      height: "2.6rem",
                      p: 1,
                      color: "#28287B",
                      gap: 2,
                      fontWeight: 700,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {appSumoPlans.map((cur) => {
                      return (
                        <MenuItem key={cur.id} value={cur.id}>
                          <Typography>
                            {cur.name}{" "}
                            <span
                              style={{ color: "#28287B", marginLeft: "1rem" }}
                            >
                              {cur.price}
                            </span>
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                  sx={{ color: "#28287B", mt: 1 }}
                >
                  Upload CSV file
                </Typography>
                <input type="file" accept={".csv"} onChange={handleOnChange} />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "3px",
              p: 3,
            }}
          >
            <Box sx={{ width: 1, borderTop: "1px solid #c2c2cb" }}></Box>
            <Typography variant="h5" component="h2" sx={{ color: "#28287B" }}>
              OR
            </Typography>
            <Box sx={{ width: 1, borderTop: "1px solid #c2c2cb" }}></Box>
          </Box>

          <Box>
            <Typography variant="h5" component="h2" sx={{ color: "#28287B" }}>
              Enter Coupon Code
            </Typography>
            <TextField
              id="outlined-basic"
              onChange={handlePromocode}
              label="Enter Code"
              variant="outlined"
              value={promocode}
              sx={{ width: 1, margin: "8px 0px 10px 0px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 2,
              p: 1,
            }}
          >
            <LoadingButton
              sx={{ width: "7rem" }}
              onClick={uploadPromocodeHandler}
              endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
            >
              <span>Upload</span>
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <Popover
        id={id}
        open={open}
        anchorEl={show}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Button
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "0px 12px 0px 0px",
          }}
          onClick={() => handleChange("page")}
        >
          <Checkbox
            inputProps={{ "aria-label": "controlled" }}
            size="small"
            checked={downloadType === "page"}
          />
          <Typography>Select page</Typography>
        </Button>
        <Button
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "0px 12px 0px 0px",
            // gap: 1,
          }}
          onClick={() => handleChange("all")}
        >
          <Checkbox
            inputProps={{ "aria-label": "controlled" }}
            size="small"
            checked={downloadType === "all"}
          />
          <Typography>Select All</Typography>
        </Button>
      </Popover>
    </>
  );
}

/*
Implement daily, weekly, monthly filter
*/
function FilterDayWeekMonth() {
  const timelineButtons = [
    {
      name: "Select Date Range",
      value: {
        start: null,
        end: null,
      },
    },
    {
      name: "Last 7 days",
      value: {
        start: new Date().setDate(new Date().getDate() - 7),
        end: Date.now(),
      },
    },
    {
      name: "Month to date",
      value: { start: new Date().setDate(1), end: Date.now() },
    },
    {
      name: "Last 4 weeks",
      value: {
        start: new Date().setDate(new Date().getDate() - 28),
        end: Date.now(),
      },
    },
    {
      name: "Last 3 months",
      value: {
        start: new Date().setMonth(new Date().getMonth() - 3),
        end: Date.now(),
      },
    },
    {
      name: "Last 6 months",
      value: {
        start: new Date().setMonth(new Date().getMonth() - 6),
        end: Date.now(),
      },
    },
    {
      name: "Last 12 months",
      value: {
        start: new Date().setMonth(new Date().getMonth() - 12),
        end: Date.now(),
      },
    },
  ];
  const [timeline, setTimeline] = React.useState(timelineButtons[0]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: "13px",
          fontWeight: 700,
          lineHeight: "16.38px",
          color: "#28287B",
          backgroundColor: "#fff",
          px: 1.5,
          mr: 2,
          "&:hover": {
            backgroundColor: "#fff",
          },
          border: "1px solid #E4E4E5",
          height: "36px",
        }}
        onClick={handleClick}
      >
        {timeline?.name}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ml: 1,
          }}
        >
          <DropDown />
        </Box>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 1.1,
            width: "200px",
          }}
        >
          {timelineButtons.map((item, i) => {
            return (
              <Button
                key={i}
                fullWidth
                sx={{
                  py: 1.1,
                  px: 2,
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "#101828",
                  fontSize: "13px",
                  backgroundColor:
                    timeline?.name === item.name && "rgb(33, 111, 237, 0.1)",
                }}
                onClick={() => {
                  setTimeline(item);
                  dispatch(
                    setDateRange({
                      name: item?.name,
                      ...item?.value,
                    })
                  );
                  setAnchorEl(null);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {item.name}
                </Box>
              </Button>
            );
          })}
        </Box>
      </Popover>
    </>
  );
}

/*
Implement Custom filter
*/
function CustomFilter() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl);
  const id2 = open2 ? "simple-popover" : undefined;

  const handleApplyClick = () => {
    //Date, month, year of start date
    const startMonth = startDate.toDate().getMonth() + 1;
    const startDateNum = startDate.toDate().getDate();
    const startYear = startDate.toDate().getFullYear();

    //Date, month, year of end date
    const endMonth = endDate.toDate().getMonth() + 1;
    const endDateNum = endDate.toDate().getDate();
    const endYear = endDate.toDate().getFullYear();

    // formatted date strings
    const startDateString = `${startYear}-${startMonth < 10 ? "0" + startMonth : startMonth
      }-${startDateNum < 10 ? "0" + startDateNum : startDateNum}`;
    const endDateString = `${endYear}-${endMonth < 10 ? "0" + endMonth : endMonth
      }-${endDateNum < 10 ? "0" + endDateNum : endDateNum}`;

    // date to milliseconds
    const start = new Date(startDateString).getTime();
    const end = new Date(endDateString).getTime();
    dispatch(setDateRange({ name: "Custom Range", start, end }));
    setAnchorEl(null);
  };

  return (
    <>
      {" "}
      <Button
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: "13px",
          fontWeight: 700,
          lineHeight: "16.38px",
          color: "#28287B",
          backgroundColor: "#fff",
          px: 1.5,
          mr: { xs: 0, sm: 2 },
          "&:hover": {
            backgroundColor: "#fff",
          },
          border: "1px solid #E4E4E5",
          height: "36px",
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mr: 1,
          }}
        >
          <EDSCalendarIcon />
        </Box>
        Custom Range
      </Button>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            p: 2,
            width: "fit-content",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "18px",
              letterSpacing: "0em",
              color: "#28287B",
              mr: 2,
              mb: 1,
            }}
          >
            From:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              maxDate={endDate}
              value={startDate}
              onChange={(date) => setStartDate(date)}
              slotProps={isDesktop() ? { popper: { placement: 'right' } } : null}

            />
          </LocalizationProvider>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "18px",
              letterSpacing: "0em",
              color: "#28287B",
              mr: 2,
              mt: 2,
              mb: 1,
            }}
          >
            To:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              minDate={startDate}
              value={endDate}
              onChange={(date) => setEndDate(date)}
              slotProps={isDesktop() ? { popper: { placement: 'right' } } : null}


            />
          </LocalizationProvider>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <Button
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
                dispatch(
                  setDateRange({
                    name: "Month to date",
                    start: new Date().setDate(1),
                    end: Date.now(),
                  })
                );
                setAnchorEl(null);
              }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              disabled={!(startDate && endDate)}
              onClick={handleApplyClick}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
