export const DraftIcon = ({ color = "#28287B" }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 10.5H3V19.5C3 20.3284 3.67157 21 4.5 21H19.5C20.3284 21 21 20.3284 21 19.5V10.5H19.5M4.5 10.5L12 15L19.5 10.5M4.5 10.5V6C4.5 4.34315 5.84315 3 7.5 3H16.5C18.1569 3 19.5 4.34315 19.5 6V10.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
