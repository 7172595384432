import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress, ToggleButtonGroup, ToggleButton, Button, Popover } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { EDSCalendarIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSCalendarIcon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DropDown } from 'assets/general/DropDown';
import { useGetSignupUserMutation } from 'services/auth-service';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const SignupUserChart = () => {
  const [signUpUsers] = useGetSignupUserMutation();

  const [timeRange, setTimeRange] = useState('Month');
  const [signUpUserDetailsResponse, setSignUpUserDetailsResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState();
  const [page, setPage] = useState(1);

  const handleTimeRangeChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setTimeRange(newAlignment);
    }
  };

  const totalUsers = async () => {
    setIsLoading(true);
    const { data } = await signUpUsers({
      ...(date?.start && { start: date?.start }),
      ...(date?.end && { end: date?.end })
    });
    setSignUpUserDetailsResponse(data);
    setIsLoading(false);
  };

  const revenueChartOption = (columns) => {
    const selection = () => {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const chartSelection = [];
      columns?.map((dateStr) => {
        let splitedValues = [];
        if (dateStr.includes('/')) {
          splitedValues = dateStr.split('/').join('-').split('-');
        } else {
          splitedValues = dateStr.split('-');
        }
        let monthIndex = 0;
        let dateString = '';
        if (splitedValues?.length > 2) {
          monthIndex = parseInt(splitedValues[1]);
          dateString = `${splitedValues[0]} ${months[monthIndex]}, ${splitedValues[2]}`;
        } else {
          monthIndex = parseInt(splitedValues[0]);
          dateString = `${months[monthIndex]}, ${splitedValues[1]}`;
        }
        chartSelection.push(dateString);
      });
      return chartSelection;
    };
    return {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#1B998B"]
        }
      },
      stroke: {
        curve: 'smooth'
      },
      colors: ['#1B998B', '#5AAAA4'],
      xaxis: {
        rotateAlways: true,
        categories: selection(),
        tooltip: {
          enabled: false,
        },
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
    };
  };

  const RevenueChartSeries = () => {
    if (signUpUserDetailsResponse) {
      if (timeRange === 'Month') {
        let groupedData = {};
        signUpUserDetailsResponse.forEach((item) => {
          const createdAt = new Date(item.createdAt);
          const yearMonthKey = `${createdAt.getMonth()}-${createdAt.getFullYear()}`;

          if (!groupedData[yearMonthKey]) {
            groupedData[yearMonthKey] = [];
          }
          groupedData[yearMonthKey].push(item);
        });

        const limit = 12;
        const keysArray = Object.keys(groupedData);
        const valuesArray = Object.values(groupedData);
        const totalUsers = [];
        for (let i of valuesArray) {
          totalUsers.push(i?.length);
        }

        const startIndex = (page - 1) * limit;
        const endIndex = startIndex + limit;
        const barData = totalUsers.slice(startIndex, endIndex);
        const columnName = keysArray.slice(startIndex, endIndex);
        return {
          name: 'New Users',
          data: barData,
          nextDisabled: page * limit >= totalUsers.length,
          prevDisabled: page === 1,
          columnName
        };
      } else if (timeRange === 'Day') {
        if (signUpUserDetailsResponse) {
          const groupedData = {};

          signUpUserDetailsResponse.forEach((item) => {
            const createdAtDate = new Date(item.createdAt);
            const monthOfDate = createdAtDate.getMonth();
            const dayOfDate = createdAtDate.getDate();
            const yearOfDate = createdAtDate.getFullYear();
            const formattedString = `${dayOfDate}/${monthOfDate}/${yearOfDate}`;
            if (!groupedData[formattedString]) {
              groupedData[formattedString] = [];
            }

            groupedData[formattedString].push(item);
          });
          const limit = 10;
          const keysArray = Object.keys(groupedData);
          const valuesArray = Object.values(groupedData);

          const totalUsers = [];
          for (let i of valuesArray) {
            totalUsers.push(i?.length);
          }
          const startIndex = (page - 1) * limit;
          const endIndex = startIndex + limit;

          const barData = totalUsers.slice(startIndex, endIndex);
          const columnName = keysArray.slice(startIndex, endIndex);
          return {
            name: 'New Users',
            data: barData,
            nextDisabled: page * limit >= totalUsers.length,
            prevDisabled: page === 1,
            columnName
          };
        }
      }
    }
    return {};
  };

  useEffect(() => {
    totalUsers();
  }, [date]);

  const chartData = RevenueChartSeries();

  return (
    <Box
      sx={{
        width: 1,
        backgroundColor: 'white',
        color: '#262626',
        overflow: 'hidden',
        borderRadius: '8px',
        borderColor: '#e6ebf1',
        mt: 2,
        p: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography
          sx={{
            color: '#28287B',
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: '0px',
            mt: 1
          }}
        >
          New Users
        </Typography>

        <FilterDayWeekMonth setDate={setDate} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <ToggleButtonGroup
          sx={{
            width: { xs: '100%', md: '23rem' },
            height: '2.8rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #f2f4f6',
            backgroundColor: '#f2f4f6',
            borderRadius: '0.5rem',
            mt: '24px'
          }}
          value={timeRange}
          exclusive
          aria-label="Platform"
          onChange={handleTimeRangeChange}
        >
          <ToggleButton
            sx={{
              width: 1,
              height: '2.3rem',
              borderRadius: '8px',
              ml: '0.2rem',
              '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: '#ffffff',
                color: '#1e82f7',
                fontSize: '1rem',
                fontWeight: 600
              }
            }}
            value="Day"
          >
            Day
          </ToggleButton>
          <ToggleButton
            sx={{
              width: 1,
              height: '2.3rem',
              borderRadius: '8px',
              mr: '0.2rem',
              '&.Mui-selected, &.Mui-selected:hover': {
                backgroundColor: '#ffffff',
                color: '#1e82f7',
                fontSize: '1rem',
                fontWeight: 600
              }
            }}
            value="Month"
          >
            Month
          </ToggleButton>
        </ToggleButtonGroup>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 3, mt: '18px' }}>
          <Button disabled={chartData.prevDisabled} onClick={() => setPage(page - 1)}>
            <ChevronLeftIcon sx={{ fontSize: '2.5rem', cursor: 'pointer' }} />
          </Button>
          <Typography>{page}</Typography>

          <Button disabled={chartData.nextDisabled} onClick={() => setPage(page + 1)}>
            <NavigateNextIcon sx={{ fontSize: '2.5rem' }} />
          </Button>
        </Box>
      </Box>

      <Box sx={{ p: 1, mt: 2, borderRadius: '8px', border: '1px solid #f1f1f1' }}>
        {!signUpUserDetailsResponse && isLoading ? (
          <Box
            sx={{
              height: '23rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <ReactApexChart
            options={revenueChartOption(chartData.columnName)}
            series={[
              {
                name: chartData.name,
                data: chartData?.data || []
              }
            ]}
            type="area"
            height={350}
            width="100%"
          />
        )}
      </Box>
    </Box>
  );
};

export default SignupUserChart;

function FilterDayWeekMonth({ setDate }) {
  const timelineButtons = [
    {
      name: 'Select Date Range',
      value: {
        start: null,
        end: null
      }
    },
    {
      name: 'Last 7 days',
      value: {
        start: new Date().setDate(new Date().getDate() - 7),
        end: Date.now()
      }
    },
    {
      name: 'Month to date',
      value: { start: new Date().setDate(1), end: Date.now() }
    },
    {
      name: 'Last 4 weeks',
      value: {
        start: new Date().setDate(new Date().getDate() - 28),
        end: Date.now()
      }
    },
    {
      name: 'Last 3 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 3),
        end: Date.now()
      }
    },
    {
      name: 'Last 6 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 6),
        end: Date.now()
      }
    },
    {
      name: 'Last 12 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 12),
        end: Date.now()
      }
    }
  ];
  const [timeline, setTimeline] = React.useState(timelineButtons[0]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          fontSize: '13px',
          fontWeight: 700,
          lineHeight: '16.38px',
          color: '#28287B',
          backgroundColor: '#fff',
          px: 1.5,
          '&:hover': {
            backgroundColor: '#fff'
          },
          border: '1px solid #E4E4E5',
          height: '36px'
        }}
        onClick={handleClick}
      >
        {timeline?.name}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml: 1
          }}
        >
          <DropDown />
        </Box>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1.1,
            width: '200px'
          }}
        >
          <CustomFilter setDate={setDate} setAnchor={setAnchorEl} />
          {timelineButtons.map((item, i) => {
            return (
              <Button
                key={i}
                fullWidth
                sx={{
                  py: 1.1,
                  px: 2,
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#101828',
                  fontSize: '13px',
                  backgroundColor: timeline?.name === item.name && 'rgb(33, 111, 237, 0.1)'
                }}
                onClick={() => {
                  setTimeline(item);
                  setDate({
                    name: item?.name,
                    ...item?.value
                  });

                  setAnchorEl(null);
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  {item.name}
                </Box>
              </Button>
            );
          })}
        </Box>
      </Popover>
    </>
  );
}

function CustomFilter({ setDate, setAnchor }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl);
  const id2 = open2 ? 'simple-popover' : undefined;

  const handleApplyClick = () => {
    //Date, month, year of start date
    const startMonth = startDate.toDate().getMonth() + 1;
    const startDateNum = startDate.toDate().getDate();
    const startYear = startDate.toDate().getFullYear();

    //Date, month, year of end date
    const endMonth = endDate.toDate().getMonth() + 1;
    const endDateNum = endDate.toDate().getDate();
    const endYear = endDate.toDate().getFullYear();

    // formatted date strings
    const startDateString = `${startYear}-${startMonth < 10 ? '0' + startMonth : startMonth}-${
      startDateNum < 10 ? '0' + startDateNum : startDateNum
    }`;
    const endDateString = `${endYear}-${endMonth < 10 ? '0' + endMonth : endMonth}-${endDateNum < 10 ? '0' + endDateNum : endDateNum}`;

    // date to milliseconds
    const start = new Date(startDateString).getTime();
    const end = new Date(endDateString).getTime();
    setDate({ name: 'Custom Range', start, end });
    setAnchorEl(null);
    setAnchor(null);
  };

  return (
    <>
      <Button
        sx={{
          width: '11rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          fontSize: '13px',
          fontWeight: 700,
          lineHeight: '16.38px',
          color: '#28287B',
          backgroundColor: '#fff',
          mb: 1,
          '&:hover': {
            backgroundColor: '#fff'
          },
          border: '1px solid #E4E4E5',
          height: '36px'
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mr: 1
          }}
        >
          <EDSCalendarIcon />
        </Box>
        Custom Range
      </Button>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            p: 2,
            width: 'fit-content'
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              letterSpacing: '0em',
              color: '#28287B',
              mr: 2,
              mb: 1
            }}
          >
            From:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker disableFuture maxDate={endDate} value={startDate} onChange={(date) => setStartDate(date)} />
          </LocalizationProvider>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              letterSpacing: '0em',
              color: '#28287B',
              mr: 2,
              mt: 2,
              mb: 1
            }}
          >
            To:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker disableFuture minDate={startDate} value={endDate} onChange={(date) => setEndDate(date)} />
          </LocalizationProvider>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              mt: 2
            }}
          >
            <Button
              onClick={() => {
                setStartDate(null);
                setEndDate(null);

                setDate({
                  name: 'Month to date',
                  start: null,
                  end: null
                });
                setAnchorEl(null);
                setAnchor(null);
              }}
            >
              Clear
            </Button>
            <Button variant="contained" disabled={!(startDate && endDate)} onClick={handleApplyClick}>
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
