import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoaderCircle = () => {
  return (
    <Box
        sx={{
            width: "100%",
            height: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
    </Box>
  )
}

export default LoaderCircle