export const Campaign = ({ color = "black" }) => {
    return (
      <>
        <svg
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.0704 2.92949L10.4064 13.5935M3.2711 8.23517L19.877 2.47394C20.8996 2.11915 21.8808 3.10028 21.526 4.12291L15.7647 20.7288C15.3701 21.8664 13.7726 21.8976 13.3338 20.7763L10.6969 14.0375C10.5652 13.701 10.2989 13.4347 9.96236 13.303L3.22363 10.6661C2.10229 10.2273 2.13348 8.62985 3.2711 8.23517Z"
            stroke={color}
          />
        </svg>
      </>
    );
  };
  export default Campaign;