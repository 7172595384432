import { useCallback, useEffect, useState, useRef } from "react";
import { TableContainer, Box, InputAdornment, OutlinedInput, SvgIcon, Table, Typography, TableHead, TableRow, TableCell, TableBody, CircularProgress, FormControl, InputLabel, Select, MenuItem, Button, Drawer, Pagination, Chip } from "@mui/material";
// import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import { CustomDropdown } from "./statusList";
import { SearchOutlined } from '@ant-design/icons';
// import
// import { CloseOutlined, Google, LanguageOutlined } from "@mui/icons-material";
// import { API_SERVICE_BACKEND, API_SERVICE_BACKEND3 } from "src/config";
import config from "../../config";
// import { Layout as DashboardLayout } from "src/layouts/dashboard/layout";
import { CSVLink } from "react-csv";
import axios from "axios";
// import Head from "next/head";
import { useDispatch, useSelector } from "react-redux";
import { Container, Stack } from "@mui/system";
// import moment from "moment";
import { openDrawer } from "../../store/reducers/menu";
import OrderStatusSection from "./orderStatusSection";
import { useLazyGetUserAuthTokenQuery } from "services/auth-service";
// import toast from "../../../node_modules/react-hot-toast/dist/index";
const API_SERVICE_BACKEND3 = config.API_BASE_URL
const statusList = [
    {
        label: "All Status",
        // icon: checkIcon,
        color: "#12B981",
        value: ""
    },
    {
        label: "Completed",
        icon: "/checkIcon.png",
        color: "#12B981",
        value: "Completed"
    },
    {
        label: "Pending",
        icon: "/pendingIcon.png",
        color: "#FF5C1F",
        value: "Pending"
    },
    {
        label: "Canceled",
        icon: "/unCheckIcon.png",
        color: "#E20E0E",
        value: "Cancelled"
    },
];

const scrollBarStyle = {
    "&::-webkit-scrollbar": {
        width: "14px",
        height: "14px",
    },
    "&::-webkit-scrollbar-track": {
        borderRadius: "60px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#E4E4E5",
        borderRadius: "10px",
        border: "4px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#d5d5d5",
    },
};

const OrderStatus = () => {
    // let doneCopy;
    const [searchText, setSearchText] = useState("");
    const [status, setStatus] = useState("All");
    const csvButton = useRef()
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const [getToken, { data: tokenData }] = useLazyGetUserAuthTokenQuery();
    const [redirect, setRedirect] = useState(false);
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    const [orders, setOrders] = useState([]);
    const [orderStatusOpen, setOrderStatusOpen] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [sortby, setSortBy] = useState("");
    const [allOrders, setAllOrders] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [csvFileName, setCsvFileName] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    };

    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    const [isLoading, setIsLoading] = useState(true);
    const handleSearchChange = async (e) => {
        const query = e.target.value.toLowerCase();
        setSearchText(query);
    };

    const handleStatusChange = (value) => {

        setStatus(value);
    };
    const onActionMenuClick = async (id) => {
        setRedirect(true);
        await getToken({ id });
    };
    const fetchAllOrders = async (email) => {
        let baseUrl = `${API_SERVICE_BACKEND3}/dfy-setup/getOrders?email=${email}&csv=true`;
        const response = await axios.get(baseUrl);
        // console.log(response.data);

        let data = response.data.data;
        let ordersData = [];
        data.forEach((order) => {
            let orderData = {
                "Email": order.user,
                "Domain Name": order.domain,
                "Forward Domain": order.forwardDomain,
                "Provider": order.provider,
            };
            order.contactDetails.forEach((contact) => {
                ordersData.push({
                    ...orderData,
                    "First Name": contact.firstName,
                    "Last Name": contact.lastName,
                    "Mailbox Email": contact.email,
                    "Status": contact.status,
                    "Purchase Date": contact.startDate,
                })
            })
        })
        setAllOrders(ordersData);
        // return ordersData;
    };
    // pressDone = () => {
    //     doneCopy();
    // }
    useEffect(() => {
        setCsvFileName(null)
        if (allOrders.length > 0) {
            setCsvFileName(allOrders[0].Email + "_orders.csv")
        }
    }, [allOrders, setAllOrders]);
    useEffect(() => {
        if (csvFileName) {
            csvButton.current.link.click()
            setAllOrders([])
        }
    }, [csvFileName]);
    useEffect(() => {
        if (tokenData?.authToken && redirect) {
            setRedirect(false);
            window.open(
                `${process.env.REACT_APP_FRONTEND_URL}/verify?token=${tokenData?.authToken}&skipUpdate=true`,
                '_blank'
            );
        }
    }, [tokenData]);

    const fetchOrders = useCallback(async () => {
        let baseUrl = `${API_SERVICE_BACKEND3}/dfy-setup/getOrderUser`;
        if (page) {
            baseUrl = baseUrl + `?page=${page}`;
        }
        if (rowsPerPage) {
            baseUrl = baseUrl + `&limit=${rowsPerPage}`;
        }
        if (searchText !== "") {
            baseUrl = baseUrl + `&query=${searchText}`;
        }
        if (status !== "All") {
            baseUrl = baseUrl + `&status=${status.toLocaleLowerCase()}`;
        }

        if (sortby) {
            baseUrl = baseUrl + `&sortBy=${sortby}`;
        }

        const response = await axios.get(baseUrl);
        setTotalCount(response.data.total);
        setOrders(response.data.data ?? []);
        setIsLoading(false);
    }, [status, debouncedSearchText, selectedEmail, sortby, page, rowsPerPage]);


    useEffect(() => {
        if (!orderStatusOpen) {
            fetchOrders();
        }
    }, [orderStatusOpen]);
    const handleOrderStatusDrawerToggle = () => {
        setOrderStatusOpen(!orderStatusOpen);
    };
    // Debounce the search text change
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 2000);

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    // Fetch orders when status or debouncedSearchText changes
    useEffect(() => {
        setIsLoading(true); // Set loading before fetching
        fetchOrders(); // Call fetchOrders with the debounced search text
    }, [status, debouncedSearchText, fetchOrders]);

    useEffect(() => {
        fetchOrders();
    }, [page, rowsPerPage]);

    return (
        <>
            {/* <Head> */}
            <title>Order Status</title>
            {/* </Head> */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 2,
                }}
            >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                        <Stack direction="row" justifyContent="space-between" spacing={4}>
                            <Stack spacing={1}>
                                <Typography variant="h4">Order Status</Typography>
                            </Stack>
                        </Stack>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 1 }}>
                            <OutlinedInput
                                onChange={handleSearchChange}
                                size="small"
                                // disabled={loader}
                                placeholder="Search"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SvgIcon>
                                            <SearchOutlined />
                                        </SvgIcon>
                                    </InputAdornment>
                                }
                                value={searchText}
                            />
                            <CustomDropdown
                                label={status}
                                options={statusList}
                                selected={status}
                                handleSelect={(value) => {
                                    handleStatusChange(value);
                                }
                                }
                            />
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={sortby}
                                    label="sortBy"
                                    onChange={(e) => { setSortBy(e.target.value) }}
                                    sx={{ minWidth: 120 }}
                                >
                                    <MenuItem value={""}>No filter</MenuItem>
                                    <MenuItem value={"date"}>Date</MenuItem>
                                </Select>
                            </FormControl>

                        </Box>

                        <TableContainer
                            sx={{ borderRadius: "0px", ...scrollBarStyle }}
                        >
                            <Table
                                sx={{
                                    height: "fit-content",
                                    minWidth: "100%",
                                    borderCollapse: "separate",
                                    borderSpacing: "0px 15px !important ",
                                }}
                                aria-label="simple table"
                                stickyHeader
                            >
                                <TableHead sx={{ background: '#E9E9EC', borderRadius: '10px' }}>
                                    <TableRow
                                        sx={{
                                            "& .MuiTableCell-root": {
                                                backgroundColor: "#E9E9EC",
                                                borderBottom: "1px solid #E9E9EC",
                                                color: "#61616F",
                                            },
                                            "& .MuiTableCell-root::after": {
                                                content: '""',
                                                position: "absolute",
                                                top: 8,
                                                right: 0,
                                                bottom: 18,
                                                borderRight: '2px solid #CECED3',
                                                height: '50%',
                                                backgroundColor: "#CECED3",
                                            },
                                            "& .MuiTableCell-root:last-child::after": {
                                                content: 'none',
                                            },
                                        }}
                                    >

                                        <TableCell align="left">Email</TableCell>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Complete Date</TableCell>
                                        <TableCell align="left">Status</TableCell>
                                        <TableCell align="left">Order_Id</TableCell>
                                        <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                {!isLoading && orders.length > 0 ?
                                    (
                                        <TableBody>
                                            {orders?.map((data, index) => {
                                                const statusInfo = statusList.find(
                                                    (status) =>
                                                        status?.value?.toLowerCase() == data?.status?.toLowerCase() // Match the status with the label
                                                );
                                                return (

                                                    <TableRow
                                                        key={index}
                                                        sx={{
                                                            cursor: "pointer",
                                                            borderRadius: "10px",
                                                            background: 'white',
                                                            marginBottom: '10px',
                                                            "& td": {
                                                                borderTop: "1px solid rgba(224, 224, 229, 1)",
                                                                borderBottom: "1px solid rgba(224, 224, 229, 1)",
                                                                borderRight: "none",
                                                            },
                                                            "& td:first-child": {
                                                                borderTopLeftRadius: "10px",
                                                                borderBottomLeftRadius: "10px",
                                                                borderLeft: "1px solid rgba(224, 224, 229, 1)",
                                                                borderRight: "none"
                                                            },
                                                            "& td:last-child": {
                                                                borderTopRightRadius: "10px",
                                                                borderBottomRightRadius: "10px",
                                                                borderRight: "1px solid rgba(224, 224, 229, 1)",
                                                                borderLeft: "none"
                                                            }
                                                        }}
                                                    >
                                                        <TableCell align="left" sx={{ borderBottom: 'none', width: 'fit-content' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                                                {data.user}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ borderBottom: 'none', width: 'fit-content' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                                                {new Date(data.createdAt).toLocaleDateString()}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ borderBottom: 'none', width: 'fit-content' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                                                {data.lastCompletedDate && new Date(data.lastCompletedDate).toLocaleDateString() || "---"}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ borderBottom: 'none' }}>
                                                            {statusInfo && (
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    border: '1px solid',
                                                                    borderColor: statusInfo.color,
                                                                    borderRadius: '10px',
                                                                    padding: '6px 12px',
                                                                    width: 'fit-content'
                                                                }}>
                                                                    <img src={statusInfo.icon} alt="" style={{ color: statusInfo.color }} /> {/* Render icon */}
                                                                    <Typography
                                                                        sx={{ fontSize: "0.875rem", color: statusInfo.color, marginLeft: "8px" }}
                                                                    >
                                                                        {statusInfo.label}
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ borderBottom: 'none', width: 'fit-content' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                                                {data.sub_id}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{display:'flex', borderBottom: 'none', width:'fit-content', padding:'10px' }}>
                                                            <Button onClick={() => {
                                                                setSelectedEmail(data);
                                                                handleOrderStatusDrawerToggle();
                                                                handleDrawerToggle();
                                                            }}>View Order</Button>
                                                            <Button variant="contained" color="success" sx={{ margin: '0px 10px'}}
                                                                onClick={() => {
                                                                    fetchAllOrders(data.user)
                                                                }}>Export CSV</Button>
                                                            <CSVLink
                                                                ref={csvButton}
                                                                data={allOrders}
                                                                style={{ display: 'none' }}
                                                                filename={csvFileName}
                                                            ></CSVLink>
                                                            <Button variant="contained" color="info" sx={{ margin: '0px 20px'}}
                                                                onClick={() => {
                                                                    // console.log(data.userId)
                                                                    onActionMenuClick(data.userId);
                                                                }}>Manage Account</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                                // })
                                            }
                                                // );
                                                // }
                                            )}
                                        </TableBody>
                                    ) : isLoading ? (
                                        <TableBody sx={{ margin: "auto", width: "100%", border: "none" }}>
                                            <TableRow sx={{ border: "none" }} >
                                                <TableCell colSpan={4} align="center"  >
                                                    <CircularProgress style={{ margin: "auto" }} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    <Typography
                                                        sx={{
                                                            fontSize: "1.5rem",
                                                            fontWeight: 500,
                                                            color: "#61616F",
                                                        }}
                                                    >
                                                        No Orders Found
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                            </Table>
                        </TableContainer>
                    </Stack>
                    <Stack spacing={3}>
                        {!isLoading && <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Stack sx={{ display: "inlineflex" }}>
                                Total &nbsp;:&nbsp;
                                <Chip
                                    sx={{ backgroundColor: "#5761FE", color: "white" }}
                                    label={totalCount}
                                />
                            </Stack>

                            {!isLoading && (
                                <FormControl variant="filled" sx={{ m: 1, minWidth: 150 }}>
                                    <InputLabel id="demo-simple-select-filled-label">Rows Per Page</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={rowsPerPage}
                                        onChange={handleRowsPerPageChange}
                                        size="small"
                                    >
                                        {[15, 25, 50, 100].map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>)}
                        </Box>}
                        {!isLoading && orders.length ? (
                            <Pagination
                                count={

                                    Math.ceil(totalCount / rowsPerPage)
                                }
                                page={page}
                                onChange={(event, page) => setPage(page)}
                                showFirstButton
                                showLastButton
                                siblingCount={1}
                                boundaryCount={1}
                                variant="outlined"
                                shape="rounded"
                                size="large"
                                sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                                rowsPerPage={rowsPerPage}
                            />
                        ) : null}
                    </Stack>
                </Container>
            </Box>
            {selectedEmail && <Drawer
                open={orderStatusOpen}
                onClose={handleOrderStatusDrawerToggle}
                variant="temporary"
                anchor="right"
                PaperProps={{
                    sx: {
                        width: "100%",
                        p: 2
                    },
                }}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <OrderStatusSection handleSideBarToggle={handleDrawerToggle} handleDrawerToggle={handleOrderStatusDrawerToggle} selectedEmail={selectedEmail} />
            </Drawer>}
        </>
    );
};
// Page.getLayout = (page) => {
//     return <DashboardLayout>{page}</DashboardLayout>;
// };
export default OrderStatus;