import React, { useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { planNames } from '../../../config';
import { useCancelSubScriptionMutation } from 'services/auth-service';

const CancelSubscription = ({ userData, onClose, setRefetchUser }) => {
  const [canceclSubscription] = useCancelSubScriptionMutation();
  const [planId, setPlanId] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const cancelSubscription = async (id) => {
    setPlanId(id);
    setIsSubmitting(true);
    await canceclSubscription({
      userId: userData.id,
      planId: id
    });
    setRefetchUser(true);
    setIsSubmitting(false);
    onClose();
  };

  const subscription = userData?.plan?.subscription;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          minWidth: 350,
          maxWidth: 700,
          mt: 2,
          pt: 2,
          bgcolor: 'white'
        }}
      >
        {subscription?.leads || subscription?.sendingWarmup ? (
          <>
            {subscription?.leads && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                  border: '1px solid rgb(228, 228, 229)',
                  borderRadius: '1rem',
                  p: 1
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 'full'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      display: 'flex',
                      fontWeight: 600,
                      lineHeight: '26px',
                      color: 'rgba(40, 40, 123, 0.85)',
                      fontFamily: 'Plus Jakarta Sans'
                    }}
                  >
                    <Box sx={{ color: 'rgba(40, 40, 123, 0.9)', mr: 1 }}>Leads: </Box>
                    {`${planNames[subscription?.leads?.planId]} `}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: 'full',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'center'

                  }}
                >
                  <CancelButton
                    onClick={() => cancelSubscription(subscription?.leads?.planId)}
                    isSubmitting={isSubmitting}
                    id={subscription?.leads?.planId}
                    planId={planId}
                  />
                </Box>
              </Box>
            )}

            {subscription?.sendingWarmup && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  flexDirection: 'row',
                  marginTop: '20px',
                  border: '1px solid rgb(228, 228, 229)',
                  borderRadius: '1rem',
                  p: 1
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 'full'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      display: 'flex',
                      fontWeight: 600,
                      lineHeight: '26px',
                      color: 'rgba(40, 40, 123, 0.85)',
                      fontFamily: 'Plus Jakarta Sans'
                    }}
                  >
                    <Box sx={{ color: 'rgba(40, 40, 123, 0.9)', mr: 1 }}>Sending Warmup:</Box>
                    {`${planNames[subscription?.sendingWarmup?.planId]} `}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    width: 'full',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <CancelButton
                    onClick={() => cancelSubscription(subscription?.sendingWarmup?.planId)}
                    isSubmitting={isSubmitting}
                    id={subscription?.sendingWarmup?.planId}
                    planId={planId}
                  />
                </Box>
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '26px',
                color: 'rgb(129, 129, 176)'
              }}
            >
              None of the subscription is active currently.
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CancelSubscription;

const CancelButton = ({ onClick, isSubmitting, id, planId }) => {
  return (
    <Button
      sx={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        py: 1,
        px: 2
      }}
      variant="contained"
      type="button"
      disabled={isSubmitting}
      onClick={onClick}
    >
      {isSubmitting && id === planId ? (
        <>
          <CircularProgress color="inherit" size={20} thickness={5} sx={{ mr: 1 }} />
          Canceling
        </>
      ) : (
        <>Cancel</>
      )}
    </Button>
  );
};
