import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Dialog,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Autocomplete,
  Switch
} from "@mui/material";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetPlanUsageMutation, useUpdatePlanUsageMutation } from "services/auth-service";
import Divider from '@mui/material/Divider';

const PlanSetting = ({ onClose, open, userId, allWorkspaces, isPartnerAccount }) => {
  const [updatePlan] = useUpdatePlanUsageMutation();
  const [getPlanUsage] = useGetPlanUsageMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [workspaceData, setWorkspaceData] = useState(null);

  useEffect(() => {
    if (allWorkspaces && allWorkspaces.length > 0) {
      setSelectedWorkspace(allWorkspaces[0]);
    }
  }, [allWorkspaces]);

  const sendUpdatedPlan = async (updatedData) => {
    try {
      setIsSubmitting(true);
      await updatePlan({
        id: userId,
        data: updatedData,
      });
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      activeLeads: 0,
      monthlyEmails: 0,
      aiWriterCredits: 0,
      leadsCredits: 0,
      bounceCredit: 0,
      linkedinCredits: 0,
      dailyLeadLimit: 0,
      leadsPhoneNumberExportsPerMonth: 0,
      emailAccountsLimit: 0,
      verificationCredits: 0,
      freeTrialExpiresAt: null,
      selectedWorkspace: selectedWorkspace?._id || '',
      perDay: 0,
      perHour: 0,
      perMin: 0,
      buyerIntendData: false,
      advancedApis: false,
      multiChannelLinkedinOutreach: false
    },
    validationSchema: Yup.object({
      activeLeads: Yup.number().integer(),
      monthlyEmails: Yup.number().integer(),
      aiWriterCredits: Yup.number().integer(),
      leadsCredits: Yup.number().integer(),
      linkedinCredits: Yup.number().integer(),
      bounceCredit: Yup.number().integer(),
      dailyLeadLimit: Yup.number().integer(),
      leadsPhoneNumberExportsPerMonth: Yup.number().integer(),
      emailAccountsLimit: Yup.number().integer(),
      verificationCredits: Yup.number().integer(),
      freeTrialExpiresAt: Yup.date().nullable(),
      selectedWorkspace: Yup.string().required('Workspace is required'),
      perDay: Yup.number().integer(),
      perHour: Yup.number().integer(),
      perMin: Yup.number().integer(),
      buyerIntendData: Yup.boolean(),
      advancedApis: Yup.boolean(),
      multiChannelLinkedinOutreach: Yup.boolean()
    }),
    onSubmit: async (values) => {
      await sendUpdatedPlan(values);
      await onClose();
    },
  });

  useEffect(() => {
    const fetchWorkspaceData = async () => {
      if (selectedWorkspace) {
        try {
          const { data } = await getPlanUsage(selectedWorkspace._id);
          setWorkspaceData(data);

          formik.setValues({
            ...formik.values,
            selectedWorkspace: selectedWorkspace._id,
            activeLeads: data?.usage?.activeLeads,
            monthlyEmails: data?.usage?.monthlyEmails,
            aiWriterCredits: data?.usage?.aiWriterCredits,
            leadsCredits: data?.usage?.leadsCredits,
            linkedinCredits: data?.usage?.linkedinCredits,
            bounceCredit: data?.usage?.bounceCredit,
            dailyLeadLimit: data?.usage?.dailyLeadLimit,
            verificationCredits: data?.usage?.verificationCredits,
            leadsPhoneNumberExportsPerMonth: data?.usage?.leadsPhoneNumberExportsPerMonth,
            emailAccountsLimit: data?.usage?.emailAccountsLimit,
            perDay: data?.plan?.apiRateLimit?.limits?.perDay,
            perHour: data?.plan?.apiRateLimit?.limits?.perHour,
            perMin: data?.plan?.apiRateLimit?.limits?.perMin,
            freeTrialExpiresAt:
              data?.usage?.freeTrialExpiresAt ||
              data?.plan?.freeTrialExpiresAt,
            buyerIntendData: data?.usage?.buyerIntendData,
            advancedApis: data?.usage?.advancedApis,
            multiChannelLinkedinOutreach: data?.usage?.multiChannelLinkedinOutreach,
          });
        } catch (error) {
          console.error('Error fetching workspace data:', error);
        }
      }
    };

    fetchWorkspaceData();
  }, [selectedWorkspace]);

  const handleClose = () => {
    onClose();
  };
  const date = new Date(formik.values.freeTrialExpiresAt);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${month}-${day}-${year}`;

  const paidUser = workspaceData?.plan?.subscription?.sendingWarmup?.expiresAt;
  const dates = new Date(paidUser);
  const months = (dates.getMonth() + 1).toString().padStart(2, "0");
  const days = dates.getDate().toString().padStart(2, "0");
  const years = dates.getFullYear();
  const formattedPaidDate = `${months}-${days}-${years}`;
  const weeklyTrial = workspaceData?.plan?.subscription?.sendingWarmup?.planType; 

  const handleKeyDown = (e) => {
    // Prevent entering invalid characters
    const regex = /^[+-.]*$/; // Only allow digits
    if (regex.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          className={{ width: "500px" }}
        >
          <Box
            maxWidth="sm"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "full",
              margin: "1rem",
              bgcolor: "white",
              padding: "1rem",
            }}
          >
            <Typography
              variant="h3"
              component="h2"
              sx={{ cursor: "pointer", color: "rgba(40, 40, 123, 1)" }}
            >
              Plan Settings
            </Typography>
            {paidUser === undefined || weeklyTrial === "weekly"?
              (<></>)
              :
              (
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ cursor: "pointer", color: "red" }}
                >
                  The expiration date cannot be modified for paid accounts.
                </Typography>
              )

            }
            <Box sx={{ display: "flex", pt: "3rem", gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    Active Leads :
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="activeLeads"
                        placeholder="Active Leads"
                        value={formik.values.activeLeads}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={
                          !!(
                            formik.touched.activeLeads &&
                            formik.errors.activeLeads
                          )
                        }
                        helperText={
                          formik.touched.activeLeads &&
                          formik.errors.activeLeads
                        }
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },

                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    Monthly Emails :
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="monthlyEmails"
                        placeholder="Monthly Emails"
                        value={formik.values.monthlyEmails}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={
                          !!(
                            formik.touched.monthlyEmails &&
                            formik.errors.monthlyEmails
                          )
                        }
                        helperText={
                          formik.touched.monthlyEmails &&
                          formik.errors.monthlyEmails
                        }
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },

                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    AI Writer Credits :
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="aiWriterCredits"
                        placeholder="AI Writer Credits"
                        value={formik.values.aiWriterCredits}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={
                          !!(
                            formik.touched.aiWriterCredits &&
                            formik.errors.aiWriterCredits
                          )
                        }
                        helperText={
                          formik.touched.aiWriterCredits &&
                          formik.errors.aiWriterCredits
                        }
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },

                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    Leads Credits :
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="leadsCredits"
                        placeholder="Leads Credits"
                        value={formik.values.leadsCredits}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={
                          !!(
                            formik.touched.leadsCredits &&
                            formik.errors.leadsCredits
                          )
                        }
                        helperText={
                          formik.touched.leadsCredits &&
                          formik.errors.leadsCredits
                        }
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },

                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    Verification Credits :
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="bounceCredit"
                        placeholder="Verification Credits"
                        value={formik.values.bounceCredit}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={!!(formik.touched.bounceCredit && formik.errors.bounceCredit)}
                        helperText={formik.touched.bounceCredit && formik.errors.bounceCredit}
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },
                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    Linkedin Credits :
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="linkedinCredits"
                        placeholder="Linkedin Credits"
                        value={formik.values.linkedinCredits}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={!!(formik.touched.linkedinCredits && formik.errors.linkedinCredits)}
                        helperText={formik.touched.linkedinCredits && formik.errors.linkedinCredits}
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },
                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    DailyLead Limit :
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="dailyLeadLimit"
                        placeholder="DailyLead Limit"
                        value={formik.values.dailyLeadLimit}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={!!(formik.touched.dailyLeadLimit && formik.errors.dailyLeadLimit)}
                        helperText={formik.touched.dailyLeadLimit && formik.errors.dailyLeadLimit}
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },
                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    Verification Credits :
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="verificationCredits"
                        placeholder="Verification Credits"
                        value={formik.values.verificationCredits}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={!!(formik.touched.verificationCredits && formik.errors.verificationCredits)}
                        helperText={formik.touched.verificationCredits && formik.errors.verificationCredits}
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },
                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    Phone Number Exports :
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="leadsPhoneNumberExportsPerMonth"
                        placeholder="Phone Number Exports"
                        value={formik.values.leadsPhoneNumberExportsPerMonth}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={!!(formik.touched.leadsPhoneNumberExportsPerMonth && formik.errors.leadsPhoneNumberExportsPerMonth)}
                        helperText={formik.touched.leadsPhoneNumberExportsPerMonth && formik.errors.leadsPhoneNumberExportsPerMonth}
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },
                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    Email Accounts Limit :
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="emailAccountsLimit"
                        placeholder="Email Accounts Limit"
                        value={formik.values.emailAccountsLimit}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={!!(formik.touched.emailAccountsLimit && formik.errors.emailAccountsLimit)}
                        helperText={formik.touched.emailAccountsLimit && formik.errors.emailAccountsLimit}
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },
                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "25px",
                width: "100%", // Ensure full width is taken
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "26px",
                    color: "rgb(129, 129, 176)",
                  }}
                >
                  {formattedPaidDate !== "NaN-NaN-NaN" && weeklyTrial !== "weekly"
                    ? `Paid User ExpiresAt ${formattedPaidDate}`
                    : `Update Free Trial ExpiresAt ${formattedDate}`}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid container spacing={2} sx={{ my: 0.5 }}>
                  <Grid item xs={6}>
                    <TextField
                      disabled={
                        formattedPaidDate !== "NaN-NaN-NaN" && weeklyTrial !== "weekly" ? "disabled" : ""
                      }
                      fullWidth
                      type="date"
                      variant="outlined"
                      name="freeTrialExpiresAt"
                      placeholder="Leads Credits"
                      value={moment(formik.values.freeTrialExpiresAt).format(
                        "YYYY-MM-DD"
                      )}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      onKeyDown={handleKeyDown}
                      error={
                        !!(
                          formik.touched.freeTrialExpiresAt &&
                          formik.errors.freeTrialExpiresAt
                        )
                      }
                      helperText={
                        formik.touched.freeTrialExpiresAt &&
                        formik.errors.freeTrialExpiresAt
                      }
                      sx={{
                        "& div": {
                          pl: 0.3,
                          borderRadius: "0.7rem",
                          mt: "-0.2rem",
                        },
                        "& div fieldset": {
                          border: "1px solid #E4E4E5",
                        },

                        "& div input": {
                          py: 1.3,
                          fontSize: "13px",
                          fontWeight: 400,
                          lineHeight: "16px",
                          letterSpacing: "0em",
                          "&::placeholder": {
                            color: "rgba(40, 40, 123, 0.5)",
                          },
                        },
                      }}
                    />
                  </Grid>

                  {/* Workspace Selection Dropdown */}
                  <Grid item xs={6}>
                    <Autocomplete
                      id="workspace-autocomplete"
                      options={allWorkspaces}
                      getOptionLabel={(option) => option.name}
                      value={
                        allWorkspaces.find((workspace) => workspace._id === formik.values.selectedWorkspace) || null
                      }
                      onChange={(event, newValue) => {
                        setSelectedWorkspace(newValue);
                      }}
                      disabled={isPartnerAccount}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Workspace"
                          error={formik.touched.selectedWorkspace && Boolean(formik.errors.selectedWorkspace)}
                          helperText={formik.touched.selectedWorkspace && formik.errors.selectedWorkspace}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  flexDirection: 'column'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 'full'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '26px',
                      color: 'rgb(129, 129, 176)'
                    }}
                  >
                    Buyer Intend Data :
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: 'full',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <Switch
                        checked={formik.values.buyerIntendData}
                        onChange={({ target: { checked } }) => {
                          formik.setFieldValue('buyerIntendData', checked)
                        }}
                        name='buyerIntendData'
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  flexDirection: 'column'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 'full'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '26px',
                      color: 'rgb(129, 129, 176)'
                    }}
                  >
                    Advanced Apis :
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: 'full',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <Switch
                        checked={formik.values.advancedApis}
                        onChange={({ target: { checked } }) => {
                          formik.setFieldValue('advancedApis', checked)
                        }}
                        name='advancedApis'
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  flexDirection: 'column'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 'full'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '26px',
                      color: 'rgb(129, 129, 176)'
                    }}
                  >
                    Multi Channel Linkedin Outreach :
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: 'full',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <Switch
                        checked={formik.values.multiChannelLinkedinOutreach}
                        onChange={({ target: { checked } }) => {
                          formik.setFieldValue('multiChannelLinkedinOutreach', checked)
                        }}
                        name='multiChannelLinkedinOutreach'
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ mt: 3, mb: 2 }} textAlign="center">
              <Typography variant="h6" color="textSecondary">
                API Rate Limits
              </Typography>
            </Divider>
            <Box sx={{ display: "flex", pt: "0.5rem", gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    PerDay:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="perDay"
                        placeholder="Per Day"
                        value={formik.values.perDay}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={
                          !!(
                            formik.touched.perDay &&
                            formik.errors.perDay
                          )
                        }
                        helperText={
                          formik.touched.perDay &&
                          formik.errors.perDay
                        }
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },

                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    PerHour:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={3} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="perHour"
                        placeholder="Per Hour"
                        value={formik.values.perHour}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={
                          !!(
                            formik.touched.perHour &&
                            formik.errors.perHour
                          )
                        }
                        helperText={
                          formik.touched.perHour &&
                          formik.errors.perHour
                        }
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },

                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", pt: "3rem", gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "full",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "26px",
                      color: "rgb(129, 129, 176)",
                    }}
                  >
                    PerMin:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    width: "full",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ my: 0.5 }}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="perMin"
                        placeholder="Per Minute"
                        value={formik.values.perMin}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={handleKeyDown}
                        error={
                          !!(
                            formik.touched.perMin &&
                            formik.errors.perMin
                          )
                        }
                        helperText={
                          formik.touched.perMin &&
                          formik.errors.perMin
                        }
                        sx={{
                          "& div": {
                            pl: 0.3,
                            borderRadius: "0.7rem",
                            width: "20rem",
                            mt: "-0.2rem",
                          },
                          "& div fieldset": {
                            border: "1px solid #E4E4E5",
                          },

                          "& div input": {
                            py: 1.3,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            "&::placeholder": {
                              color: "rgba(40, 40, 123, 0.5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end", mt: "2rem" }}>
              <SaveButton
                onClick={formik.handleSubmit}
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default PlanSetting;

const SaveButton = ({ onClick, isSubmitting }) => {
  return (
    <Button
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "18px",
        py: 1,
        px: 2,
      }}
      variant="contained"
      type="submit"
      onClick={onClick}
    >
      {isSubmitting ? (
        <>
          <CircularProgress
            color="inherit"
            size={20}
            thickness={5}
            sx={{ mr: 1 }}
          />
          Saving
        </>
      ) : (
        <>Save</>
      )}
    </Button>
  );
};
