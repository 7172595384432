export const LFRevenue = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.7778 7.55556C16.5566 6.92804 16.1527 6.38107 15.6181 5.98504C15.0834 5.58901 14.4425 5.36203 13.7778 5.33333H9.33333C8.44928 5.33333 7.60143 5.68452 6.97631 6.30964C6.35119 6.93477 6 7.78261 6 8.66667C6 9.55072 6.35119 10.3986 6.97631 11.0237C7.60143 11.6488 8.44928 12 9.33333 12H13.7778C14.6618 12 15.5097 12.3512 16.1348 12.9763C16.7599 13.6014 17.1111 14.4493 17.1111 15.3333C17.1111 16.2174 16.7599 17.0652 16.1348 17.6904C15.5097 18.3155 14.6618 18.6667 13.7778 18.6667H9.33333C8.66861 18.638 8.02768 18.411 7.49303 18.015C6.95839 17.6189 6.5545 17.072 6.33333 16.4444M11.5556 2V5.33333M11.5556 18.6667V22"
          stroke="#28287B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
