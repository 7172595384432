import { createSlice } from '@reduxjs/toolkit';

const account = createSlice({
  name: 'accounts',
  initialState: [],
  reducers: {
    setAccounts(state, action) {
      return action.payload;
    },
    accountsAdded(state, action) {
      state.push(...action.payload);
    },
    accountUpdated(state, action) {
      const updatedAccount = action.payload;
      const index = state.findIndex((a) => a._id === updatedAccount._id);
      if (index >= 0) {
        state[index] = { ...state[index], ...updatedAccount };
      }
    },
    accountDeleted(state, action) {
      const id = action.payload;
      return state.filter((a) => a._id !== id);
    },
    accountsDeleted(state, action) {
      const { deleteAll, ids } = action.payload;
      if (deleteAll) return [];
      return state.filter((a) => !ids.includes(a._id));
    }
  }
});

export default account.reducer;

export const { setAccounts, accountsAdded, accountUpdated, accountDeleted, accountsDeleted } = account.actions;
