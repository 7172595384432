import { Box, Grid, Slider, Typography } from '@mui/material';

import { EDSCalendarIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSCalendarIcon';
import { EDSFIleCheckIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSFIleCheckIcon';
import { EDSChartIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSChartIcon';
import { EDSStarIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSStarIcon';
import { EDSWarningIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSWarningIcon';
import { EDSMailOpenIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSMailOpenIcon';
import CustomCheckbox from 'components/CustomCheckbox';
const AdvanceWarmup = ({ formik }) => {
  return (
    <Box
      sx={{
        borderRadius: '12px',
        p: 3,
        pb: 0,
        mt: 2,
        border: '1px solid #E4E4E5',
        mb: 3
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: 'full',
              borderBottom: '1px solid #E4E4E5',
              pb: 2
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  color: '#28287B'
                }}
              >
                Warmup Settings | Advanced
              </Typography>
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: '#8181B0',
                  mt: 1
                }}
              >
                Advanced settings to make warmup behavior more human-like
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 4
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '75%',
                mr: 2
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <EDSCalendarIcon />
                </Box>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '26px',
                    color: '#28287B',
                    ml: 1.5
                  }}
                >
                  Weekdays Only
                </Typography>
              </Box>
              <Typography
                sx={{
                  mt: 1,
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: '#8181B0'
                }}
              >
                Only send warmup emails on weekdays for a more natural sending pattern
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mr: '-26px'
              }}
            >
              <CustomCheckbox
                name="warmup.advanceSetting.weekdayOnly"
                checked={formik.values.warmup.advanceSetting.weekdayOnly}
                onChange={formik.handleChange}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              my: 4
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '75%',
                mr: 2
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <EDSFIleCheckIcon />
                </Box>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '26px',
                    color: '#28287B',
                    ml: 1.5
                  }}
                >
                  Read Emulation
                </Typography>
              </Box>
              <Typography
                sx={{
                  mt: 1,
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: '#8181B0'
                }}
              >
                Spend time and scroll through your warmup email to emulate human-like reading
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mr: '-26px'
              }}
            >
              <CustomCheckbox
                name="warmup.advanceSetting.readEmulation"
                checked={formik.values.warmup.advanceSetting.readEmulation}
                onChange={formik.handleChange}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '75%',
                mr: 2
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <EDSChartIcon />
                </Box>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '26px',
                    color: '#28287B',
                    ml: 1.5
                  }}
                >
                  Warm custom tracking domain
                </Typography>
              </Box>
              <Typography
                sx={{
                  mt: 1,
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: '#8181B0'
                }}
              >
                Include your custom tracking domain in your warmup emails to further improve deliverability
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mr: '-26px'
              }}
            >
              <CustomCheckbox
                name="warmup.advanceSetting.customTrackingDomain"
                checked={formik.values.warmup.advanceSetting.customTrackingDomain}
                onChange={formik.handleChange}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: 'full',
              flexDirection: 'column',
              mt: 3
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 4,
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  width: '45%',
                  mr: 2
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <EDSMailOpenIcon />
                  </Box>
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '26px',
                      color: '#28287B',
                      ml: 1.5
                    }}
                  >
                    Open rate
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    mt: 1,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: '#8181B0'
                  }}
                >
                  How many of your warm up emails to open
                </Typography>
              </Box>
              <Box sx={{ width: '25%' }}>
                <Slider
                  aria-label="Always visible"
                  name="warmup.advanceSetting.openRate"
                  value={formik.values.warmup.advanceSetting.openRate}
                  onChange={formik.handleChange}
                  valueLabelDisplay="on"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 4,
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  width: '45%',
                  mr: 2
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <EDSWarningIcon />
                  </Box>
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '26px',
                      color: '#28287B',
                      ml: 1.5
                    }}
                  >
                    Spam Protection
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    mt: 1,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: '#8181B0'
                  }}
                >
                  How many of your warm up emails to save from spam folder
                </Typography>
              </Box>
              <Box sx={{ width: '25%' }}>
                {' '}
                <Slider
                  aria-label="Always visible"
                  name="warmup.advanceSetting.spamProtectionRate"
                  value={formik.values.warmup.advanceSetting.spamProtectionRate}
                  onChange={formik.handleChange}
                  valueLabelDisplay="on"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 4,
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  width: '45%',
                  mr: 2
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <EDSStarIcon />
                  </Box>
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '26px',
                      color: '#28287B',
                      ml: 1.5
                    }}
                  >
                    Mark important
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    mt: 1,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: '#8181B0'
                  }}
                >
                  How many of your warm up emails to mark as important
                </Typography>
              </Box>
              <Box sx={{ width: '25%' }}>
                {' '}
                <Slider
                  aria-label="Always visible"
                  name="warmup.advanceSetting.markImportantRate"
                  value={formik.values.warmup.advanceSetting.markImportantRate}
                  onChange={formik.handleChange}
                  valueLabelDisplay="on"
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdvanceWarmup;
