import { CDAnalytics } from 'assets/campaignDetails/CDAnalytics';
import { CDLeads } from 'assets/campaignDetails/CDLeads';
import { CDOptions } from 'assets/campaignDetails/CDOptions';
import { CDSchedule } from 'assets/campaignDetails/CDSchedule';
import { CDSequences } from 'assets/campaignDetails/CDSequences';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { setUserAccessToken } from 'utils/localstorage.helper';

import {
  Grid,
  IconButton,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Drawer,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useLazyGetCampaignQuery, usePauseCampaignMutation, useResumeCampaignMutation } from 'services/campaign-service';
import { CloseOutlined, WidgetsOutlined } from '@mui/icons-material';
import Analytics from 'pages/components/campaign/Analytics';
import Leads from 'pages/components/campaign/Leads';
import Sequences from 'pages/components/campaign/Sequences';
import Schedule from 'pages/components/campaign/Schedule';
import Options from 'pages/components/campaign/Options';
import { useGetUserTokenQuery } from 'services/auth-service';
import { useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
const items = [
  { label: 'Analytics', icon: (active) => <CDAnalytics color={active ? '#0071F6' : '#28287B'} /> },
  { label: 'Leads', icon: (active) => <CDLeads color={active ? '#0071F6' : '#28287B'} /> },
  { label: 'Sequences', icon: (active) => <CDSequences color={active ? '#0071F6' : '#28287B'} /> },
  { label: 'Schedule', icon: (active) => <CDSchedule color={active ? '#0071F6' : '#28287B'} /> },
  {
    label: 'Configurations',
    icon: (active) => <CDOptions color={active ? '#0071F6' : '#28287B'} />
  }
];

const CampaignMenu = ({ items, setValue, value }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        boxShadow: { xs: 0, md: '0px 12px 15px 0px #4B71970D' },
        width: '100%',
        backgroundColor: 'white',
        borderRadius: '12px'
      }}
    >
      <List sx={{ width: '100%', mt: 2 }}>
        {items.map((item, i) => {
          return (
            <ListItem
              disablePadding
              key={i}
              sx={{
                px: '16px',
                pb: '16px',
                cursor: 'pointer'
              }}
              onClick={() => {
                setValue(i);
              }}
            >
              {' '}
              <Tooltip title="" placement="right" arrow>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    backgroundColor: i === value && 'rgba(242, 244, 246, 1)',
                    px: 2,
                    borderRadius: '10px',
                    width: '100%'
                  }}
                >
                  {' '}
                  <ListItemIcon
                    sx={{
                      minWidth: 'auto',
                      color: i === value ? 'black' : 'neutral.400',
                      py: '8px'
                    }}
                  >
                    {item.icon(i === value)}
                  </ListItemIcon>{' '}
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{
                      variant: 'caption',
                      sx: {
                        color: i === value ? '#0071F6' : '#28287B',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '18px',
                        letterSpacing: '0px',
                        textAlign: 'left',
                        ml: 1
                      }
                    }}
                    sx={{ ml: 1 }}
                  />
                </Box>
              </Tooltip>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

const CampaignSettings = () => {
  const [params] = useSearchParams();
  const userId = params.get('id');
  const campaignId = params.get('campaignId');

  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [campaignMenuOpen, setCampaignMenuOpen] = useState(false);

  const { data } = useGetUserTokenQuery({ id: userId });

  const [value, setValue] = useState(0);
  const [refetch, setRefetch] = useState(false);

  const [getCampaign, { data: campaign, isLoading: isCampaignLoading }] = useLazyGetCampaignQuery();
  const [pauseCampaign] = usePauseCampaignMutation();
  const [resumeCampaign] = useResumeCampaignMutation();

  useEffect(() => {
    if (data?.authToken) {
      setUserAccessToken(data?.authToken);
      getCampaign({ id: campaignId });
    }
  }, [data]);

  useEffect(() => {
    if (refetch) {
      getCampaign({ id: campaignId });
      setRefetch(false);
    }
  }, [refetch]);

  const handlePauseCampaignClick = async (userId) => {
    const { message } = await pauseCampaign({ id: userId }).unwrap();
    toast.success(message);
  };

  const handleResumeCampaignClick = async (userId) => {
    try {
      const { message } = await resumeCampaign({ id: userId }).unwrap();
      toast.success(message);
    } catch (error) {
      toast.error(error.data.error.message);
    }
  };

  const handleReftch = () => {
    setRefetch(true);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h3" component="h2">
          Campaign
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          pt: 0.6
        }}
      >
        <Box
          sx={{
            width: '90%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: { md: 'none', xs: 'flex' },
              justifyContent: 'flex-end',
              width: '100%',
              my: 2
            }}
          >
            <IconButton
              onClick={() => setCampaignMenuOpen(true)}
              sx={{
                width: '36px',
                height: '36px',
                borderRadius: '8px',
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main
              }}
            >
              <WidgetsOutlined />
            </IconButton>
          </Box>
          <Grid container sx={{ height: '100%', mt: { xs: 0, sm: 1 } }} spacing={3}>
            <Drawer
              open={isMobile ? campaignMenuOpen : false}
              variant="temporary"
              onClose={() => setCampaignMenuOpen(false)}
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: { sm: '300px', xs: '100%' }
                }
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  zIndex: 1
                }}
              >
                <IconButton onClick={() => setCampaignMenuOpen(false)}>
                  <CloseOutlined />
                </IconButton>
              </Box>
              <CampaignMenu items={items} setValue={setValue} value={value} />
            </Drawer>
            <Grid item xs={3} sx={{ display: { xs: 'none', md: 'block' } }}>
              <CampaignMenu items={items} setValue={setValue} value={value} />
            </Grid>
            <Grid item xs={12} md={9} sx={{ width: '100%', position: 'relative' }}>
              {isCampaignLoading || !data?.authToken || !campaign ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 10
                  }}
                >
                  <CircularProgress size={25} thickness={5} />
                  <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#4e88e6', ml: 2 }}>Loading...</Typography>
                </Box>
              ) : value === 0 ? (
                <Analytics campaign={campaign} handlePause={handlePauseCampaignClick} handleResume={handleResumeCampaignClick} />
              ) : value === 1 ? (
                <Leads campaign={campaign} handleReftch={handleReftch} />
              ) : value === 2 ? (
                <Sequences campaign={campaign} handleReftch={handleReftch} />
              ) : value === 3 ? (
                <Schedule campaign={campaign} handleReftch={handleReftch} />
              ) : value === 4 ? (
                <Options campaign={campaign} handleReftch={handleReftch} />
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CampaignSettings;
