export const Appsumo = () => {
  return (
    <>
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="32" height="32" rx="16" fill="#ff7675" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 11a3 3 0 100 6 3 3 0 000-6zm-5 8c0-2.761 2.239-5 5-5s5 2.239 5 5v1H11v-1z"
          fill="#d63031"
        />
      </svg>
    </>
  );
};
