import { useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from "@mui/material";
import { CloseTwoTone, CloudUploadTwoTone, TaskAlt } from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import FileUploadRowBlocklist from "./FileUploadRowBlocklist";
import { UploadColoredIcon } from "assets/general/UploadColoredIcon.js";
import { OffCheckboxCustomIcon } from "assets/general/OffCheckboxCustomIcon.js";
import { OnCheckboxCustomIcon } from "assets/general/OnCheckboxCustomIcon.js";
import { useAddBlocklistMutation, useDuplicateCheckMutation} from "services/user-service";
import { handleDialogUploadAllClick, handleUploadAllClick, handleSelectTypeChange, updateData } from "utils/util.js";

const selections = [
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Domain",
    value: "domain",
  },
  {
    label: "Do not import",
    value: "DO_NOT_IMPORT",
  },
];

const CsvImport = ({ listType, setSnackbarOpen, setSnackbarMsg, onLeadsCreate }) => {
  const fileInputRef = useRef(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [closeButtonMouseEnter, setCloseButtonMouseEnter] = useState(false);
  const [samples, setSamples] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);
  const [progress, setProgress] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [leads, setLeads] = useState([]);
  const [domains, setDomains] = useState([]);
  const [open, setOpen] = useState(false);
  const [stats, setStats] = useState({});
  const [checkDuplicates, setCheckDuplicates] = useState(true);
  const [addBlocklist, {isLoading : isUploading }] = useAddBlocklistMutation()
  const [duplicateCheck] = useDuplicateCheckMutation();

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    handleDragLeave(e);
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    handleFileUpload(file);
  };

  const onTypeChange = (column, selection) => {
    handleSelectTypeChange(csvData, column, selection, setCsvData, setLeads, setDomains);
  };

  const handleFileUpload = (file) => {
    setProgress(0);
    if (file) {
      const allowedTypes = ["text/csv"];
      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onprogress = (event) => {
          const { total, loaded } = event;
          setProgress((loaded / total) * 100);
        };
        reader.onload = (event) => {
          const csv = event.target.result;
          const lines = csv.split(/\r?\n/);
          const columns = lines[0].split(",");
          setFileColumns(columns);
          let isSampleSet = false;
          const csvData = [];
          for (let i = 1; i < lines.length; i++) {
            const line = lines[i].split(",");
            if (!line.some(Boolean)) continue;
            if (!isSampleSet) {
              setSamples(line);
              isSampleSet = true;
            }
            const row = [];
            for (let j = 0; j < line.length; j++) {
              const type =
                selections.find((s) => s.label === columns[j])?.value ||
                selections[selections.length - 1].value;
              if (!line[j]) continue;
              row.push({
                type,
                column: columns[j],
                value: line[j],
              });
            }
            csvData.push(row);
          }         
          setCsvData(csvData);
          updateData(csvData, listType, setLeads, setDomains);
        };
        reader.readAsText(file);
      } else {
        alert("Only .csv files are allowed.");
      }
    }
  };

  const handleDeleteFile = () => {
    fileInputRef.current.value = "";
    setSelectedFile(null);
    setCloseButtonMouseEnter(false);
    setProgress(0);
    setFileColumns([]);
    setCsvData([]);
    setLeads([]);
    setDomains([]);
  };

  const onUploadClick = () => {
    handleDialogUploadAllClick({
      listType,
      leads,
      domains,
      isUploading,
      checkDuplicates,
      stats,
      addBlocklist,
      setOpen,
      setSnackbarOpen,
      setSnackbarMsg,
      onLeadsCreate
    });
  };

  const onUploadAllClick = async () => {
    await handleUploadAllClick(checkDuplicates, listType, leads, domains, duplicateCheck, setStats, setOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Paper
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        sx={{
          width: "100%",
          height: "260px",
          borderRadius: "12px",
          border: "1px dashed #0071F6",
          mt: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
          boxShadow: "0px 12px 15px 0px #4B71970D",
          backgroundColor: "#F2F4F6",
        }}
        onClick={() => {
          !closeButtonMouseEnter && fileInputRef.current.click();
        }}
      >
        <input
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          ref={fileInputRef}
          accept=".csv"
          disabled={isUploading}
        />
        {selectedFile ? (
          <>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "25px",
                color: "#0071F6",
              }}
            >
              Size: {(selectedFile.size / 1000).toFixed(2)} KB
            </Typography>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2 }}>
            <UploadColoredIcon />
          </Box>
        )}

        <Typography
          sx={{ fontSize: "20px", fontWeight: 700, lineHeight: "25px", color: "#0071F6" }}
        >
          {selectedFile
            ? selectedFile.name
            : isDragActive
            ? "Drop the files here ..."
            : "Drag files to this area or click to pick files."}
        </Typography>
        {selectedFile && (
          <IconButton
            onMouseEnter={() => {
              setCloseButtonMouseEnter(true);
            }}
            onMouseLeave={() => {
              setCloseButtonMouseEnter(false);
            }}
            sx={{ position: "absolute", right: 0, top: 0, m: 1 }}
            onClick={handleDeleteFile}
            disabled={isUploading}
          >
            <CloseTwoTone sx={{ color: "black" }} />
          </IconButton>
        )}
        {selectedFile && (
          <Box sx={{ width: "80%", mt: 2, display: progress === 100 && "none" }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        )}
      </Paper>
      {selectedFile && progress === 100 && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <TaskAlt sx={{ color: "rgb(33, 111, 237)", mr: 1 }} />
            <Typography sx={{ fontWeight: 600, color: "rgb(33, 111, 237)" }}>
              Files Processed
            </Typography>
          </Box>
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={360}>Column Name</TableCell>
                  <TableCell width={360}>Select Type</TableCell>
                  <TableCell>Samples</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileColumns.map((column, index) => (
                  <FileUploadRowBlocklist
                    key={index}
                    column={column}
                    sample={samples[index]}
                    selections={selections} 
                    onChange={(s) => onTypeChange(column, s)}
                    isUploading={isUploading}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  icon={<OffCheckboxCustomIcon />}
                  checkedIcon={<OnCheckboxCustomIcon />}
                  checked={checkDuplicates}
                  onChange={(e, value) => setCheckDuplicates(value)}
                />
              }
              label="Check for duplicates across existing records"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "13px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  color: "#28287B",
                },
                mt: 2,
              }}
            />
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
          </Box>
          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              px: 3,
              py: 1.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
            onClick={ checkDuplicates ? onUploadAllClick : onUploadClick}
          >
            {isUploading ? (
              <CircularProgress size={20} sx={{ color: "white", mr: 1 }} />
            ) : (
              <CloudUploadTwoTone sx={{ mr: 1 }} />
            )}
            Upload All
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ fontSize: "25px", color: "#595959", textAlign: "center" }}>
              Are you sure?
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                This will upload{" "}
                <Typography component="span" color={"blue"}>
                  {stats.uploadedCount}{" "}
                </Typography>{" "}
                records to your blocklist .{" "}
                <Typography component="span" color={"blue"}>
                  {" "}
                  {stats?.duplicateItemsCount || 0}{" "}
                </Typography>{" "}
                 duplicate records.
              </Typography>
            </DialogContent>
            <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  onUploadClick();
                }}                
                variant="contained"
                sx={{
                  fontSize: "16px",
                  borderRadius: "12px",
                  px: 2.5,
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                {isUploading || isUploading ? (
                  "Uploading..."
                ) : (
                  <>
                    <CloudUploadTwoTone sx={{ mr: 1 }} />
                    Upload
                  </>
                )}
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  // backgroundColor: "#595959",
                  color: "Black",
                  fontSize: "16px",
                  borderRadius: "12px",
                  px: 3.2,
                  py: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 1,
                  "&:hover": {
                    backgroundColor: "#787878",
                  },
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default CsvImport;
