import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box, CircularProgress } from '@mui/material';
import { Total } from 'assets/campaignDetailsLeads/Total';
import { Paid } from 'assets/dashboard/users/Paid';
import { Free } from 'assets/dashboard/users/Free';
import { Enabled } from 'assets/dashboard/users/Enabled';
import Chart from 'react-apexcharts';
import { useGetAllUsersQuery } from 'services/user-service';
import PlanChart from './PlanChart';
import RevenueChart from './RevenueChart';
import SignupUserChart from './SignupUserChart';

const Dashboard = () => {
  const { data, isLoading: refetchLoader } = useGetAllUsersQuery();
  const [users, setUsers] = useState({});

  const userChartData = {
    options: {
      dataLabels: {
        enabled: false
      },
      labels: ['All Users', 'Free Users', 'Enabled Users', 'Paid Users'],
      colors: ['#008ffb', '#ff8322', '#00aa38', '#d588f7'],
      plotOptions: {
        pie: {
          donut: {
            size: '65%'
          }
        }
      },
      responsive: [
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 300
            }
          }
        },
        {
          breakpoint: 360,
          options: {
            chart: {
              width: 270
            }
          }
        }
      ]
    },
    series: Object.values(users)
  };

  useEffect(() => {
    if (data) {
      setUsers({
        allUsers: data?.users?.statistics?.activeUsers + data?.users?.statistics?.deletedUsers ?? [],
        freeUsers: data?.users?.statistics?.freeUsers ?? [],
        enabledUsers: data?.users?.statistics?.activeUsers ?? [],
        paidUsers: data?.users?.statistics?.paidUsers ?? []
      });
    }
  }, [data]);

  return (
    <>
      <Typography
        sx={{
          color: '#28287B',
          fontSize: '30px',
          fontWeight: 600,
          lineHeight: '28px',
          letterSpacing: '0px'
        }}
      >
        Analytics
      </Typography>
      <Grid container gap={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: 'white',
              color: '#262626',
              overflow: 'hidden',
              borderRadius: '8px',
              borderColor: '#e6ebf1',
              mt: 2,
              p: 2
            }}
          >
            <Typography
              sx={{
                color: '#28287B',
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '28px',
                letterSpacing: '0px',
                my: 1,
                textUnderlineOffset: 'auto'
              }}
            >
              Users
            </Typography>
            <Box
              sx={{
                width: 1,
                display: { md: 'flex' },
                pl: 1,
                border: '1px solid #f0f0f0',
                borderRadius: '12px',
                p: 1.3,
                gap: 2
              }}
            >
              <Box
                sx={{
                  width: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'spaceBetween',
                  flexBasis: '75%'
                }}
              >
                <Grid container columnSpacing={3} rowSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: 2,
                        borderRadius: '12px',
                        border: '1px solid rgba(33, 111, 237, 0.5)',
                        padding: 2,
                        cursor: 'pointer'
                      }}
                    >
                      <Box>
                        <Total />
                      </Box>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#28287B',
                            fontWeight: '600',
                            fontSize: '15px'
                          }}
                        >
                          All Users
                        </Typography>
                        <Typography>{users.allUsers}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: 2,
                        borderRadius: '12px',
                        border: '1px solid rgba(33, 111, 237, 0.5)',
                        padding: 2,
                        cursor: 'pointer'
                      }}
                    >
                      <Box>
                        <Paid />
                      </Box>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#28287B',
                            fontWeight: '600',
                            fontSize: '15px'
                          }}
                        >
                          Paid Users
                        </Typography>
                        <Typography>{users.paidUsers}</Typography>
                      </Box>
                    </Box>
                  </Grid>{' '}
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: 2,
                        borderRadius: '12px',
                        border: '1px solid rgba(33, 111, 237, 0.5)',
                        padding: 2,
                        cursor: 'pointer'
                      }}
                    >
                      <Box>
                        <Free />
                      </Box>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#28287B',
                            fontWeight: '600',
                            fontSize: '15px'
                          }}
                        >
                          Free Users
                        </Typography>
                        <Typography>{users.freeUsers}</Typography>
                      </Box>
                    </Box>
                  </Grid>{' '}
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: 2,
                        borderRadius: '12px',
                        border: '1px solid rgba(33, 111, 237, 0.5)',
                        padding: 2,
                        cursor: 'pointer'
                      }}
                    >
                      <Box>
                        <Enabled />
                      </Box>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#28287B',
                            fontWeight: '600',
                            fontSize: '15px'
                          }}
                        >
                          Enabled Users
                        </Typography>
                        <Typography>{users.enabledUsers}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {refetchLoader ? (
                <Box
                  sx={{
                    height: { xs: '15rem' },
                    border: '1px solid #f0f0f0',
                    borderRadius: '10px',
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: { xs: 2, md: 0 }
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #f0f0f0',
                    borderRadius: '10px',
                    mt: { xs: 1, md: 0 }
                  }}
                >
                  <Chart options={userChartData.options} series={userChartData.series} type="donut" width="380" />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: { md: 'flex' }, justifyContent: 'space-between', alignItems: 'center', gap: '2rem' }}>
        <PlanChart />
        <RevenueChart />
      </Box>
      <Box>
        <Box sx={{ mt: '1rem' }}>
          <SignupUserChart />
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
