import { useState } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Plus } from '../../../assets/general/Plus';
import { useCreateCampaignScheduleMutation, useUpdateCampaignMutation } from '../../../services/campaign-service.js';
import toast from 'react-hot-toast';
import { CalendarIcon } from '../../../assets/general/CalendarIcon';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import ScheduleBlock from './ScheduleBlock';

const today = dayjs();

const CampaignSchedule = (props) => {
  const { campaign, handleReftch } = props;
  const { schedules } = campaign;

  const [startDate, setStartDate] = useState(dayjs(campaign?.startDate) || today);
  const [endDate, setEndDate] = useState(dayjs(campaign?.endDate) || startDate);
  const [openedSchedule, setOpenedSchedule] = useState(null);

  const [createCampaignSchedule, { isLoading: isCreateCampaignLoading }] = useCreateCampaignScheduleMutation();
  const [updateCampaign] = useUpdateCampaignMutation();

  const handleSaveCampaignClick = async () => {
    const { message } = await updateCampaign({
      id: campaign?._id,
      data: {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      }
    }).unwrap();
    handleReftch();
    toast.success(message);
  };

  const handleCreateSchedule = async () => {
    if (isCreateCampaignLoading) return;
    const { message, schedule } = await createCampaignSchedule({
      id: campaign?._id,
      data: { name: 'New Schedule' }
    }).unwrap();
    handleReftch();
    setOpenedSchedule(schedule?._id);
    toast.success(message);
  };

  return (
    <>
      <Grid container sx={{}}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column'
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  color: '#28287B'
                }}
              >
                Schedules let you set when to send your emails.
              </Typography>
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  letterSpacing: '0em',
                  color: '#8181B0',
                  mt: 0.5
                }}
              >
                {' '}
                Choose an existing schedule or create a new one.
              </Typography>
            </Box>

            <Button
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#164694',
                  boxShadow: 10
                },
                textAlign: 'left',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                letterSpacing: '0em',
                color: 'white',
                backgroundColor: '#0071F6',
                borderRadius: '8px',
                px: 1.5,
                py: 1.5
              }}
              variant="outlined"
              size="large"
              onClick={handleCreateSchedule}
            >
              {isCreateCampaignLoading ? (
                <>
                  <CircularProgress size={20} sx={{ color: 'white', mr: 1 }} />
                  Creating...
                </>
              ) : (
                <>
                  <Box sx={{ mr: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Plus />
                  </Box>
                  New schedule
                </>
              )}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
                <CalendarIcon />
              </Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  color: '#28287B',
                  mr: 2
                }}
              >
                Start:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker disablePast value={startDate} onChange={(date) => setStartDate(date)} />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
                <CalendarIcon />
              </Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  color: '#28287B',
                  mr: 2
                }}
              >
                End:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker value={endDate} minDate={startDate} onChange={(date) => setEndDate(date)} />
              </LocalizationProvider>
            </Box>
            <Button
              sx={{
                color: '#0071F6',
                pt:1,
                cursor: 'pointer',
                '&:hover': {
                  color: '#164694'
                },
                fontSize: '13px',
                fontWeight: 700,
                lineHeight: '16px'
              }}
              variant="outlined"
              onClick={handleSaveCampaignClick}
            >
              Save
            </Button>
          </Box>
        </Grid>
        {schedules &&
          schedules?.map((schedule) => {
            return (
              <Grid xs={12} key={schedule?._id}>
                <ScheduleBlock
                  schedule={schedule}
                  open={schedule?._id === openedSchedule}
                  setOpenedSchedule={setOpenedSchedule}
                  showDelete={schedules?.length > 1}
                  handleReftch={handleReftch}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default CampaignSchedule;
