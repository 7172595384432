import React from 'react';
import { DropDown } from 'assets/general/DropDown';
import { Grid, Button, Typography, Popover, Box, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { EDSCalendarIcon } from 'assets/emailAccounts/emailDrawer/EDSettingsTab/EDSCalendarIcon';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { setDateRange } from 'store/reducers/users';
import lodash from 'lodash';
import { downloadCsv } from 'utils/util';
import { planNames } from 'config';
import { useGetUserInvoicesMutation } from 'services/auth-service';
import { useSearchParams } from 'react-router-dom';

const Invoice = ({ name }) => {
  const componentVerify = window.location.href.split('/').reverse()[0];

  return (
    <>
      <Grid container gap={3}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: '#28287B',
              fontSize: '30px',
              fontWeight: 600,
              lineHeight: '28px',
              letterSpacing: '0px'
            }}
          >
            {name ? `${name}` : componentVerify === 'dashboard' ? '' : 'Invoice Details'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            <FilterDayWeekMonth />
            <CustomFilter />
          </Box>
          {!componentVerify === 'dashboard' && <DownloadCsv />}
        </Grid>
      </Grid>
    </>
  );
};
export default Invoice;

function DownloadCsv() {
  const [userPlan] = useGetUserInvoicesMutation();
  const { userList } = useSelector((state) => state.users);
  const { exportUsers } = useSelector((state) => state.users);
  const [downloadType, setDownloadType] = React.useState(null);
  const [show, setShow] = React.useState(null);
  const componentVerify = window.location.href.split('/').reverse()[0];
  const [params] = useSearchParams();
  const ids = params.get('id');
  let userId;
  if (ids) {
    userId = window.atob(ids);
  } else {
    userId = null;
  }
  const handleClick = (event) => {
    setShow(event.currentTarget);
  };

  const handleClose = () => {
    setShow(null);
  };

  function getFormaDate(dateTimeString) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const date = new Date(dateTimeString);
    const formattedDate = `${monthNames[String(date.getMonth())]} ${String(date.getDate()).padStart(2, '0')}, ${date.getFullYear()}`;
    return formattedDate;
  }

  const getDateFromTimestamp = (timeStamp) => {
    const date = new Date(timeStamp * 1000);
    const formatedDate = date.toISOString().replace(/T/, ' ').replace(/\..+/, '');
    return formatedDate;
  };

  //csv download function

  const handleChange = async (type) => {
    setDownloadType(type);
    let datas = null;
    if (userId) {
      if (type == 'all') {
        const response = await userPlan({
          customerId: userId
        });
        handleClose();
        datas = response?.data?.invoices?.data;

        if (datas?.length) {
          const userArray = datas?.map((data) => {
            return {
              name: data?.customer_name,
              description: data?.lines?.data[0]?.description,
              amount: `${data?.amount_paid / 100} $`,
              created: getFormaDate(getDateFromTimestamp(data?.created)),
              status: data?.status,
              invoice_pdf: data?.invoice_pdf
            };
          });
          const list = userArray.map((data) => {
            return lodash.pick(data, ['name', 'description', 'amount', 'created', 'status', 'invoice_pdf']);
          });
          handleClose();
          downloadCsv('users', list);
        }
      } else if (componentVerify === 'invoice') {
        datas = userList;
        if (datas?.length) {
          const userArray = datas?.map((data) => {
            return {
              name: data?.customerName,
              description: data?.description,
              amount: `${data?.amount / 100} $`,
              created: getFormaDate(getDateFromTimestamp(data?.createdAt)),
              status: data?.status,
              invoice_pdf: data?.invoicelink
            };
          });
          const list = userArray.map((data) => {
            return lodash.pick(data, ['name', 'description', 'amount', 'created', 'status', 'invoice_pdf']);
          });
          handleClose();
          downloadCsv('users', list);
        }
      }
    } else {
      if (type == 'all') {
        datas = exportUsers?.allUsers;
        if (datas?.length) {
          const userArray = datas?.map((data) => {
            return {
              email: data?.user?.email,
              name: data?.user?.name.first + ' ' + data?.user?.name.last,
              sendingWarmup: planNames[data?.subscription?.sendingWarmup?.planId] || '-',
              leads: planNames[data?.subscription?.leads?.planId] || '-',
              expireAt: data?.freeTrialExpiresAt.slice(0, 10)
            };
          });
          const list = userArray.map((data) => {
            return lodash.pick(data, ['email', 'name', 'sendingWarmup', 'leads', 'expireAt']);
          });
          handleClose();
          downloadCsv('users', list);
        }
      } else {
        datas = userList;
        if (datas?.length) {
          const userArray = datas?.map((data) => {
            return {
              email: data?.email,
              name: data?.name,
              sendingWarmup: planNames[data?.sendingWarmup] || '-',
              leads: planNames[data?.leads] || '-',
              expireAt: data?.expireAt.slice(0, 10)
            };
          });
          const list = userArray.map((data) => {
            return lodash.pick(data, ['email', 'name', 'sendingWarmup', 'leads', 'expireAt']);
          });
          handleClose();
          downloadCsv('users', list);
        }
      }
    }
    setDownloadType(null);
    handleClose();
  };

  const open = Boolean(show);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box>
        <Button variant="contained" onClick={handleClick}>
          Download CSV
        </Button>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={show}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Button
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0px 12px 0px 0px'
          }}
          onClick={() => handleChange('page')}
        >
          <Checkbox inputProps={{ 'aria-label': 'controlled' }} size="small" checked={downloadType === 'page'} />
          <Typography>Select page</Typography>
        </Button>
        <Button
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0px 12px 0px 0px'
            // gap: 1,
          }}
          onClick={() => handleChange('all')}
        >
          <Checkbox inputProps={{ 'aria-label': 'controlled' }} size="small" checked={downloadType === 'all'} />
          <Typography>Select All</Typography>
        </Button>
      </Popover>
    </>
  );
}

function FilterDayWeekMonth() {
  const timelineButtons = [
    {
      name: 'Select Date Range',
      value: {
        start: null,
        end: null
      }
    },
    {
      name: 'Last 7 days',
      value: {
        start: new Date().setDate(new Date().getDate() - 7),
        end: Date.now()
      }
    },
    {
      name: 'Month to date',
      value: { start: new Date().setDate(1), end: Date.now() }
    },
    {
      name: 'Last 4 weeks',
      value: {
        start: new Date().setDate(new Date().getDate() - 28),
        end: Date.now()
      }
    },
    {
      name: 'Last 3 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 3),
        end: Date.now()
      }
    },
    {
      name: 'Last 6 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 6),
        end: Date.now()
      }
    },
    {
      name: 'Last 12 months',
      value: {
        start: new Date().setMonth(new Date().getMonth() - 12),
        end: Date.now()
      }
    }
  ];
  const [timeline, setTimeline] = React.useState(timelineButtons[0]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          fontSize: '13px',
          fontWeight: 700,
          lineHeight: '16.38px',
          color: '#28287B',
          backgroundColor: '#fff',
          px: 1.5,
          mr: 2,
          '&:hover': {
            backgroundColor: '#fff'
          },
          border: '1px solid #E4E4E5',
          height: '36px'
        }}
        onClick={handleClick}
      >
        {timeline?.name}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml: 1
          }}
        >
          <DropDown />
        </Box>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1.1,
            width: '200px'
          }}
        >
          {timelineButtons.map((item, i) => {
            return (
              <Button
                key={i}
                fullWidth
                sx={{
                  py: 1.1,
                  px: 2,
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#101828',
                  fontSize: '13px',
                  backgroundColor: timeline?.name === item.name && 'rgb(33, 111, 237, 0.1)'
                }}
                onClick={() => {
                  setTimeline(item);
                  dispatch(
                    setDateRange({
                      name: item?.name,
                      ...item?.value
                    })
                  );
                  setAnchorEl(null);
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  {item.name}
                </Box>
              </Button>
            );
          })}
        </Box>
      </Popover>
    </>
  );
}

function CustomFilter() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl);
  const id2 = open2 ? 'simple-popover' : undefined;

  const handleApplyClick = () => {
    //Date, month, year of start date
    const startMonth = startDate.toDate().getMonth() + 1;
    const startDateNum = startDate.toDate().getDate();
    const startYear = startDate.toDate().getFullYear();

    //Date, month, year of end date
    const endMonth = endDate.toDate().getMonth() + 1;
    const endDateNum = endDate.toDate().getDate();
    const endYear = endDate.toDate().getFullYear();

    // formatted date strings
    const startDateString = `${startYear}-${startMonth < 10 ? '0' + startMonth : startMonth}-${
      startDateNum < 10 ? '0' + startDateNum : startDateNum
    }`;
    const endDateString = `${endYear}-${endMonth < 10 ? '0' + endMonth : endMonth}-${endDateNum < 10 ? '0' + endDateNum : endDateNum}`;

    // date to milliseconds
    const start = new Date(startDateString).getTime();
    const end = new Date(endDateString).getTime();
    dispatch(setDateRange({ name: 'Custom Range', start, end }));
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          fontSize: '13px',
          fontWeight: 700,
          lineHeight: '16.38px',
          color: '#28287B',
          backgroundColor: '#fff',
          px: 1.5,
          mr: { xs: 0, sm: 2 },
          '&:hover': {
            backgroundColor: '#fff'
          },
          border: '1px solid #E4E4E5',
          height: '36px'
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mr: 1
          }}
        >
          <EDSCalendarIcon />
        </Box>
        Custom Range
      </Button>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            p: 2,
            width: 'fit-content'
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              letterSpacing: '0em',
              color: '#28287B',
              mr: 2,
              mb: 1
            }}
          >
            From:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker disableFuture maxDate={endDate} value={startDate} onChange={(date) => setStartDate(date)} />
          </LocalizationProvider>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              letterSpacing: '0em',
              color: '#28287B',
              mr: 2,
              mt: 2,
              mb: 1
            }}
          >
            To:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker disableFuture minDate={startDate} value={endDate} onChange={(date) => setEndDate(date)} />
          </LocalizationProvider>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              mt: 2
            }}
          >
            <Button
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
                dispatch(
                  setDateRange({
                    name: 'Month to date',
                    start: null,
                    end: null
                  })
                );
                setAnchorEl(null);
              }}
            >
              Clear
            </Button>
            <Button variant="contained" disabled={!(startDate && endDate)} onClick={handleApplyClick}>
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
