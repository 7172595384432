export const Replied = () => {
  return (
    <>
      <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#E7F0FF" />
        <path
          d="M9.9427 6.79995L6.40003 10.3426M6.40003 10.3426L9.9427 13.8853M6.40003 10.3426L13.6 10.3426C15.8092 10.3426 17.6 12.1335 17.6 14.3426L17.6 17.2"
          stroke="#0071F6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
