import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useGetAllPartnerUsersQuery } from 'services/user-service';
import Search from '../../../layout/MainLayout/Header/HeaderContent/Search';
import toast from 'react-hot-toast';
import {
  Box,
  Link,
  Table,
  TableSortLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import GlobalStyles from '@mui/material/GlobalStyles';
import LoaderCircle from 'components/LoaderCircle';
import { useDispatch, useSelector } from 'react-redux';
import {
  useDisableUserMutation,
  useEnableUserMutation,
  useLazyGetUserAuthTokenQuery,
} from 'services/auth-service';
import ActionMenu from 'components/Menu';
import Popover from '@mui/material/Popover';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CustomCheckbox from 'pages/components/campaign/CustomCheckbox';
import { setUserType, setUsers } from 'store/reducers/users';
import { Total } from 'assets/campaignDetailsLeads/Total';
import { Paid } from 'assets/dashboard/users/Paid';
import { Free } from 'assets/dashboard/users/Free';
import { Enabled } from 'assets/dashboard/users/Enabled';
import DeleteConformation from 'pages/components/DeleteConformation/DeleteConformation';
import { ChangePassword } from './../../user/users/ChangePassword';
import { UserCard } from './../../user/users/UserCard';
import EmailSetting from 'pages/components/EmailSettings/EmailSettings';
import PlanSelector from './PlanSelector';
import { useParams } from 'react-router-dom';

function createData(email, name, plan, managePlan, warmupTag, createdAt, lastLogout, status, action, id) {
  return {
    email,
    name,
    plan,
    managePlan,
    warmupTag,
    createdAt,
    lastLogout,
    status,
    action,
    id
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getFormaDate(dateTimeString) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date(dateTimeString);
  const formattedDate = `${monthNames[String(date.getMonth())]} ${String(
    date.getDate()
  ).padStart(2, '0')}, ${date.getFullYear()}`;
  return formattedDate;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'email',
    align: 'left',
    disablePadding: false,
    label: 'E-MAIL',
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'NAME',
  },
  {
    id: 'assignPlan',
    align: 'center',
    disablePadding: true,
    label: 'ASSIGN PLAN',
  },
  {
    id: 'managePlan',
    align: 'center',
    disablePadding: true,
    label: 'MANAGE SUBSCRIPTION',
  },
  {
    id: 'warmupTag',
    align: 'left',
    disablePadding: false,
    label: 'WARMUPTAG',
  },
  {
    id: 'createdAt',
    align: 'left',
    disablePadding: false,
    label: 'CREATED AT',
  },
  {
    id: 'lastLogout',
    align: 'left',
    disablePadding: false,
    label: 'LAST LOGOUT',
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'STATUS',
  },
  {
    id: 'changepassword',
    align: 'cener',
    disablePadding: false,
    label: 'Change Password',
  },
  {
    id: 'action',
    align: 'center',
    disablePadding: false,
    label: 'ACTION',
  },
  {
    id: 'delete',
    align: 'center',
    disablePadding: false,
    label: 'DELETE',
  },
];

function OrderTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: 'rgb(242, 244, 246)',
              color: 'rgb(40, 40, 123)',
              fontSize: '13px',
              fontWeight: '500',
            }}
          >
            {headCell.id !== 'action' &&
              headCell.id !== 'warmupTag' &&
              headCell.id !== 'plan' &&
              headCell.id !== 'managePlan' &&
              headCell.id !== 'appSumo' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

export default function OrderTable() {

  const { id: partnerId } = useParams();
  const [orderBy, setOrderBy] = useState('email');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [open] = useState(false);
  const [totalUser, setTotaluser] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  const { dateRange } = useSelector((state) => state.users);
  const { searchData } = useSelector((state) => state.search);
  const [userId, setUserId] = useState(null);
  const [getToken, { data: tokenData }] = useLazyGetUserAuthTokenQuery();
  const [refetchUser, setRefetchUser] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState('Active');
  const [statusActive] = useEnableUserMutation();
  const [statusDisable] = useDisableUserMutation();
  const [statusLoading, setStatusLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pagePerRows, setPagePerRows] = useState(15);
  const [deleteConformation, setDeleteConformation] = useState(false);
  const [changeUserEmail, setChangeUserEmail] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const {
    data,
    refetch,
    isLoading: refetchLoader,
  } = useGetAllPartnerUsersQuery({
    partnerId: partnerId,
    sortBy: orderBy,
    page: page + 1,
    limit: pagePerRows,
    order,
    userType: name,
    ...(dateRange?.start && { start: dateRange?.start }),
    ...(dateRange?.end && { end: dateRange?.end }),
    ...(searchData?.length && { search: searchData }),
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const opened = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClickUserEmail = (id, email) => {
    setUserId(id);
    setUserEmail(email);
    setChangeUserEmail(true);
  };

  const handleDeleteAction = (deleteId) => {
    setDeleteConformation(true);
    setUserId(deleteId);
  };

  const handleCloseDeleteConformation = () => {
    setDeleteConformation(false);
  };

  const handleRowsPerPageChange = (event) => {
    setPagePerRows(event.target.value);
  };

  const toggleHandleClick = async (toggle, id) => {
    try {
      if (toggle) {
        //true
        setStatusLoading(true);
        await statusDisable({
          userId: id,
        });
        await refetch();
        setStatusLoading(false);
        toast.success('User is blocked');
      } else {
        //false
        setStatusLoading(true);
        await statusActive({
          userId: id,
        });
        await refetch();
        setStatusLoading(false);
        toast.success('User is active');
      }
    } catch (error) {
      setStatusLoading(false);
    }
  };

  const actionMenuData = [
    {
      name: 'Change User Email',
      onChange: (id, data) => handleClickUserEmail(id, data.email),
    },
  ];

  const onActionMenuClick = async (id) => {
    setRedirect(true);
    await getToken({ id });
  };

  useEffect(() => {
    const handleRefetchData = async () => {
      setStatusLoading(true);
      await refetch();
      setStatusLoading(false);
    };

    if (refetchUser) {
      handleRefetchData();
      setRefetchUser(false);
    } else {
      handleRefetchData();
    }
  }, [
    dateRange,
    name,
    page,
    orderBy,
    order,
    searchData?.length > 0,
    refetchUser,
  ]);

  useEffect(() => {
    if (data?.users?.docs?.length) {
      const rows = data?.users?.docs?.map((cur) => {
        return createData(
          cur.email,
          cur.name.first + ' ' + cur.name.last,
          cur.plan,
          cur.managePlan,
          cur.warmupTag,
          cur.createdAt,
          cur.lastLogout,
          cur.isDeleted,
          cur.action,
          cur._id,
          cur?.appSumoCode,
          cur?.EarlyBirdPlan,
        );
      });
      setRows(rows);
      dispatch(setUsers(rows));
      setTotaluser(Number(data?.users?.totalDocs) || 0);
    } else {
      setTotaluser(0);
    }
  }, [data]);

  useEffect(() => {
    if (tokenData?.authToken && redirect) {
      setRedirect(false);
      window.open(
        `${process.env.REACT_APP_FRONTEND_URL}/verify?token=${tokenData?.authToken}&skipUpdate=true`,
        '_blank'
      );
    }
  }, [tokenData]);

  if (refetchLoader) {
    return <LoaderCircle />;
  }
  const userStats = data?.users?.statistics || {};

  return (
    <>
      <>
        <Box
          sx={{
            width: 1,
            pl: 1,
            border: '1px solid #f0f0f0',
            borderRadius: '12px',
            p: 1.3,
          }}
        >
          <Box
            sx={{
              width: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'space-between',
              flexWrap: 'wrap',
              gap: 1
            }}
          >
            <UserCard
              Icon={Total}
              title="All Users"
              count={userStats.activeUsers + userStats.deletedUsers}
            />
            <UserCard
              Icon={Paid}
              title="Paid Users"
              count={userStats.paidUsers}
            />

            <UserCard
              Icon={Free}
              title="Free Users"
              count={userStats.freeUsers}
            />

            <UserCard
              Icon={Enabled}
              title="Enabled Users"
              count={userStats.activeUsers}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
            mr: 3,
            my: 2,
            gap: 2,
          }}
        >
          <Box sx={{ width: { xs: '97%', sm: '30%' } }}>
            <Search />
          </Box>
          <Box sx={{ px: 1 }}>
            <Box
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              onClick={handleClick}
              sx={{
                width: 200,
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                p: 1,
                border: '2px solid rgba(33, 111, 237, 0.5)',
                fontSize: '15px',
                borderRadius: '12px',
                color: '#28287B',
                fontWeight: '600',
              }}
            >
              <Box>{name}</Box>
              <Box sx={{ display: 'flex' }}>
                {opened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Box>
            </Box>
          </Box>
          <Popover
            sx={{ mt: 0.7 }}
            id={id}
            open={opened}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
          >
            <Box sx={{ width: 200, p: 1, gap: 1 }}>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('Active');
                  dispatch(setUserType('Active'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'Active'}
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('Not Active');
                  dispatch(setUserType('Not Active'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'Not Active'}
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('All');
                  dispatch(setUserType('All'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'All'}
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('Paid');
                  dispatch(setUserType('Paid'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'Paid'}
              </Typography>
              <Typography
                onClick={() => {
                  handleClosePopover();
                  setName('Free');
                  dispatch(setUserType('Free'));
                }}
                sx={{
                  border: '1px solid #E4E4E5',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  p: 0.5,
                  mb: 0.3,
                  '&:hover': {
                    backgroundColor: '#739fe5',
                    color: 'white',
                  },
                }}
              >
                {'Free'}
              </Typography>
            </Box>
          </Popover>
        </Box>
        {statusLoading ? (
          <LoaderCircle />
        ) : totalUser === 0 ? (
          <>
            <Box
              sx={{
                width: 1,
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '2rem',
              }}
            >
              No {name == 'All' ? '' : name == 'Not Active' ? 'InActive' : name}{' '}
              User Available
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                border: '1px solid #ebebeb',
                borderRadius: '12px',
                width: { xs: '100%' },
              }}
            >
              <TableContainer
                sx={{
                  width: '100%',
                  // height: 'calc(100vh - 200px)',
                  overflowx: 'auto',
                  maxHeight: '100vh', // 80vh
                  transition:
                    'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  boxShadow:
                    'rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
                  borderRadius: '6px',
                  color: 'rgb(16, 24, 40)',
                  '& td, & th': { whiteSpace: 'nowrap' },
                }}
              >
                <GlobalStyles
                  styles={{
                    '*::-webkit-scrollbar': {
                      width: '8px',
                      height: '8px',
                    },
                    '*::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgb(228, 228, 229)',
                      borderRadius: '10px',
                      border: '1px solid rgba(0, 0, 0, 0)',
                    },
                    '*::-webkit-scrollbar-track': {
                      borderRadius: '60px',
                      width: '4px',
                      backgroundColor: 'rgb(242, 244, 246)',
                    },
                  }}
                />
                <Table
                  aria-labelledby="tableTitle"
                  sx={{
                    '& .MuiTableCell-root:first-of-type': {
                      pl: 2,
                    },
                    '& .MuiTableCell-root:last-of-type': {
                      pr: 3,
                    },
                  }}
                  stickyHeader
                >
                  <OrderTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map(
                      (row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                            tabIndex={-1}
                            key={row.email}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              align="left"
                            >
                              <Link
                                color="secondary"
                                component={RouterLink}
                                to=""
                              >
                                {row.email}
                              </Link>
                            </TableCell>
                            <TableCell
                              sx={{
                                pl: '12px !important',
                                align: 'left',
                              }}
                            >
                              {row.name}
                            </TableCell>
                            <PlanSelector row={row} setRefetchUser={setRefetchUser} />
                            <TableCell align="center">
                              <ActionMenu
                                options={actionMenuData}
                                actionId={row.id}
                                data={row}
                              />
                            </TableCell>
                            <TableCell align="left">{row.warmupTag}</TableCell>
                            <TableCell align="left">
                              {getFormaDate(row.createdAt)}
                            </TableCell>
                            <TableCell align="left">
                              {getFormaDate(row.lastLogout)}
                            </TableCell>
                            <TableCell align="left">
                              <CustomCheckbox
                                size={'small'}
                                checked={!row.status}
                                onClick={() => {
                                  toggleHandleClick(!row.status, row.id);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <ChangePassword id={row.id} email={row.email} />
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                type="button"
                                onClick={() => {
                                  onActionMenuClick(row.id);
                                }}
                                sx={{
                                  backgroundColor: '#216fed', //'#1890ff'
                                  color: '#ffffff',
                                  border: 'none',
                                  padding: '4px 12px',
                                  fontSize: '14px',
                                  cursor: 'pointer',
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                  transition: 'box-shadow 0.3s',
                                  '&:hover': {
                                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                                    backgroundColor: '#216fed',
                                  },
                                }}
                              >
                                Manage
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                type="button"
                                onClick={() => {
                                  handleDeleteAction(row.id);
                                }}
                                sx={{
                                  backgroundColor: '#ff0000', //'#1890ff'
                                  color: '#ffffff',
                                  border: 'none',
                                  padding: '4px 12px',
                                  fontSize: '14px',
                                  cursor: 'pointer',
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                  transition: 'box-shadow 0.3s',
                                  '&:hover': {
                                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                                    backgroundColor: '#ff0000',
                                  },
                                }}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                sx={{
                  border: '1px solid #f0f0f0',
                  borderRadius: 2,
                }}
                component="div"
                rowsPerPageOptions={[15, 25, 50, 100]}
                rowsPerPage={pagePerRows}
                onRowsPerPageChange={handleRowsPerPageChange}
                count={totalUser}
                page={page}
                onPageChange={handleChangePage}
              />
            </Box>
          </>
        )}
      </>
      <DeleteConformation
        open={deleteConformation}
        onClose={handleCloseDeleteConformation}
        userId={userId}
        setRefetchUser={setRefetchUser}
      />
      <EmailSetting
        open={changeUserEmail}
        onClose={() => { setChangeUserEmail(false); setRefetchUser(true); }}
        userId={userId}
        userEmail={userEmail}
      />
    </>
  );
}
