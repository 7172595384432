import { Box, Button, Checkbox, CircularProgress, FormControlLabel, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { OffCheckboxCustomIcon } from '../../../assets/general/OffCheckboxCustomIcon.js';
import { OnCheckboxCustomIcon } from '../../../assets/general/OnCheckboxCustomIcon.js';
import { useCreateLeadsMutation } from '../../../services/campaign-service.js';

const ManualImport = ({ campaign, onLeadsCreate }) => {
  const [manualImportText, setManualImportText] = useState('');
  const [checkDuplicates, setCheckDuplicates] = useState(false);

  const [createLeads, { isLoading: isCreatingLeads }] = useCreateLeadsMutation();

  const handleImportEmailsClick = async () => {
    const lines = manualImportText.trim().split('\n');
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    const leadsArray = [];
    let name = '';
    let capitalize = '';
    for (const line of lines) {
      const [firstName, lastName, email] = line
        .trim()
        .replaceAll(/["'<>]/g, '')
        .split(' ')
        .map((v) => v.trim());
      if (!email) {
        if (firstName && !lastName) {
          if (emailRegex.test(firstName)) {
            name = firstName.split('@')[0];
            capitalize = (str) => str[0].toUpperCase() + str.slice(1);
            if (name.includes('.')) {
              let [emailFirstName, emailLastName] = name.split('.');
              emailFirstName = capitalize(emailFirstName);
              emailLastName = capitalize(emailLastName);
              leadsArray.push({ firstName: emailFirstName, lastName: emailLastName, email: firstName });
            } else {
              name = capitalize(name);
              leadsArray.push({ firstName: name, lastName: '', email: firstName });
            }
          }
        } else if (firstName && lastName) {
          if (emailRegex.test(lastName)) leadsArray.push({ firstName, email: lastName });
        }
      } else {
        if (emailRegex.test(email)) leadsArray.push({ firstName, lastName, email });
      }
    }

    if (!leadsArray.length) {
      toast.error('Invalid email address');
      return false;
    }

    const leads = leadsArray;

    try {
      const { message, createdLeads } = await createLeads({
        id: campaign._id,
        data: {
          leads,
          checkDuplicates
        }
      }).unwrap();
      onLeadsCreate(createdLeads);
      toast.success(message);
      setManualImportText('');
    } catch (error) {
      toast.error(error.data.error.message);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%'
        }}
      >
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '25px',
            color: '#28287B'
          }}
        >
          Bulk Add Emails by Hand
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
            backgroundColor: '#F2F4F6',
            p: 2,
            borderRadius: '12px',
            mt: 2,
            width: '100%'
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '18px'
            }}
          >
            💡 For adding emails with associated names, use any of these formats:
          </Typography>
          <Typography
            sx={{
              width: '100%',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '20px',
              p: 2,
              pb: 0
            }}
          >
            &#12644; John Doe&#12644; &lt;john@doe.com&gt; <br />
            &#12644;Jane Smith&#12644; jane@smith.com
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '20px',
            color: '#8181B0',
            mt: 3
          }}
        >
          Input or paste email IDs - enter one email per line
        </Typography>
        <TextField
          multiline
          rows={6}
          fullWidth
          placeholder={'"FirstName LastName" example@mail.com'}
          variant="outlined"
          sx={{
            mt: 1,
            backgroundColor: 'white',
            '& div': { pl: 2 },
            '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
            '& div input': {
              p: 1.3,
              px: 3,
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: '16px',
              letterSpacing: '0em',
              '&::placeholder': {
                color: 'rgba(40, 40, 123, 0.5)'
              }
            }
          }}
          value={manualImportText}
          onChange={(e) => setManualImportText(e.target.value)}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mt: 2
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                icon={<OffCheckboxCustomIcon />}
                checkedIcon={<OnCheckboxCustomIcon />}
                checked={checkDuplicates}
                onChange={(e, v) => setCheckDuplicates(v)}
              />
            }
            label="Ensure there are no duplicates across all marketing campaigns."
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '13px',
                fontWeight: 500,
                lineHeight: '16px',
                color: '#28287B'
              }
            }}
          />
          <Button variant="contained" disabled={!manualImportText.trim()} onClick={handleImportEmailsClick}>
            {isCreatingLeads ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Import Emails'}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ManualImport;
