import { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Grid, Box, Typography, Button, Switch,
  FormControlLabel, TextField, Autocomplete
} from '@mui/material';
import { Formik, Form } from 'formik';
import config, { planNames } from 'config';
import { useGetUserStandardPlanMutation, useCreateStandardPlanMutation, useGetAllPlansMutation } from 'services/user-service';
import { toast } from 'react-hot-toast';

const UserStandardPlan = ({ open, onClose, allWorkspaces, userId }) => {
  const [getUserStandardPlan] = useGetUserStandardPlanMutation();
  const [createStandardPlan] = useCreateStandardPlanMutation();
  const [getAllPlans] = useGetAllPlansMutation();
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [planName, setPlanName] = useState('');
  const [allPlans, setAllPlans] = useState([]);

  const plans = [
    {
      id: 'starter',
      title: 'starter',
      monthlyPlanId: config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID,
      yearlyPlanId: config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID,
      monthlyPrice: '59',
      yearlyPrice: '35'
    },
    {
      id: 'growth',
      title: 'growth',
      monthlyPlanId: config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID,
      yearlyPlanId: config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID,
      monthlyPrice: '99',
      yearlyPrice: '59'
    },
    {
      id: 'infinity',
      title: 'infinity',
      monthlyPlanId: config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID,
      yearlyPlanId: config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID,
      monthlyPrice: '149',
      yearlyPrice: '89'
    }
  ];

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await getAllPlans().unwrap();
        setAllPlans(response);
      } catch (error) {
        toast.error('Failed to fetch available plans');
      }
    };
    fetchPlans();
  }, [getAllPlans]);

  const findPlanByPrice = (price) => {
    
    const matchingPlan = allPlans.find(plan => {
      return Number(price)  ===  Number(plan.price);
    });
  
    return matchingPlan.priceId;
  };
  
  const findStandardPlanByPlanId = (planId) => {
    return plans.find(plan => 
      plan.monthlyPlanId === planId || plan.yearlyPlanId === planId
    );
  };

  const getSeedPlanNameFromPlanId = (planId) => {
    const planFullName = planId && planNames[planId] ? planNames[planId] : 'Free Plan';
    return planFullName;
  };

  const isPlanYearly = (planId) => {
    const planFullName = planNames[planId] ? planFullName.toLowerCase().includes('yearly') : false
    return planFullName;
  };

  useEffect(() => {
    if (allWorkspaces?.length > 0) {
      const defaultWorkspace = allWorkspaces[0];
      setSelectedWorkspace(defaultWorkspace);

      const fetchCurrentPlan = async () => {
        try {
          const userPlan = await getUserStandardPlan(defaultWorkspace._id).unwrap();
          const currentPlanId = userPlan?.subscription?.sendingWarmup?.planId || userPlan?.subscription?.leads?.planId ;

          if (currentPlanId) {
            const currentPlanName = getSeedPlanNameFromPlanId(currentPlanId);
            setPlanName(currentPlanName);

            const matchedPlan = findStandardPlanByPlanId(currentPlanId);
            if (matchedPlan) {
              const isYearly = isPlanYearly(currentPlanId);
              setSelectedPlan(matchedPlan);
              
              if (typeof setFieldValue === 'function') {
                setFieldValue('isYearly', isYearly);
                setFieldValue('selectedPlan', matchedPlan);
              }
            } else {
              setSelectedPlan(plans[0]);
            }
          } else {
            setPlanName('Free Plan');
            setSelectedPlan(plans[0]);
          }
        } catch (error) {
          toast.error('Failed to fetch current plan');
          setPlanName('Free Plan');
          setSelectedPlan(plans[0]);
        } finally {
          setLoading(false);
        }
      };

      fetchCurrentPlan();
    } else {
      setLoading(false);
    }
  }, [allWorkspaces, getUserStandardPlan]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  const handleFormSubmit = async (values, { setSubmitting, setPlanName, onClose, userId, selectedWorkspace }) => {
  if (!values.selectedPlan) {
    toast.error('Please select a plan');
    setSubmitting(false);
    return;
  }

  const workspaceId = selectedWorkspace?._id;
  const selectedPrice = values.isYearly 
    ? values.selectedPlan.yearlyPrice * 12
    : values.selectedPlan.monthlyPrice;

  const planId = findPlanByPrice(selectedPrice);

  if (!planId) {
    toast.error('Invalid plan configuration');
    setSubmitting(false);
    return;
  }

  try {
    const { message } = await createStandardPlan({
      user: userId,
      selectedWorkspace: workspaceId,
      planId,
      isYearly: values.isYearly
    }).unwrap();

    setPlanName(values.selectedPlan.planName);
    toast.success(message || 'Plan updated successfully');
    onClose();
    window.location.reload();
  } catch (error) {
    toast.error(error.message || 'Failed to update plan');
  } finally {
    setSubmitting(false);
  }
};

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Update Plan</DialogTitle>
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h5">Current Plan: <Typography component="span" variant="body2">{planName}</Typography></Typography>
      </Box>

      <Formik
        initialValues={{
          selectedWorkspace,
          selectedPlan,
          isYearly: false
        }}
        onSubmit={(values, formikHelpers) => handleFormSubmit(values, { ...formikHelpers, setPlanName, onClose, userId, selectedWorkspace })}
      >
        {({ values, setFieldValue, handleBlur, isSubmitting, touched, errors }) => (
          <Form>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isYearly"
                        checked={values.isYearly}
                        onChange={(event) => setFieldValue('isYearly', event.target.checked)}
                        onBlur={handleBlur}
                      />
                    }
                    label="Yearly Plan"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    options={allWorkspaces}
                    getOptionLabel={(option) => option.name || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Workspace"
                        variant="outlined"
                        onBlur={handleBlur}
                        error={touched.selectedWorkspace && Boolean(errors.selectedWorkspace)}
                        helperText={touched.selectedWorkspace && errors.selectedWorkspace}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setSelectedWorkspace(newValue);
                      setFieldValue('selectedWorkspace', newValue);
                    }}
                    value={selectedWorkspace}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    options={plans}
                    getOptionLabel={(option) => option.title || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Plan"
                        variant="outlined"
                        onBlur={handleBlur}
                        error={touched.selectedPlan && Boolean(errors.selectedPlan)}
                        helperText={touched.selectedPlan && errors.selectedPlan}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Box>
                          <Typography variant="subtitle1">{option.title}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            {values.isYearly 
                              ? `$${option.yearlyPrice}/month`
                              : `$${option.monthlyPrice}/month`
                            }
                          </Typography>
                        </Box>
                      </li>
                    )}
                    onChange={(event, newValue) => {
                      setSelectedPlan(newValue);
                      setFieldValue('selectedPlan', newValue);
                    }}
                    value={selectedPlan}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit" variant="contained" disabled={isSubmitting}>
                Update Plan
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UserStandardPlan;