import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme, size }) => ({
  width: 42,
  height: size && size === 'small' ? 22 : 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: size && size === 'small' ? 'translateX(20px)' : 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: size && size === 'small' ? 18 : 22,
    height: size && size === 'small' ? 18 : 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const CustomCheckbox = ({ name, checked, onChange, onClick, disabled, size }) => {
  return (
    <>
      <FormControlLabel
        control={
          <IOSSwitch sx={{ m: 1 }} size={size} disabled={disabled} name={name} checked={checked} onChange={onChange} onClick={onClick} />
        }
      />
    </>
  );
};

export default CustomCheckbox;
