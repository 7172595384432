import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useGetUserInvoicesMutation } from 'services/auth-service';
import LoaderCircle from 'components/LoaderCircle';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Table,
  TableSortLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { setUsers } from 'store/reducers/users';
import Invoice from '../Invoice';

function getFormaDate(dateTimeString) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const date = new Date(dateTimeString);
  const formattedDate = `${monthNames[String(date.getMonth())]} ${String(date.getDate()).padStart(2, '0')}, ${date.getFullYear()}`;
  return formattedDate;
}

function createData(amount, description, invoicelink, createdAt, status, customerName) {
  return {
    amount,
    description,
    invoicelink,
    createdAt,
    status,
    customerName
  };
}

const getDateFromTimestamp = (timeStamp) => {
  const date = new Date(timeStamp * 1000);
  const formatedDate = date.toISOString().replace(/T/, ' ').replace(/\..+/, '');
  return formatedDate;
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: 'description',
    align: 'center',
    disablePadding: true,
    label: 'DESCRIPTION'
  },
  {
    id: 'amount',
    align: 'center',
    disablePadding: true,
    label: 'AMOUNT'
  },
  {
    id: 'createdAt',
    align: 'center',
    disablePadding: true,
    label: 'CREATED AT'
  },
  {
    id: 'status',
    align: 'center',
    disablePadding: true,
    label: 'STATUS'
  },
  {
    id: 'invoicelink',
    align: 'center',
    disablePadding: false,
    label: 'INVOICE LINK'
  }
];

function OrderTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: 'rgb(242, 244, 246)',
              color: 'rgb(40, 40, 123)',
              fontSize: '13px',
              fontWeight: '500',
              cursor: 'pointer'
            }}
          >
            {headCell.id !== 'description' && headCell.id !== 'invoicelink' && headCell.id !== 'status' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string
};

const UserInvoices = () => {
  const [userPlan] = useGetUserInvoicesMutation();
  const [params] = useSearchParams();
  const { dateRange } = useSelector((state) => state.users);
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState('email');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(0);
  const [name, setName] = useState('');
  const [startingAfter, setStartingAfter] = useState(null);
  const [endingBefore, setEndingBefore] = useState(null);
  const [totalUser, setTotaluser] = useState(0);
  const [userID, setUserID] = useState('');
  const [endId, setEndId] = useState('');
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const id = params.get('id');
  const userId = window.atob(id);

  const getUserPlans = async () => {
    setIsLoading(true);
    const response = await userPlan({
      customerId: userId,
      ...(startingAfter && { startingAfter }),
      ...(endingBefore && { endingBefore }),
      limit: 15,
      ...(dateRange?.start && { start: dateRange?.start }),
      ...(dateRange?.end && { end: dateRange?.end })
    });
    setData(response);
    setIsLoading(false);
  };

  useEffect(() => {
    if (userId) {
      getUserPlans();
    }
  }, [page, userId, dateRange]);

  const handleChangePage = (_event, newPage) => {
    setIsLoading(true);

    if (page < newPage) {
      setEndingBefore(null);
      setStartingAfter(userID);
    } else {
      setStartingAfter(null);
      setEndingBefore(endId);
    }
    setPage(newPage);
  };

  const onClickAction = (link) => {
    window.open(link, '_blank');
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setIsLoading(true);
    if (data?.data?.invoices?.data?.length) {
      setName(data?.data?.invoices?.data[0]?.customer_name);
      setUserID(data?.data?.invoices?.data[data?.data?.invoices?.data.length - 1].id);
      setEndId(data?.data?.invoices?.data[0].id);
      const rows = data?.data?.invoices?.data?.map((cur) => {
        return createData(
          cur?.amount_paid,
          cur?.lines.data[0].description,
          cur?.invoice_pdf,
          cur?.created,
          cur?.status,
          cur?.customer_name
        );
      });
      setTotaluser(Number(data?.data?.invoices?.totalDocs) || 0);
      setRows(rows);
      dispatch(setUsers(rows));
      setIsLoading(false);
    } else {
      setTotaluser(0);
    }
  }, [data]);

  return (
    <>
      <Invoice name={name} />

      <Box
        sx={{
          backgroundColor: 'white',
          color: '#262626',
          overflow: 'hidden',
          borderRadius: '8px',
          borderColor: '#e6ebf1',
          mt: 4
        }}
      >
        {isLoading ? (
          <LoaderCircle />
        ) : (
          <>
            {totalUser === 0 ? (
              <>
                <Box
                  sx={{
                    width: 1,
                    height: '70vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '2rem'
                  }}
                >
                  Invoices are not found
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    border: '1px solid #ebebeb',
                    borderRadius: '12px'
                  }}
                >
                  <TableContainer
                    sx={{
                      width: '100%',
                      overflow: 'auto',
                      maxHeight: '100vh',
                      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                      boxShadow: 'rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
                      borderRadius: '6px',
                      color: 'rgb(16, 24, 40)',
                      '& td, & th': { whiteSpace: 'nowrap' }
                    }}
                  >
                    <GlobalStyles
                      styles={{
                        '*::-webkit-scrollbar': {
                          width: '8px',
                          height: '8px'
                        },
                        '*::-webkit-scrollbar-thumb': {
                          backgroundColor: 'rgb(228, 228, 229)',
                          borderRadius: '10px',
                          border: '1px solid rgba(0, 0, 0, 0)'
                        },
                        '*::-webkit-scrollbar-track': {
                          borderRadius: '60px',
                          width: '4px',
                          backgroundColor: 'rgb(242, 244, 246)'
                        }
                      }}
                    />
                    <Table
                      aria-labelledby="tableTitle"
                      sx={{
                        '& .MuiTableCell-root:first-of-type': {
                          pl: 2
                        },
                        '& .MuiTableCell-root:last-of-type': {
                          pr: 3
                        }
                      }}
                      stickyHeader
                    >
                      <OrderTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                      <TableBody>
                        {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                }
                              }}
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell align="center">{row.description}</TableCell>
                              <TableCell align="center" sx={{ fontWeight: '500' }}>
                                {row.amount <= 100 ? row.amount : row.amount / 100} $
                              </TableCell>
                              <TableCell align="center">{getFormaDate(getDateFromTimestamp(row.createdAt))}</TableCell>
                              <TableCell align="center">{row.status}</TableCell>
                              <TableCell align="center">
                                <Button
                                  type="button"
                                  onClick={() => {
                                    onClickAction(row.invoicelink);
                                  }}
                                  sx={{
                                    backgroundColor: '#216fed',
                                    color: '#ffffff',
                                    border: 'none',
                                    padding: '4px 12px',
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'box-shadow 0.3s',
                                    '&:hover': {
                                      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                                      backgroundColor: '#216fed'
                                    }
                                  }}
                                >
                                  Download Invoice
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    sx={{
                      border: '1px solid #f0f0f0',
                      borderRadius: 2
                    }}
                    component="div"
                    rowsPerPageOptions={[15]}
                    rowsPerPage={15}
                    count={totalUser}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default UserInvoices;
