import { Box, Dialog, Typography } from '@mui/material';
import CancelSubscription from '../subscription/CancelSubscription';
import { CloseOutlined } from "@mui/icons-material";


const ManageSetting = ({ onClose, open, userData, userId, setRefetchUser }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          backdropFilter: 'blur(1px) sepia(3%)'
        }}
        PaperProps={{ sx: { borderRadius: '1rem' } }}
      >
        <Box sx={{ width: '100%', height: '100%', p: 4 }}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-Between', alignItems: 'center', borderBottom: '2px solid rgb(228, 228, 229)' }}
          >
            <Typography
              variant="h3"
              component="h2"
              sx={{
                color: 'rgba(40, 40, 123, 1)',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                pb: '4px'
              }}
            >
              Manage Subscription
            </Typography>

            <CloseOutlined sx={{ cursor: 'pointer', fontSize: '2rem', color: 'rgba(40, 40, 123, 1)' }} onClick={handleClose} />
          </Box>
          {/* <Box sx={{ width: '100%', mt: 2 }}>
            <Grid
              container
              sx={{
                backgroundColor: '#F2F4F6',
                width: '100%',
                borderRadius: '8px',
                p: 0.4,
                border: '1px solid #F2F4F7',
                mb: 3
              }}
            >
              <Grid item xs={6}>
                <Button
                  fullWidth
                  sx={{
                    width: '18vw',
                    backgroundColor: value === 0 ? 'white' : 'transparent',
                    color: value === 0 ? '#0071F6' : '#8181B0',
                    '&:hover': {
                      backgroundColor: value === 0 ? 'white' : 'transparent'
                    },
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    letterSpacing: '0em',
                    boxShadow: value === 0 && '0px 1px 2px 0px #1018280F',
                    borderRadius: '5px',
                    py: 1
                  }}
                  onClick={() => {
                    setValue(0);
                  }}
                >
                  Custom
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  sx={{
                    width: '18vw',
                    backgroundColor: value === 1 ? 'white' : 'transparent',
                    color: value === 1 ? '#0071F6' : '#8181B0',
                    '&:hover': {
                      backgroundColor: value === 1 ? 'white' : 'transparent'
                    },
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    letterSpacing: '0em',
                    boxShadow: value === 1 && '0px 1px 2px 0px #1018280F',
                    borderRadius: '5px',
                    py: 1
                  }}
                  onClick={() => {
                    setValue(1);
                  }}
                >
                  Subscription
                </Button>
              </Grid>
            </Grid>
          </Box> */}

          <Box><CancelSubscription userData={userData} setRefetchUser={setRefetchUser} userId={userId} onClose={onClose}/></Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ManageSetting;
