import { Box } from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import Typography from 'themes/overrides/Typography';
import { useLocation } from 'react-router';

const Navigation = () => {
  const location = useLocation();
  const menuItems = menuItem(location);
  const navGroups = menuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;

      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
