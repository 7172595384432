import PropTypes from "prop-types";
import React from 'react';
import { MenuItem, Select, TableCell } from '@mui/material';
import { useGetCustomPlansQuery, useUpdatePartnerUserPlanMutation } from 'services/user-service';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const PlanSelector = ({ row, setRefetchUser }) => {
    const { id: partnerId } = useParams();
    const { data: planNames } = useGetCustomPlansQuery({ PartnerId: partnerId });

    const [updateAssignedPlan] = useUpdatePartnerUserPlanMutation();
    const currentPlan = row.plan?.customPlanId || '';

    const handlePlanChange = async (event) => {
        const newPlanId = event.target.value;
        try {
            const { message } = await updateAssignedPlan({
                userId: row.id,
                planId: newPlanId,
                partnerId: partnerId
            }).unwrap();

            setRefetchUser(true);
            toast.success(message || "Plan Assigned")
        } catch (error) {
            toast.error(`Error updating plan: ${error?.data?.message}`)
        }
    };

    return (
        <TableCell align="center">
            <Select
                value={currentPlan}
                onChange={handlePlanChange}
                size="small"
                displayEmpty
                sx={{
                    fontSize: '14px',
                    minWidth: '120px',
                    '& .MuiSelect-select': {
                        padding: '6px 10px'
                    }
                }}
            >
                <MenuItem value="" sx={{ fontSize: '14px' }} disabled>
                    Choose Plan
                </MenuItem>
                {planNames?.customPlan?.map((plan) => (
                    <MenuItem
                        key={plan._id}
                        value={plan._id}
                        sx={{ fontSize: '14px' }}
                    >
                        {plan.planName}
                    </MenuItem>
                ))}
            </Select>

        </TableCell>
    );
};

PlanSelector.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.string.isRequired,
        plan: PropTypes.shape({
            customPlanId: PropTypes.string
        })
    }).isRequired,
    setRefetchUser: PropTypes.func.isRequired
};

export default PlanSelector;