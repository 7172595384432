export const Clicked = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-link"
        width="36"
        height="36"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        // stroke="#a905b6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect width="24" height="24" rx="12" fill="#E3FFEC" />
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path stroke="#00AA38" d="M9 15l6 -6" />
        <path stroke="#00AA38" d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464" />
        <path stroke="#00AA38" d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463" />
      </svg>
    </>
  );
};
