import { Box } from "@mui/material";
import { LFJobTitles } from "./leadFinder/LFFilter/LFJobTitles";
import { LFLocation } from "./leadFinder/LFFilter/LFLocation";
import { LFIndustry } from "./leadFinder/LFFilter/LFIndustry";
import { LFEmployees } from "./leadFinder/LFFilter/LFEmployees";
import { LFRevenue } from "./leadFinder/LFFilter/LFRevenue";
import { LFKeywords } from "./leadFinder/LFFilter/LFKeywords";
import { LFLookalikeDomain } from "./leadFinder/LFFilter/LFLookalikeDomain";
import { LFTechnologies } from "./leadFinder/LFFilter/LFTechnologies";
import { LFDepartment } from "./leadFinder/LFFilter/LFDepartment";
import { LFName } from "./leadFinder/LFFilter/LFName";
import { LFCompanyName } from "./leadFinder/LFFilter/LFCompanyName";
import { Abc, BackspaceOutlined, BadgeOutlined } from "@mui/icons-material";

export const campaignData = [
  {
    id: 1,
    title: "Your Campaign Title",
    created: "8 days",
    status: "draft",
    sent: 0,
    opened: 0,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: "Total Sent",
        type: "bar",
        data: [10, 0, 0, 0, 20, 0, 0],
      },
      {
        name: "Total Opens",
        type: "bar",
        data: [3, 0, 0, 0, 15, 0, 0],
      },
      {
        name: "Total Replies",
        type: "line",
        data: [1, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
  {
    id: 2,
    title: "Jon_Withlinks_11_05",
    created: "2 months",
    status: "error",
    sent: 0,
    opened: 0,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: "Total Sent",
        type: "bar",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Total Opens",
        type: "bar",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Total Replies",
        type: "line",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
  {
    id: 3,
    title: "26/04 Gmail Campaign10",
    created: "2 months",
    status: "active",
    sent: 220,
    opened: 25,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: "Total Sent",
        type: "bar",
        data: [0, 0, 20, 0, 0, 30, 0],
      },
      {
        name: "Total Opens",
        type: "bar",
        data: [0, 0, 5, 0, 0, 15, 0],
      },
      {
        name: "Total Replies",
        type: "line",
        data: [0, 0, 2, 0, 0, 0, 0],
      },
    ],
  },
  {
    id: 4,
    title: "26/04 Gmail Campaign09",
    created: "2 months",
    status: "paused",
    sent: 220,
    opened: 12,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: "Total Sent",
        type: "bar",
        data: [0, 0, 0, 0, 0, 0, 10],
      },
      {
        name: "Total Opens",
        type: "bar",
        data: [0, 0, 0, 0, 0, 0, 2],
      },
      {
        name: "Total Replies",
        type: "line",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
  {
    id: 5,
    title: "26/04 Gmail Campaign06",
    created: "2 months",
    status: "completed",
    sent: 172,
    opened: 57,
    replied: 0,
    linkClicked: 0,
    opportunities: 0,
    series: [
      {
        name: "Total Sent",
        type: "bar",
        data: [0, 0, 150, 0, 0, 122, 0],
      },
      {
        name: "Total Opens",
        type: "bar",
        data: [0, 0, 75, 0, 0, 100, 0],
      },
      {
        name: "Total Replies",
        type: "line",
        data: [0, 0, 22, 0, 0, 15, 0],
      },
    ],
  },
];

export const emailData = [
  {
    id: 1,
    email: "annie@b2brocket.ai",
    emailsSentToday: 0,
    emailsSentPastWeek: 140,
    emailsLanded: 140,
    emailsSaved: 0,
    health: 100,
    warmup: "pause",
    series: [
      {
        name: "Warmup emails landed in spam",
        data: [1, 0, 0, 0, 0, 0],
      },
      {
        name: "Warmup emails sent",
        data: [8, 9, 4, 6, 5, 6],
      },
    ],
  },
  {
    id: 2,
    email: "danni@nftbrandsllc.com",
    emailsSentToday: 0,
    emailsSentPastWeek: 0,
    emailsLanded: 0,
    emailsSaved: 0,
    health: 0,
    warmup: "disabled",
    series: [
      {
        name: "Warmup emails landed in spam",
        data: [1, 0, 0, 0, 0, 0],
      },
      {
        name: "Warmup emails sent",
        data: [8, 9, 4, 6, 5, 6],
      },
    ],
  },
  {
    id: 3,
    email: "freddie@b2brocket.ai",
    emailsSentToday: 0,
    emailsSentPastWeek: 147,
    emailsLanded: 146,
    emailsSaved: 1,
    health: 99,
    warmup: "enable",
    series: [
      {
        name: "Warmup emails landed in spam",
        data: [1, 0, 4, 0, 0, 0],
      },
      {
        name: "Warmup emails sent",
        data: [1, 5, 6, 9, 4, 2],
      },
    ],
  },
];
export const inboxData = [
  {
    id: 1,
    user: "test1@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 2,
    user: "test1@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },

  {
    id: 3,
    user: "test2@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 4,
    user: "test3@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 5,
    user: "test4@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
  {
    id: 6,
    user: "test5@company.io",
    date: "Jul 3, 2023",
    content:
      "Thank you for your e-mail. I am out of the office until Wednesday, 7/5. For any urgent issues, please contact Chris Benson at cbenson@obsidian.net.",
  },
];

export const selectTypes = [
  "IMAP Username",
  "IMAP Password",
  "IMAP Port",
  "IMAP Host",
  "SMTP Username",
  "SMTP Password",
  "SMTP Port",
  "SMTP Host",
  "Email",
  "First Name",
  "Last Name",
  "Reply To",
  "Daily Limit",
  "Sending gap in minutes",
  "Warmup Enabled",
  "Warmup Increment",
  "Warmup Limit",
  "Warmup | Reply Rate Percent",
  "Warmup | Read Emulation",
  "Warmup | Warm tracking domain",
  "Warmup | Random email range (min)",
  "Warmup | Random email range (max)",
  "Warmup | Open rate",
  "Warmup | Spam protection rate",
  "Warmup | Mark important rate",
  "Do not import",
];

export const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const locationData = [
  {
    id: 0,
    label: "US > States",
    children: [
      "Alabama, US",
      "Alaska, US",
      "Arizona, US",
      "Arkansas, US",
      "California, US",
      "Colorado, US",
      "Connecticut, US",
      "Delaware, US",
      "Florida, US",
      "Georgia, US",
      "Hawaii, US",
      "Idaho, US",
      "Illinois, US",
      "Indiana, US",
      "lowa, US",
      "Kansas, US",
      "Kentucky, US",
      "Louisiana, US",
      "Maine, US",
      "Maryland, US",
      "Massachusetts, US",
      "Michigan, US",
      "Minnesota, US",
      "Mississippi, US",
      "Missouri, US",
      "Montana, US",
      "Nebraska, US",
      "Nevada, US",
      "New Hampshire, US",
      "New Jersey, US",
      "New Mexico, US",
      "New York, US",
      "North Carolina, US",
      "North Dakota, US",
      "Ohio, US",
      "Oklahoma, US",
      "Oregon, US",
      "Pennsylvania, US",
      "Rhode Island, US",
      "South Carolina, US",
      "South Dakota, US",
      "Tennessee, US",
      "Texas, US",
      "Utah, US",
      "Vermont, US",
      "Virginia, US",
      "Washington, US",
      "West Virginia, US",
      "Wisconsin, US",
      "Wyoming, US",
      "District of Columbia",
      "American Samoa",
      "Canal Zone",
      "Guam",
      "Puerto Rico",
      "Virgin Islands",
    ],
  },
  {
    id: 1,
    label: "US Metro Areas",
    children: [
      "AK > Anchorage Area",
      "AK > Fairbanks Area",
      "AL > Birmingham Area",
      "AL> Anniston Area",
      "AL > Dothan Area",
      "AL > Huntsville Area",
      "AL > Mobile Area",
      "AL > Montgomery Area",
      "AL > Tuscaloosa Area",
      "AR > Fort Smith Area",
      "AR > Jonesboro Area",
      "AZ > Phoenix Area",
      "CA > Eureka Area",
      "CA > Fresno Area",
      "CA > Irvine Area",
      "AR > Little Rock Area",
      "AZ > Tucson Area",
      "AZ > Yuma Area",
      "CA > Bakersfield Area",
      "CA > Chico Area",
      "CA > Eureka Area",
      "CA > Fresno Area",
      "CA> Los Angeles Area",
      "CA > Monterey Bay Area",
      "CA > Irvine Area",
      "CA> Palm Springs Area",
      "CA > Sacramento Area",
      "CA> San Diego Area",
      "CA > San Francisco Area",
      "CA > San Jose Area",
      "CA > Santa Barbara Area",
      "CO > Denver Area",
      "FL> Fort Myers Area",
      "CO Colorado Springs Area",
      "CO > Grand Junction Area",
      "CT > Hartford Area",
      "DC > Washington Area",
      "FL > Gainesville Area",
      "FL > Jacksonville Area",
      "FL Miami Area",
      "FL> Orlando Area",
      "FL> Panama City Area",
      "FL> Tallahassee Area",
      "FL> Tampa Bay Area",
      "FL> West Palm Beach Area",
      "GA> Albany Area",
      "GA > Atlanta Area",
      "GA> Augusta Area",
      "GA > Columbus Area",
      "GA> Macon Area",
      "GA > Savannah Area",
      "HI > Honolulu Area",
      "IA > Cedar Rapids Area",
      "IA > Davenport Area",
      "IA > Des Moines Area",
      "IA > Ottumwa Area",
      "IA > Sioux City Area",
      "ID > Boise City Area",
      "ID > Idaho Falls Area",
      "ID> Twin Falls Area",
      "IL > Chicago Area",
      "IL > Peoria Area",
      "IL> Quincy Area",
      "IN > Lafayette Area",
      "KS > Topeka Area",
      "IL > Rockford Area",
      "IL > Springfield Area",
      "IN > Evansville Area",
      "IN > Fort Wayne Area",
      "IN > Indianapolis Area",
      "IN > South Bend Area",
      "IN > Terre Haute Area",
      "KS > Wichita Area",
      "KY > Bowling Green Area",
      "KY > Lexington Area",
      "KY > Louisville Area",
      "KY > Paducah Area",
      "LA > Alexandria Area",
      "LA > Baton Rouge Area",
      "LA > Lafayette Area",
      "LA > Lake Charles Area",
      "LA > Monroe Area",
      "LA > New Orleans Area",
      "LA > Shreveport Area",
      "MA > Boston Area",
      "MD > Baltimore Area",
      "ME > Presquelsle Area",
      "MA > Springfield Area",
      "MD > Salisbury Area",
      "ME > Bangor Area",
      "ME > Portland Area",
      "MI > Alpena Area",
      "MI > Detroit Area",
      "MI > Flint Area",
      "MI > Grand Rapids Area",
      "MI > Lansing Area",
      "MI> Marquette Area",
      "MI > Traverse City Area",
      "MN > Duluth Area",
      "MN > Mankato Area",
      "MS > Columbus Area",
      "MN > Minneapolis Area",
      "MN > Rochester Area",
      "MO > Jefferson City Area",
      "MO > Joplin Area",
      "MO> Kansas City Area",
      "MO > Saint Joseph Area",
      "MO > Saint Louis Area",
      "MO > Springfield Area",
      "MS > Biloxi Area",
      "MS > Greenville Area",
      "MS > Hattiesburg Area",
      "MS > Jackson Area",
      "MS > Meridian Area",
      "MT > Billings Area",
      "MT > Butte Area",
      "MT > Glendive Area",
      "MT > Great Falls Area",
      "MT > Helena Area",
      "MT > Missoula Area",
      "NC > Charlotte Area",
      "NC > Greenville Area",
      "NC > Raleigh Area",
      "NC > Wilmington Area",
      "NC > Winston Salem Area",
      "ND > Bismarck Area",
      "ND > Fargo Area",
      "NE > Lincoln Area",
      "NE> North Platte Area",
      "NE > Omaha Area",
      "NM > Albuquerque Area",
      "NV > Las Vegas Area",
      "NV > Reno Area",
      "NY > Albany Area",
      "NY > Binghamton Area",
      "NY > Buffalo Area",
      "NY > Elmira Area",
      "NY > New York Area",
      "NY > Rochester Area",
      "NY > Syracuse Area",
      "NY > Utica Area",
      "NY > Watertown Area",
      "OH > Cincinnati Area",
      "OH > Cleveland Area",
      "OH > Columbus Area",
      "OH > Dayton Area",
      "OH > Lima Area",
      "OH > Toledo Area",
      "OH > Wheeling Area",
      "OH > Youngstown Area",
      "OH > Zanesville Area",
      "OK > Ardmore Area",
      "OK Oklahoma City Area",
      "OK > Tulsa Area",
      "OR > Eugene Area",
      "PA > Erie Area",
      "PA > Johnstown Area",
      "PA > Pittsburgh Area",
      "PA > Wilkes Barre Area",
      "PR Mayaguez Area",
      "PR > Ponce Area",
      "RI > Providence Area",
      "SC > Columbia Area",
      "SC> Florence Area",
      "OR > Bend Area",
      "OR > Medford Area",
      "OR > Portland Area",
      "PA> Harrisburg Area",
      "PA > Philadelphia Area",
      "PR > Aguadilla Area",
      "PR > San Juan Area",
      "SC > Charleston Area",
      "SC> Greenville Area",
      "SD > Rapid City Area",
      "SD > Sioux Falls Area",
      "TN> Chattanooga Area",
      "TN > Jackson Area",
      "TN > Knoxville Area",
      "TN > Memphis Area",
      "TN > Nashville Area",
      "TN> Tri Cities Area",
      "TX > Abilene Area",
      "TX > Amarillo Area",
      "TX > Austin Area",
      "TX > Beaumont Area",
      "TX > Bryan Area",
      "TX > Corpus Christi Area",
      "TX > Dallas Area",
      "TX > El Paso Area",
      "TX> Harlingen Area",
      "TX > Houston Area",
      "TX > Laredo Area",
      "TX > Lubbock Area",
      "TX > Odessa Area",
      "TX> San Antonio Area",
      "TX > Tyler Area",
      "TX > Wichita Falls Area",
      "TX San Angelo Area",
      "TX > Victoria Area",
      "VA > Hampton Roads Area",
      "VA > Roanoke Area",
      "UT > Salt Lake City Area",
      "VA > Charlottesville Area",
      "VA > Harrisonburg Area",
      "VA > Richmond Area",
      "VT > Burlington Area",
      "WA > Seattle Area",
      "WA > Spokane Area",
      "WA > Tri Cities Area",
      "WI> Green Bay Area",
      "WI > La Crosse Area",
      "WI > Madison Area",
      "WI > Milwaukee Area",
      "WI > Wausau Area",
      "WV > Bluefield Area",
      "WV > Charleston Area",
      "WV > Clarksburg Area",
      "WV > Parkersburg Area",
      "WY > Casper Area",
      "WY > Cheyenne Area",
    ],
  },
  {
    id: 2,
    label: "CAN> Provinces",
    children: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ],
  },
  {
    id: 3,
    label: "CAN > Metro Areas",
    children: [
      "AB > Calgary Area",
      "AB Edmonton Area",
      "BC Abbotsford Area",
      "BC > Kelowna Area",
      "BC > Victoria Area",
      "MB Winnipeg Area",
      "NB> Moncton Area",
      "NB> St. John Area",
      "NL> St. John Area",
      "NS > Halifax Area",
      "ON > Barrie Area",
      "ON > London Area",
      "ON > Ottawa Area",
      "BC > Vancouver Area",
      "NT>Yellowknife Area",
      "ON > Brantford Area",
      "ON > Guelph Area",
      "ON> Hamilton Area",
      "ON > Kingston Area",
      "ON > Kitchener Area",
      "ON > London Area",
      "ON > Ottawa Area",
      "ON> Peterborough Area",
      "ON> St. Catherines Area",
      "ON > Sudbury Area",
      "ON > Thunder Bay Area",
      "ON > Toronto Area",
      "ON > Windsor Area",
      "PEI > Charlottetown Area",
      "QC > Chicoutimi Area",
      "QC > Montreal Area",
      "QC > Quebec Area",
      "QC > Sherbrooke Area",
      "QC > Trois Rivieres Area",
      "SK > Regina Area",
      "SK> Saskatoon Area",
    ],
  },
  {
    id: 4,
    label: "Africa",
    children: [
      "Algeria",
      "Angola",
      "Benin",
      "Botswana",
      "Burkina Faso",
      "Burundi",
      "Cameroon",
      "Cape Verde",
      "Central African Republic",
      "Chad",
      "Comoros",
      "Congo (DRC)",
      "Congo (Republic)",
      "Djibouti",
      "Egypt",
      "Equatorial Guinea",
      "Eritrea",
      "Ethiopia",
      "Gabon",
      "Gambia",
      "Ghana",
      "Guinea",
      "Guinea-Bissau",
      "Kenya",
      "Lesotho",
      "Liberia",
      "Libya",
      "Madagascar",
      "Malawi",
      "Mali",
      "Mauritania",
      "Mauritius",
      "Morocco",
      "Mozambique",
      "Namibia",
      "Niger",
      "Nigeria",
      "Rwanda",
      "Sao Tome and Principe",
      "Senegal",
      "Seychelles",
      "Sierra Leone",
      "Somalia",
      "South Africa",
      "Sudan",
      "Swaziland",
      "Tanzania",
      "Togo",
      "Tunisia",
      "Uganda",
      "Zambia",
      "Zimbabwe",
    ],
  },
  {
    id: 5,
    label: "Asia",
    children: [
      "Afghanistan",
      "Armenia",
      "Azerbaijan",
      "Bahrain",
      "Bangladesh",
      "Bhutan",
      "Brunei Darussalam",
      "Cambodia",
      "China",
      "Georgia (C)",
      "India",
      "Indonesia",
      "Iran",
      "Israel",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Lebanon",
      "Malaysia",
      "Maldives",
      "Mongolia",
      "Myanmar",
      "Nepal",
      "North Korea",
      "Oman",
      "Pakistan",
      "Philippines",
      "Qatar",
      "Saudi Arabia",
      "Singapore",
      "South Korea",
      "Sri Lanka",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Laos",
      "Lebanon",
      "Malaysia",
      "Maldives",
      "Mongolia",
      "Myanmar",
      "Nepal",
      "North Korea",
      "Oman",
      "Pakistan",
      "Philippines",
      "Qatar",
      "Saudi Arabia",
      "Singapore",
      "South Korea",
      "Sri Lanka",
      "Syria",
      "Taiwan",
      "Tajikistan",
    ],
  },
  {
    id: 6,
    label: "Europe",
    children: [
      "Albania",
      "Andorra",
      "Austria",
      "Belarus",
      "Belgium",
      "Bosnia Herzegovina",
      "Bulgaria",
      "Croatia",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Estonia",
      "Finland",
      "France",
      "Germany",
      "Greece",
      "Hungary",
      "Iceland",
      "Ireland",
      "Italy",
      "Latvia",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macedonia (FYROM)",
      "Malta",
      "Moldova",
      "Monaco",
      "Netherlands",
      "Norway",
      "Poland",
      "Portugal",
      "Romania",
      "Russia",
      "San Marino",
      "Slovakia",
      "Slovenia",
      "Spain",
      "Sweden",
      "Switzerland",
      "Ukraine",
      "United Kingdom",
    ],
  },
  {
    id: 7,
    label: "North America",
    children: [
      "Antigua and Barbuda",
      "Bahamas",
      "Barbados",
      "Belize",
      "Canada",
      "Costa Rica",
      "Cuba",
      "Dominica",
      "Dominican Republic",
      "El Salvador",
      "Grenada",
      "Guatemala",
      "Haiti",
      "Honduras",
      "Jamaica",
      "Mexico",
      "Nicaragua",
      "Panama",
      "Saint Kitts and Nevis",
      "Saint Vincent and the Grenadines",
      "Trinidad and Tobago",
      "United States",
    ],
  },
  {
    id: 8,
    label: "Oceania",
    children: [
      "Australia",
      "Cook Islands",
      "Fiji",
      "Kiribati",
      "New Zealand",
      "Papua New Guinea",
      "Samoa",
      "Solomon Islands",
      "Timor-Leste",
      "Tonga",
      "Vanuatu",
    ],
  },
  {
    id: 9,
    label: "South America",
    children: [
      "Argentina",
      "Bolivia",
      "Brazil",
      "Chile",
      "Colombia",
      "Ecuador",
      "Guyana",
      "Paraguay",
      "Peru",
      "Suriname",
      "Uruguay",
      "Venezuela",
    ],
  },
];
export const locations = [
  "US > States",
  "Alabama, US",
  "Alaska, US",
  "Arizona, US",
  "Arkansas, US",
  "California, US",
  "Colorado, US",
  "Connecticut,",
  "Delaware, US",
  "Florida, US",
  "Georgia, US",
  "Hawaii, US",
  "Idaho, US",
  "Illinois, US",
  "Indiana, US",
  "lowa, US",
  "Kansas, US",
  "Kentucky, US",
  "Louisiana, US",
  "Maine, US",
  "Maryland, US",
  "Massachusetts, US",
  "Michigan, US",
  "Minnesota, US",
  "Mississippi, US",
  "Missouri, US",
  "Montana, US",
  "Nebraska, US",
  "Nevada, US",
  "New Hampshire, US",
  "New Jersey, US",
  "New Mexico, US",
  "New York, US",
  "North Carolina, US",
  "North Dakota, US",
  "Ohio, US",
  "Oklahoma, US",
  "Oregon, US",
  "Pennsylvania, US",
  "Rhode Island, US",
  "South Carolina, US",
  "South Dakota, US",
  "Tennessee, US",
  "Texas, US",
  "Utah, US",
  "Vermont, US",
  "Virginia, US",
  "Washington, US",
  "West Virginia, US",
  "Wisconsin, US",
  "Wyoming, US",
  "District of Columbia",
  "American Samoa",
  "Canal Zone",
  "Guam",
  "Puerto Rico",
  "Virgin Islands",
  "US Metro Areas (217)",
  "AK > Anchorage Area",
  "AK > Fairbanks Area",
  "AL > Birmingham Area",
  "AL> Anniston Area",
  "AL > Dothan Area",
  "AL > Huntsville Area",
  "AL > Mobile Area",
  "AL > Montgomery Area",
  "AL > Tuscaloosa Area",
  "AR > Fort Smith Area",
  "AR > Jonesboro Area",
  "AZ > Phoenix Area",
  "CA > Eureka Area",
  "CA > Fresno Area",
  "CA > Irvine Area",
  "AR > Little Rock Area",
  "AZ > Tucson Area",
  "AZ > Yuma Area",
  "CA > Bakersfield Area",
  "CA > Chico Area",
  "CA > Eureka Area",
  "CA > Fresno Area",
  "CA> Los Angeles Area",
  "CA > Monterey Bay Area",
  "CA > Irvine Area",
  "CA> Palm Springs Area",
  "CA > Sacramento Area",
  "CA> San Diego Area",
  "CA > San Francisco Area",
  "CA > San Jose Area",
  "CA > Santa Barbara Area",
  "CO > Denver Area",
  "FL> Fort Myers Area",
  "CO Colorado Springs Area",
  "CO > Grand Junction Area",
  "CT > Hartford Area",
  "DC > Washington Area",
  "FL > Gainesville Area",
  "FL > Jacksonville Area",
  "FL Miami Area",
  "FL> Orlando Area",
  "FL> Panama City Area",
  "FL> Tallahassee Area",
  "FL> Tampa Bay Area",
  "FL> West Palm Beach Area",
  "GA> Albany Area",
  "GA > Atlanta Area",
  "GA> Augusta Area",
  "GA > Columbus Area",
  "GA> Macon Area",
  "GA > Savannah Area",
  "HI > Honolulu Area",
  "IA > Cedar Rapids Area",
  "IA > Davenport Area",
  "IA > Des Moines Area",
  "IA > Ottumwa Area",
  "IA > Sioux City Area",
  "ID > Boise City Area",
  "ID > Idaho Falls Area",
  "ID> Twin Falls Area",
  "IL > Chicago Area",
  "IL > Peoria Area",
  "IL> Quincy Area",
  "IN > Lafayette Area",
  "KS > Topeka Area",
  "IL > Rockford Area",
  "IL > Springfield Area",
  "IN > Evansville Area",
  "IN > Fort Wayne Area",
  "IN > Indianapolis Area",
  "IN > South Bend Area",
  "IN > Terre Haute Area",
  "KS > Wichita Area",
  "KY > Bowling Green Area",
  "KY > Lexington Area",
  "KY > Louisville Area",
  "KY > Paducah Area",
  "LA > Alexandria Area",
  "LA > Baton Rouge Area",
  "LA > Lafayette Area",
  "LA > Lake Charles Area",
  "LA > Monroe Area",
  "LA > New Orleans Area",
  "LA > Shreveport Area",
  "MA > Boston Area",
  "MD > Baltimore Area",
  "ME > Presquelsle Area",
  "MA > Springfield Area",
  "MD > Salisbury Area",
  "ME > Bangor Area",
  "ME > Portland Area",
  "MI > Alpena Area",
  "MI > Detroit Area",
  "MI > Flint Area",
  "MI > Grand Rapids Area",
  "MI > Lansing Area",
  "MI> Marquette Area",
  "MI > Traverse City Area",
  "MN > Duluth Area",
  "MN > Mankato Area",
  "MS > Columbus Area",
  "MN > Minneapolis Area",
  "MN > Rochester Area",
  "MO > Jefferson City Area",
  "MO > Joplin Area",
  "MO> Kansas City Area",
  "MO > Saint Joseph Area",
  "MO > Saint Louis Area",
  "MO > Springfield Area",
  "MS > Biloxi Area",
  "MS > Greenville Area",
  "MS > Hattiesburg Area",
  "MS > Jackson Area",
  "MS > Meridian Area",
  "MT > Billings Area",
  "MT > Butte Area",
  "MT > Glendive Area",
  "MT > Great Falls Area",
  "MT > Helena Area",
  "MT > Missoula Area",
  "NC > Charlotte Area",
  "NC > Greenville Area",
  "NC > Raleigh Area",
  "NC > Wilmington Area",
  "NC > Winston Salem Area",
  "ND > Bismarck Area",
  "ND > Fargo Area",
  "NE > Lincoln Area",
  "NE> North Platte Area",
  "NE > Omaha Area",
  "NM > Albuquerque Area",
  "NV > Las Vegas Area",
  "NV > Reno Area",
  "NY > Albany Area",
  "NY > Binghamton Area",
  "NY > Buffalo Area",
  "NY > Elmira Area",
  "NY > New York Area",
  "NY > Rochester Area",
  "NY > Syracuse Area",
  "NY > Utica Area",
  "NY > Watertown Area",
  "OH > Cincinnati Area",
  "OH > Cleveland Area",
  "OH > Columbus Area",
  "OH > Dayton Area",
  "OH > Lima Area",
  "OH > Toledo Area",
  "OH > Wheeling Area",
  "OH > Youngstown Area",
  "OH > Zanesville Area",
  "OK > Ardmore Area",
  "OK Oklahoma City Area",
  "OK > Tulsa Area",
  "OR > Eugene Area",
  "PA > Erie Area",
  "PA > Johnstown Area",
  "PA > Pittsburgh Area",
  "PA > Wilkes Barre Area",
  "PR Mayaguez Area",
  "PR > Ponce Area",
  "RI > Providence Area",
  "SC > Columbia Area",
  "SC> Florence Area",
  "OR > Bend Area",
  "OR > Medford Area",
  "OR > Portland Area",
  "PA> Harrisburg Area",
  "PA > Philadelphia Area",
  "PR > Aguadilla Area",
  "PR > San Juan Area",
  "SC > Charleston Area",
  "SC> Greenville Area",
  "SD > Rapid City Area",
  "SD > Sioux Falls Area",
  "TN> Chattanooga Area",
  "TN > Jackson Area",
  "TN > Knoxville Area",
  "TN > Memphis Area",
  "TN > Nashville Area",
  "TN> Tri Cities Area",
  "TX > Abilene Area",
  "TX > Amarillo Area",
  "TX > Austin Area",
  "TX > Beaumont Area",
  "TX > Bryan Area",
  "TX > Corpus Christi Area",
  "TX > Dallas Area",
  "TX > El Paso Area",
  "TX> Harlingen Area",
  "TX > Houston Area",
  "TX > Laredo Area",
  "TX > Lubbock Area",
  "TX > Odessa Area",
  "TX> San Antonio Area",
  "TX > Tyler Area",
  "TX > Wichita Falls Area",
  "TX San Angelo Area",
  "TX > Victoria Area",
  "VA > Hampton Roads Area",
  "VA > Roanoke Area",
  "UT > Salt Lake City Area",
  "VA > Charlottesville Area",
  "VA > Harrisonburg Area",
  "VA > Richmond Area",
  "VT > Burlington Area",
  "WA > Seattle Area",
  "WA > Spokane Area",
  "WA > Tri Cities Area",
  "WI> Green Bay Area",
  "WI > La Crosse Area",
  "WI > Madison Area",
  "WI > Milwaukee Area",
  "WI > Wausau Area",
  "WV > Bluefield Area",
  "WV > Charleston Area",
  "WV > Clarksburg Area",
  "WV > Parkersburg Area",
  "WY > Casper Area",
  "WY > Cheyenne Area",
  "CAN> Provinces (13)",
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
  "CAN > Metro Areas (34)",
  "AB > Calgary Area",
  "AB Edmonton Area",
  "BC Abbotsford Area",
  "BC > Kelowna Area",
  "BC > Victoria Area",
  "MB Winnipeg Area",
  "NB> Moncton Area",
  "NB> St. John Area",
  "NL> St. John Area",
  "NS > Halifax Area",
  "ON > Barrie Area",
  "ON > London Area",
  "ON > Ottawa Area",
  "BC > Vancouver Area",
  "NT>Yellowknife Area",
  "ON > Brantford Area",
  "ON > Guelph Area",
  "ON> Hamilton Area",
  "ON > Kingston Area",
  "ON > Kitchener Area",
  "ON > London Area",
  "ON > Ottawa Area",
  "ON> Peterborough Area",
  "ON> St. Catherines Area",
  "ON > Sudbury Area",
  "ON > Thunder Bay Area",
  "ON > Toronto Area",
  "ON > Windsor Area",
  "PEI > Charlottetown Area",
  "QC > Chicoutimi Area",
  "QC > Montreal Area",
  "QC > Quebec Area",
  "QC > Sherbrooke Area",
  "QC > Trois Rivieres Area",
  "SK > Regina Area",
  "SK> Saskatoon Area",
  "Africa (52)",
  "Algeria",
  "Angola",
  "Benin",
  "Botswana",
  "Burkina Faso",
  "Burundi",
  "Cameroon",
  "Cape Verde",
  "Central African Republic",
  "Chad",
  "Comoros",
  "Congo (DRC)",
  "Congo (Republic)",
  "Djibouti",
  "Egypt",
  "Equatorial Guinea",
  "Eritrea",
  "Ethiopia",
  "Gabon",
  "Gambia",
  "Ghana",
  "Guinea",
  "Guinea-Bissau",
  "Kenya",
  "Lesotho",
  "Liberia",
  "Libya",
  "Madagascar",
  "Malawi",
  "Mali",
  "Mauritania",
  "Mauritius",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Niger",
  "Nigeria",
  "Rwanda",
  "Sao Tome and Principe",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Somalia",
  "South Africa",
  "Sudan",
  "Swaziland",
  "Tanzania",
  "Togo",
  "Tunisia",
  "Uganda",
  "Zambia",
  "Zimbabwe",
  "Asia (45)",
  "Afghanistan",
  "Armenia",
  "Azerbaijan",
  "Bahrain",
  "Bangladesh",
  "Bhutan",
  "Brunei Darussalam",
  "Cambodia",
  "China",
  "Georgia (C)",
  "India",
  "Indonesia",
  "Iran",
  "Israel",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Lebanon",
  "Malaysia",
  "Maldives",
  "Mongolia",
  "Myanmar",
  "Nepal",
  "North Korea",
  "Oman",
  "Pakistan",
  "Philippines",
  "Qatar",
  "Saudi Arabia",
  "Singapore",
  "South Korea",
  "Sri Lanka",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Laos",
  "Lebanon",
  "Malaysia",
  "Maldives",
  "Mongolia",
  "Myanmar",
  "Nepal",
  "North Korea",
  "Oman",
  "Pakistan",
  "Philippines",
  "Qatar",
  "Saudi Arabia",
  "Singapore",
  "South Korea",
  "Sri Lanka",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Europe (42)",
  "Albania",
  "Andorra",
  "Austria",
  "Belarus",
  "Belgium",
  "Bosnia Herzegovina",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia (FYROM)",
  "Malta",
  "Moldova",
  "Monaco",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "San Marino",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Ukraine",
  "United Kingdom",
  "North America (22)",
  "Antigua and Barbuda",
  "Bahamas",
  "Barbados",
  "Belize",
  "Canada",
  "Costa Rica",
  "Cuba",
  "Dominica",
  "Dominican Republic",
  "El Salvador",
  "Grenada",
  "Guatemala",
  "Haiti",
  "Honduras",
  "Jamaica",
  "Mexico",
  "Nicaragua",
  "Panama",
  "Saint Kitts and Nevis",
  "Saint Vincent and the Grenadines",
  "Trinidad and Tobago",
  "United States",
  "Oceania (11)",
  "Australia",
  "Cook Islands",
  "Fiji",
  "Kiribati",
  "New Zealand",
  "Papua New Guinea",
  "Samoa",
  "Solomon Islands",
  "Timor-Leste",
  "Tonga",
  "Vanuatu",
  "South America (12)",
  "Australia",
  "Cook Islands",
  "Fiji",
  "Kiribati",
  "New Zealand",
  "Papua New Guinea",
  "Samoa",
  "Solomon Islands",
  "Timor-Leste",
  "Tonga",
  "Vanuatu",
];

export const industryData = [
  "AR/VR",
  "Accounting & Accounting Services",
  "Adult",
  "Aerospace & Defense",
  "Agriculture",
  "Airlines & Aviation",
  "Amusement Parks Arcades & Attractions",
  "Animation",
  "Apparel & Accessories Retail",
  "Apparel & Fashion",
  "Appliances",
  "Architecture Engineering & Design",
  "Artificial Intelligence & Machine Learning",
  "Auctions",
  "Automotive",
  "Banking",
  "Biotechnology",
  "Boats & Submarines",
  "Broadcasting & Media",
  "Building Materials",
  "Business Intelligence Software",
  "Business Services - General",
  "Business Supplies & Equipment",
  "Cable & Satellite",
  "Call Centers & Business Centers",
  "Car & Truck Rental",
  "Career",
  "Career Planning",
  "Chemicals",
  "Child Care",
  "Cities Towns & Municipalities - General",
  "Classifieds",
  "Cleaning Products",
  "Clinical Trials",
  "Cloud Software",
  "Colleges & Universities",
  "Commercial Printing",
  "Commercial Real Estate",
  "Communities",
  "Computer Games",
  "Computer Hardware",
  "Construction - General",
  "Consumer Electronics",
  "Consumer Electronics & Computers Retail",
  "Consumer Goods",
  "Consumer Services - General",
  "Content & Collaboration Software",
  "Corrections Facilities",
  "Cosmetics Beauty Supply & Personal Care Products",
  "Credit Cards & Transaction Processing",
  "Crops",
  "Cruise Lines",
  "Cryptocurrency",
  "Cultural - General",
  "Customer Relationship Management Software(CRM)",
  "Dairy",
  "Data & Big Data",
  "Database & File Management Software",
  "Debt Collection",
  "Delivery",
  "Department Stores Shopping Centers & Superstores",
  "Design",
  "Drones",
  "Drug Manufacturing & Research",
  "Drug Stores & Pharmacies",
  "E-learning",
  "Ebook & Audiobooks",
  "Ecommerce & Internet Retail",
  "Education - General",
  "Elder & Disabled Care",
  "Electricity & Energy",
  "Electronics & Electronics Manufacturing",
  "Emergency Medical Transportation & Services",
  "Energy, Utilities & Waste Treatment - General",
  "Enterprise Resource Planning Software(ERP)",
  "Entertainment",
  "Environmental Services",
  "Event Services",
  "Facilities Management & Services",
  "Farming Animals & Livestock",
  "Film Video & Media Production & Services",
  "Finance - General",
  "Financial Services",
  "Fine Arts",
  "Fishery & Aquaculture",
  "Fitness & Dance Facilities",
  "Flash Storage",
  "Flowers Gifts & Specialty",
  "Food & Beverages",
  "Food Service",
  "Footwear",
  "Forestry",
  "Fraud Detection",
  "Freight & Logistics Services",
  "Funeral Homes & Funeral Related Services",
  "Furniture",
  "Gambling & Casinos",
  "Gaming",
  "Gas Stations Convenience & Liquor Stores",
  "Genetics",
  "Geography & Positioning",
  "Gift & Gift Products",
  "Glass & Concrete",
  "Government - General",
  "Grocery & Supermarkets",
  "Hair Salons",
  "Hand Power and Lawn-care Tools",
  "Health & Nutrition Products",
  "Health Wellness & Fitness",
  "Healthcare - General",
  "Higher Education",
  "Home Improvement & Hardware",
  "Hospitality - General",
  "Hospitals & Healthcare",
  "Hotels and Resorts",
  "Household Goods",
  "Human Resources & Staffing",
  "Import & Export & Trade",
  "Industrial Machinery Equipment & Automation",
  "Information Services",
  "International Affairs",
  "Internet & Digital Media",
  "Internet-related Services",
  "Investment Banking",
  "Jewelry & Watch Retail",
  "K-12 Schools",
  "Laundry & Dry Cleaning Services",
  "Law Enforcement",
  "Libraries",
  "Local",
  "Lodging & Resorts",
  "Logistics & Supply Chain",
  "Lumber & Wood Production",
  "Luxury Goods & Jewelry",
  "Management Consulting",
  "Manufacturing - General",
  "Maritime",
  "Marketing & Advertising",
  "Mechanical Engineering",
  "Mechanical or Industrial Engineering",
  "Media & Internet - General",
  "Medical Devices & Equipment",
  "Medical Practice",
  "Medicine",
  "Mental Health Care",
  "Metals & Mining - General",
  "Military",
  "Mining",
  "Miscellaneous Building Materials",
  "Mobile",
  "Movie Theaters",
  "Multimedia & Graphic Design",
  "Museums & Art Galleries",
  "Music & Music Related Services",
  "Nanotechnology",
  "Natural Resources",
  "Network Security Hardware & Software",
  "Neuroscience",
  "Newspapers & News Services",
  "Non-Profit & Charitable Organizations & Foundations",
  "Nuclear",
  "Office Products",
  "Oil & Gas Exploration & Services",
  "Optometry & Eyewear",
  "Organizations - General",
  "Outsourcing",
  "Paper & Forest Products",
  "Parenting & Youth",
  "Parking",
  "Performing Arts",
  "Personal Computers & Peripherals",
  "Pet Products",
  "Petrochemicals",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics & Packaging & Containers",
  "Plumbing & HVAC Equipment",
  "Podcast",
  "Power Conversion & Protection Equipment",
  "Privacy",
  "Professional Training & Coaching",
  "Psychology",
  "Public Policy",
  "Public Relations & Communication",
  "Public Safety",
  "Publishing",
  "Quantum Computing",
  "Radio Stations",
  "Rail Bus & Taxi",
  "Ranching",
  "Real Estate - General",
  "Record, Video & Book Stores",
  "Recreation",
  "Religious Organizations",
  "Renewables & Environment",
  "Rental - Other - Furniture A/V Construction & Industrial Equipment",
  "Rental - Video & DVD",
  "Research - General",
  "Restaurants",
  "Retail - General",
  "Robotics",
  "Sales",
  "Search Engines & Internet Portals",
  "Security & Investigations Products & Services",
  "Semiconductor & Semiconductor Equipment",
  "Sharing Economy",
  "Shipbuilding",
  "Shipping",
  "Social Media",
  "Software & Technical Consulting",
  "Software - General",
  "Software Engineering",
  "Sporting & Recreational Equipment",
  "Sports",
  "Staffing & Recruiting",
  "Storage & System Management Software",
  "Streaming",
  "Supply Chain Management Software",
  "Technology",
  "Telecommunication Equipment",
  "Telecommunications - General",
  "Television Stations",
  "Test & Measurement Equipment",
  "Textiles & Apparel",
  "Think Tanks",
  "Tires & Rubber",
  "Tobacco",
  "Toys & Games",
  "Training",
  "Translation & Localization",
  "Transportation - General",
  "Travel Agencies & Services",
  "Trucking Moving & Storage",
  "Utilities",
  "Venture Capital & Private Equity",
  "Veterinary Care",
  "Video Chat & Online Messaging",
  "Vitamins Supplements & Health Stores",
  "Warehousing",
  "Water Energy & Waste Treatment",
  "Wedding",
  "Wholesale",
  "Wine & Spirits",
  "Wire & Cable",
  "Wireless",
  "Writing & Editing",
  "Zoos & National Parks",
];
export const name = [];
export const jobTitles = [
  "Academic Librarian",
  "Accountant",
  "Accounting Technician",
  "Actuary",
  "Adult Nurse",
  "Advertising Account executive",
  "Advertising Account planner",
  "Advertising Copywriter",
  "Advice Worker",
  "Aeronautical Engineer",
  "Agricultural Consultant",
  "Agricultural Manager",
  "Aid Worker/Humanitarian Worker",
  "Air Traffic Controller",
  "Airline Cabin Crew",
  "Amenity Horticulturist",
  "Analytical Chemist",
  "Animal Nutritionist",
  "Animator",
  "Archaeologist",
  "Architect",
  "Architectural Technologist",
  "Archivist",
  "Armed Forces Officer",
  "AromaTherapist",
  "Art Therapist",
  "Arts Administrator",
  "Auditor",
  "Automotive Engineer",
  "Barrister",
  "Barrister's Clerk",
  "Bid Manager",
  "Bilingual Secretary",
  "Biomedical Engineer",
  "Biomedical Scientist",
  "Biotechnologist",
  "Border Force Officer",
  "Brand Manager",
  "Broadcasting Presenter",
  "Building Control Officer/Surveyor",
  "Building Services Engineer",
  "Building Surveyor",
  "Business Analyst",
  "Camera Operator",
  "Careers Adviser (Higher Education)",
  "Careers Adviser",
  "Careers Consultant",
  "Cartographer",
  "Catering Manager",
  "Charities Administrator",
  "Charities Fundraiser",
  "Chemical (Process) Engineer",
  "Child PsychoTherapist",
  "Children's Nurse",
  "Chiropractor",
  "Civil Engineer",
  "Civil Service Administrator",
  "Clinical Biochemist",
  "Clinical Cytogeneticist",
  "Clinical Microbiologist",
  "Clinical Molecular Geneticist",
  "Clinical Research Associate",
  "Clinical Scientist",
  "Clothing Technologist",
  "Colour Technologist",
  "Commercial Airline Pilot",
  "Commercial Horticulturist",
  "Commercial/Residential Surveyor",
  "Commissioning Editor",
  "Commissioning Engineer",
  "Commodity Broker",
  "Communications Engineer",
  "Community Arts Worker",
  "Community Education Officer",
  "Community Worker",
  "Company Secretary",
  "Computer Sales Support",
  "Computer Scientist",
  "Conference Organiser",
  "Consultant",
  "Consumer Rights Adviser",
  "Control and Instrumentation Engineer",
  "Corporate Banker",
  "Corporate Treasurer",
  "Counsellor",
  "Court Reporter/Verbatim Reporter",
  "Credit Analyst",
  "Crown Prosecution Service lawyer",
  "Crystallographer",
  "Curator",
  "Customs Officer",
  "Cyber Security Specialist",
  "Dance Movement PsychoTherapist",
  "Data Analyst",
  "Data Scientist",
  "Data Visualisation Analyst",
  "Database Administrator",
  "Debt/Dinance Adviser",
  "Dental Hygienist",
  "Dentist",
  "Design Engineer",
  "Design Manager (Construction)",
  "DevOps Engineer",
  "Dietitian",
  "Diplomatic Service",
  "Doctor (GP)",
  "Doctor (Hospital)",
  "DramaTherapist",
  "Economist",
  "Editorial Assistant",
  "Education Administrator",
  "Electrical Engineer",
  "Electronics Engineer",
  "Employment Advice Worker",
  "Energy Conservation Officer",
  "Energy Consultant",
  "Engineering Geologist",
  "Environmental Education Officer",
  "Environmental Health Officer",
  "Environmental Manager",
  "Environmental Scientist",
  "Equal Opportunities Officer",
  "Equality and Diversity Officer",
  "Ergonomist",
  "Estate Agent",
  "Estimator",
  "European Commission Administrators",
  "Exhibition Display Designer",
  "Exhibition Organiser",
  "Exploration Geologist",
  "Facilities Manager",
  "Field Trials Officer",
  "Financial Manager",
  "Fire Engineer",
  "Firefighter",
  "Fisheries Enforcement Officer",
  "Fitness Centre Manager",
  "Food Scientist",
  "Food Technologist",
  "Forensic Scientist",
  "Freight Forwarder",
  "Geneticist",
  "Geographical Information Systems Manager",
  "Geomatics/Land Surveyor",
  "Government Lawyer",
  "Government Research Officer",
  "Graphic Designer",
  "Health and Safety Adviser",
  "Health and Safety Inspector",
  "Health Promotion Specialist",
  "Health Service Manager",
  "Health Visitor",
  "Herbalist",
  "Heritage Manager",
  "Higher Education Administrator",
  "Higher Education Advice Worker",
  "Homeless Support Worker",
  "Horticultural Consultant",
  "Hotel Manager",
  "Housing Adviser",
  "Human Resources Officer",
  "Hydrologist",
  "Illustrator",
  "Immigration Officer",
  "Immunologist",
  "Industrial/Product Designer",
  "Information Scientist",
  "Information Systems Manager",
  "Information Technology/Software Trainers",
  "Insurance Broker",
  "Insurance Claims Inspector",
  "Insurance Risk Surveyor",
  "Insurance Underwriter",
  "Interpreter",
  "Investment Analyst",
  "Investment Banker - Corporate Finance",
  "Investment Banker - Operations",
  "Investment FUnd Manager",
  "IT Consultant",
  "IT Support Analyst",
  "Journalist",
  "Laboratory Technician",
  "Land-based Engineer",
  "Landscape Architect",
  "Learning Disability Nurse",
  "Learning Mentor",
  "Lecturer (Adult Education)",
  "Lecturer (Further Education)",
  "Lecturer (Higher Education)",
  "Legal Executive",
  "Leisure Centre Manager",
  "Licensed Conveyancer",
  "Local Government administrator",
  "Local Government lawyer",
  "Logistics/Distribution Manager",
  "Magazine Features Editor",
  "Magazine Journalist",
  "Maintenance Engineer",
  "Management accountant",
  "Manufacturing Engineer",
  "Manufacturing Machine Operator",
  "Manufacturing Toolmaker",
  "Marine Scientist",
  "Market Research Analyst",
  "Market Research Executive",
  "Marketing Assistant",
  "Marketing Executive",
  "Marketing Manager (Direct)",
  "Marketing Manager (Social Media)",
  "Materials Engineer",
  "Materials Specialist",
  "Mechanical Engineer",
  "Media Analyst",
  "Media Buyer",
  "Media Planner",
  "Medical Physicist",
  "Medical Representative",
  "Mental Health Nurse",
  "Metallurgist",
  "Meteorologist",
  "Microbiologist",
  "Midwife",
  "Mining Engineer",
  "Mobile Developer",
  "Multimedia Programmer",
  "Multimedia Specialists",
  "Museum Education Officer",
  "Museum/Gallery Exhibition Officer",
  "Music Therapist",
  "Nanoscientist",
  "Nature Conservation Officer",
  "Naval Architect",
  "Network Administrator",
  "Nurse",
  "Nutritional Therapist",
  "Nutritionist",
  "Occupational Therapist",
  "Oceanographer",
  "Office Manager",
  "Operational Researcher",
  "Orthoptist",
  "Outdoor Pursuits Manager",
  "Packaging Technologist",
  "Paramedic",
  "Patent Attorney",
  "Patent Examiner",
  "Pension Scheme Manager",
  "Personal Assistant",
  "Petroleum Engineer",
  "Pharmacist",
  "Pharmacologist",
  "Pharmacovigilance Officer",
  "Photographer",
  "PhysioTherapist",
  "Picture Researcher",
  "Planning and Development Surveyor",
  "Planning Technician",
  "Plant Breeder",
  "Police Officer",
  "Political Party Agent",
  "Political Researcher",
  "Practice nurse",
  "Press Photographer",
  "Press Sub-editor",
  "Prison Officer",
  "Private Music Teacher",
  "Probation Officer",
  "Product Development Scientist",
  "Production Manager",
  "Programme Researcher",
  "Project Manager",
  "Psychologist (Clinical)",
  "Psychologist (Educational)",
  "PsychoTherapist",
  "Public Affairs Consultant (Lobbyist)",
  "Public Affairs Consultant (Research)",
  "Public House Manager",
  "Public Librarian",
  "Public Relations (PR) Officer",
  "QA Analyst",
  "Quality Assurance Manager",
  "Quantity Surveyor",
  "Records Manager",
  "Recruitment Consultant",
  "Recycling Officer",
  "Regulatory Affairs Officer",
  "Research Chemist",
  "Research Scientist",
  "Restaurant Manager",
  "Retail Banker",
  "Retail Buyer",
  "Retail Manager",
  "Retail Merchandiser",
  "Retail Pharmacist",
  "Sales Executive",
  "Scene of Crime Officer",
  "Secretary",
  "Seismic Interpreter",
  "Site Engineer",
  "Site Manager",
  "Social Researcher",
  "Social Worker",
  "Software Developer",
  "Software Engineer",
  "Soil Scientist",
  "Solicitor",
  "Speech and Language Therapist",
  "Sports Coach",
  "Sports Development Officer",
  "Sports Therapist",
  "Statistician",
  "Stockbroker",
  "Structural Engineer",
  "Systems Analyst",
  "Systems Developer",
  "Tax Inspector",
  "Teacher (Nursery Years)",
  "Teacher (Primary)",
  "Teacher (Secondary)",
  "Teacher (Special Educational Needs)",
  "Teaching/Classroom Assistant",
  "Technical Author",
  "Technical Sales Engineer",
  "TEFL/TESL Teacher",
  "Television Production Assistant",
  "Test Automation Developer",
  "Tour Guide",
  "Tour Operator",
  "Tour/Holiday Representative",
  "Tourism Officer",
  "Tourist Information Manager",
  "Town and Country Planner",
  "Toxicologist",
  "Trade Union Official",
  "Trade Union Research Officer",
  "Trader",
  "Trading Standards Officer",
  "Training and Development Officer",
  "Translator",
  "Transportation Planner",
  "Travel Agent",
  "TV/Film/Theatre Set Designer",
  "UX Designer",
  "Validation Engineer",
  "Veterinary Nurse",
  "Veterinary Surgeon",
  "Video Game Designer",
  "Video Game Developer",
  "Volunteer Work Organiser",
  "Waste Management Officer",
  "Water Conservation Officer",
  "Water Engineer",
  "Web Designer",
  "Web Developer",
  "Welfare Rights Adviser",
  "Writer",
  "Youth Worker",
];
export const skills = [];
export const yearsOfExperience = ["0 - 3", "3 - 10", "10 +"];
export const companyName = [];
export const employeeCount = [
  "1 - 10",
  "11 - 50",
  "51 - 100",
  "101 - 500",
  "501 - 1000",
  "1001 - 5000",
  "5001 - 10000",
  "10001 - 25000",
  "25000 +",
];
export const revenue = [
  {
    label: "< $10M",
    value: "<10000000",
  },
  {
    label: "$10M - $50M",
    value: "10000000-50000000",
  },
  {
    label: "$50M - $100M",
    value: "50000000-100000000",
  },
  {
    label: "$100M - $500M",
    value: "100000000-500000000",
  },
  {
    label: "$500M - $1B",
    value: "500000000-1000000000",
  },
  {
    label: "$1B - $5B",
    value: "1000000000-5000000000",
  },
  {
    label: "$5B +",
    value: "5000000000+",
  },
];
export const emailTypes = ["Professional Email", "Personal Email"];
export const companyLists = [];
export const education = [];
export const contactInfo = [];
export const socialLink = [];
export const keywords = [];
export const exclude = [];
export const filterList = [
  {
    name: "name",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFName sx={{ color: "#28287B" }} />
      </Box>
    ),
    label: "Name",
  },
  {
    name: "location",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFLocation sx={{}} />
      </Box>
    ),
    label: "Location",
    menuItems: locationData,
    select: true,
  },
  {
    name: "current_title",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFJobTitles sx={{}} />
      </Box>
    ),
    label: "Job Titles",
    menuItems: jobTitles,
    select: true,
  },
  /* {
    name: "email_type",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFJobTitles sx={{}} />
      </Box>
    ),
    label: "Email Type",
    menuItems: emailTypes,
    select: true,
  },*/
  {
    name: "skills",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFKeywords sx={{}} />
      </Box>
    ),
    label: "Skills",
  },
  {
    name: "years_experience",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFLookalikeDomain sx={{}} />
      </Box>
    ),
    label: "Years of Experience",
    select: true,
    menuItems: yearsOfExperience,
  },
  {
    name: "employer",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFCompanyName sx={{}} />
      </Box>
    ),
    label: "Company Name or Domain",
  },
  {
    name: "company_size",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFEmployees sx={{}} />
      </Box>
    ),
    label: "Employee Count",
    select: true,
    menuItems: employeeCount,
  },
  {
    name: "revenue",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFRevenue sx={{}} />
      </Box>
    ),
    label: "Revenue",
    select: true,
    menuItems: revenue,
  },
  {
    name: "industry",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFIndustry sx={{}} />
      </Box>
    ),
    label: "Industry",
    select: true,
    menuItems: industryData,
  },
  {
    name: "degree",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFDepartment sx={{}} />
      </Box>
    ),
    label: "Education",
  },
  {
    name: "email",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <BadgeOutlined sx={{ color: "#28287B" }} />
      </Box>
    ),
    label: "Contact Info",
  },
  {
    name: "handle",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <LFTechnologies sx={{}} />
      </Box>
    ),
    label: "Social Link",
  },
  {
    name: "keyword",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <Abc sx={{ color: "#28287B" }} />
      </Box>
    ),
    label: "Keywords",
  },
  {
    name: "exclude_keyword",
    icon: (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
        <BackspaceOutlined sx={{ color: "#28287B" }} />
      </Box>
    ),
    label: "Exclude",
  },
];

export const timingIntervals = [
  "0:00 AM",
  "0:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

const timezones = [
  {
    offset: "GMT-12:00",
    name: "Etc/GMT-12",
  },
  {
    offset: "GMT-11:00",
    name: "Etc/GMT-11",
  },
  {
    offset: "GMT-11:00",
    name: "Pacific/Midway",
  },
  {
    offset: "GMT-10:00",
    name: "America/Adak",
  },
  {
    offset: "GMT-09:00",
    name: "America/Anchorage",
  },
  {
    offset: "GMT-09:00",
    name: "Pacific/Gambier",
  },
  {
    offset: "GMT-08:00",
    name: "America/Dawson_Creek",
  },
  {
    offset: "GMT-08:00",
    name: "America/Ensenada",
  },
  {
    offset: "GMT-08:00",
    name: "America/Los_Angeles",
  },
  {
    offset: "GMT-07:00",
    name: "America/Chihuahua",
  },
  {
    offset: "GMT-07:00",
    name: "America/Denver",
  },
  {
    offset: "GMT-06:00",
    name: "America/Belize",
  },
  {
    offset: "GMT-06:00",
    name: "America/Cancun",
  },
  {
    offset: "GMT-06:00",
    name: "America/Chicago",
  },
  {
    offset: "GMT-06:00",
    name: "Chile/EasterIsland",
  },
  {
    offset: "GMT-05:00",
    name: "America/Bogota",
  },
  {
    offset: "GMT-05:00",
    name: "America/Havana",
  },
  {
    offset: "GMT-05:00",
    name: "America/New_York",
  },
  {
    offset: "GMT-04:30",
    name: "America/Caracas",
  },
  {
    offset: "GMT-04:00",
    name: "America/Campo_Grande",
  },
  {
    offset: "GMT-04:00",
    name: "America/Glace_Bay",
  },
  {
    offset: "GMT-04:00",
    name: "America/Goose_Bay",
  },
  {
    offset: "GMT-04:00",
    name: "America/Santiago",
  },
  {
    offset: "GMT-04:00",
    name: "America/La_Paz",
  },
  {
    offset: "GMT-03:00",
    name: "America/Argentina/Buenos_Aires",
  },
  {
    offset: "GMT-03:00",
    name: "America/Montevideo",
  },
  {
    offset: "GMT-03:00",
    name: "America/Araguaina",
  },
  {
    offset: "GMT-03:00",
    name: "America/Godthab",
  },
  {
    offset: "GMT-03:00",
    name: "America/Miquelon",
  },
  {
    offset: "GMT-03:00",
    name: "America/Sao_Paulo",
  },
  {
    offset: "GMT-03:30",
    name: "America/St_Johns",
  },
  {
    offset: "GMT-02:00",
    name: "America/Noronha",
  },
  {
    offset: "GMT-01:00",
    name: "Atlantic/Cape_Verde",
  },
  {
    offset: "GMT",
    name: "Europe/Belfast",
  },
  {
    offset: "GMT",
    name: "Africa/Abidjan",
  },
  {
    offset: "GMT",
    name: "Europe/Dublin",
  },
  {
    offset: "GMT",
    name: "Europe/Lisbon",
  },
  {
    offset: "GMT",
    name: "Europe/London",
  },
  {
    offset: "UTC",
    name: "UTC",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Algiers",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Windhoek",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Azores",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Stanley",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Amsterdam",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Belgrade",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Brussels",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Cairo",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Blantyre",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Beirut",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Damascus",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Gaza",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Jerusalem",
  },
  {
    offset: "GMT+03:00",
    name: "Africa/Addis_Ababa",
  },
  {
    offset: "GMT+03:00",
    name: "Asia/Riyadh89",
  },
  {
    offset: "GMT+03:00",
    name: "Europe/Minsk",
  },
  {
    offset: "GMT+03:30",
    name: "Asia/Tehran",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Dubai",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Yerevan",
  },
  {
    offset: "GMT+04:00",
    name: "Europe/Moscow",
  },
  {
    offset: "GMT+04:30",
    name: "Asia/Kabul",
  },
  {
    offset: "GMT+05:00",
    name: "Asia/Tashkent",
  },
  {
    offset: "GMT+05:30",
    name: "Asia/Kolkata",
  },
  {
    offset: "GMT+05:45",
    name: "Asia/Katmandu",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Dhaka",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Yekaterinburg",
  },
  {
    offset: "GMT+06:30",
    name: "Asia/Rangoon",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Bangkok",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Novosibirsk",
  },
  {
    offset: "GMT+08:00",
    name: "Etc/GMT+8",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Hong_Kong",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Krasnoyarsk",
  },
  {
    offset: "GMT+08:00",
    name: "Australia/Perth",
  },
  {
    offset: "GMT+08:45",
    name: "Australia/Eucla",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Irkutsk",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Seoul",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Tokyo",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Adelaide",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Darwin",
  },
  {
    offset: "GMT+09:30",
    name: "Pacific/Marquesas",
  },
  {
    offset: "GMT+10:00",
    name: "Etc/GMT+10",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Brisbane",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Hobart",
  },
  {
    offset: "GMT+10:00",
    name: "Asia/Yakutsk",
  },
  {
    offset: "GMT+10:30",
    name: "Australia/Lord_Howe",
  },
  {
    offset: "GMT+11:00",
    name: "Asia/Vladivostok",
  },
  {
    offset: "GMT+11:30",
    name: "Pacific/Norfolk",
  },
  {
    offset: "GMT+12:00",
    name: "Etc/GMT+12",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Anadyr",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Magadan",
  },
  {
    offset: "GMT+12:00",
    name: "Pacific/Auckland",
  },
  {
    offset: "GMT+12:45",
    name: "Pacific/Chatham",
  },
  {
    offset: "GMT+13:00",
    name: "Pacific/Tongatapu",
  },
  {
    offset: "GMT+14:00",
    name: "Pacific/Kiritimati",
  },
];

export const timezonesConcatenated = timezones.map((item) => `${item.name} (${item.offset})`);

const timezoneObj = {
  "Pacific/Niue": "(GMT-11:00) Niue",
  "Pacific/Pago_Pago": "(GMT-11:00) Pago Pago",
  "Pacific/Honolulu": "(GMT-10:00) Hawaii Time",
  "Pacific/Rarotonga": "(GMT-10:00) Rarotonga",
  "Pacific/Tahiti": "(GMT-10:00) Tahiti",
  "Pacific/Marquesas": "(GMT-09:30) Marquesas",
  "America/Anchorage": "(GMT-09:00) Alaska Time",
  "Pacific/Gambier": "(GMT-09:00) Gambier",
  "America/Los_Angeles": "(GMT-08:00) Pacific Time",
  "America/Tijuana": "(GMT-08:00) Pacific Time - Tijuana",
  "America/Vancouver": "(GMT-08:00) Pacific Time - Vancouver",
  "America/Whitehorse": "(GMT-08:00) Pacific Time - Whitehorse",
  "Pacific/Pitcairn": "(GMT-08:00) Pitcairn",
  "America/Dawson_Creek": "(GMT-07:00) Mountain Time - Dawson Creek",
  "America/Denver": "(GMT-07:00) Mountain Time",
  "America/Edmonton": "(GMT-07:00) Mountain Time - Edmonton",
  "America/Hermosillo": "(GMT-07:00) Mountain Time - Hermosillo",
  "America/Mazatlan": "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan",
  "America/Phoenix": "(GMT-07:00) Mountain Time - Arizona",
  "America/Yellowknife": "(GMT-07:00) Mountain Time - Yellowknife",
  "America/Belize": "(GMT-06:00) Belize",
  "America/Chicago": "(GMT-06:00) Central Time",
  "America/Costa_Rica": "(GMT-06:00) Costa Rica",
  "America/El_Salvador": "(GMT-06:00) El Salvador",
  "America/Guatemala": "(GMT-06:00) Guatemala",
  "America/Managua": "(GMT-06:00) Managua",
  "America/Mexico_City": "(GMT-06:00) Central Time - Mexico City",
  "America/Regina": "(GMT-06:00) Central Time - Regina",
  "America/Tegucigalpa": "(GMT-06:00) Central Time - Tegucigalpa",
  "America/Winnipeg": "(GMT-06:00) Central Time - Winnipeg",
  "Pacific/Galapagos": "(GMT-06:00) Galapagos",
  "America/Bogota": "(GMT-05:00) Bogota",
  "America/Cancun": "(GMT-05:00) America Cancun",
  "America/Cayman": "(GMT-05:00) Cayman",
  "America/Guayaquil": "(GMT-05:00) Guayaquil",
  "America/Havana": "(GMT-05:00) Havana",
  "America/Iqaluit": "(GMT-05:00) Eastern Time - Iqaluit",
  "America/Jamaica": "(GMT-05:00) Jamaica",
  "America/Lima": "(GMT-05:00) Lima",
  "America/Nassau": "(GMT-05:00) Nassau",
  "America/New_York": "(GMT-05:00) Eastern Time",
  "America/Panama": "(GMT-05:00) Panama",
  "America/Port-au-Prince": "(GMT-05:00) Port-au-Prince",
  "America/Rio_Branco": "(GMT-05:00) Rio Branco",
  "America/Toronto": "(GMT-05:00) Eastern Time - Toronto",
  "Pacific/Easter": "(GMT-05:00) Easter Island",
  "America/Caracas": "(GMT-04:00) Caracas",
  "America/Asuncion": "(GMT-03:00) Asuncion",
  "America/Barbados": "(GMT-04:00) Barbados",
  "America/Boa_Vista": "(GMT-04:00) Boa Vista",
  "America/Campo_Grande": "(GMT-03:00) Campo Grande",
  "America/Cuiaba": "(GMT-03:00) Cuiaba",
  "America/Curacao": "(GMT-04:00) Curacao",
  "America/Grand_Turk": "(GMT-04:00) Grand Turk",
  "America/Guyana": "(GMT-04:00) Guyana",
  "America/Halifax": "(GMT-04:00) Atlantic Time - Halifax",
  "America/La_Paz": "(GMT-04:00) La Paz",
  "America/Manaus": "(GMT-04:00) Manaus",
  "America/Martinique": "(GMT-04:00) Martinique",
  "America/Port_of_Spain": "(GMT-04:00) Port of Spain",
  "America/Porto_Velho": "(GMT-04:00) Porto Velho",
  "America/Puerto_Rico": "(GMT-04:00) Puerto Rico",
  "America/Santo_Domingo": "(GMT-04:00) Santo Domingo",
  "America/Thule": "(GMT-04:00) Thule",
  "Atlantic/Bermuda": "(GMT-04:00) Bermuda",
  "America/St_Johns": "(GMT-03:30) Newfoundland Time - St. Johns",
  "America/Araguaina": "(GMT-03:00) Araguaina",
  "America/Argentina/Buenos_Aires": "(GMT-03:00) Buenos Aires",
  "America/Bahia": "(GMT-03:00) Salvador",
  "America/Belem": "(GMT-03:00) Belem",
  "America/Cayenne": "(GMT-03:00) Cayenne",
  "America/Fortaleza": "(GMT-03:00) Fortaleza",
  "America/Godthab": "(GMT-03:00) Godthab",
  "America/Maceio": "(GMT-03:00) Maceio",
  "America/Miquelon": "(GMT-03:00) Miquelon",
  "America/Montevideo": "(GMT-03:00) Montevideo",
  "America/Paramaribo": "(GMT-03:00) Paramaribo",
  "America/Recife": "(GMT-03:00) Recife",
  "America/Santiago": "(GMT-03:00) Santiago",
  "America/Sao_Paulo": "(GMT-03:00) Sao Paulo",
  "Antarctica/Palmer": "(GMT-03:00) Palmer",
  "Antarctica/Rothera": "(GMT-03:00) Rothera",
  "Atlantic/Stanley": "(GMT-03:00) Stanley",
  "America/Noronha": "(GMT-02:00) Noronha",
  "Atlantic/South_Georgia": "(GMT-02:00) South Georgia",
  "America/Scoresbysund": "(GMT-01:00) Scoresbysund",
  "Atlantic/Azores": "(GMT-01:00) Azores",
  "Atlantic/Cape_Verde": "(GMT-01:00) Cape Verde",
  "Africa/Abidjan": "(GMT+00:00) Abidjan",
  "Africa/Accra": "(GMT+00:00) Accra",
  "Africa/Bissau": "(GMT+00:00) Bissau",
  "Africa/Casablanca": "(GMT+00:00) Casablanca",
  "Africa/El_Aaiun": "(GMT+00:00) El Aaiun",
  "Africa/Monrovia": "(GMT+00:00) Monrovia",
  "America/Danmarkshavn": "(GMT+00:00) Danmarkshavn",
  "Atlantic/Canary": "(GMT+00:00) Canary Islands",
  "Atlantic/Faroe": "(GMT+00:00) Faeroe",
  "Atlantic/Reykjavik": "(GMT+00:00) Reykjavik",
  "Etc/GMT": "(GMT+00:00) GMT (no daylight saving)",
  "Europe/Dublin": "(GMT+00:00) Dublin",
  "Europe/Lisbon": "(GMT+00:00) Lisbon",
  "Europe/London": "(GMT+00:00) London",
  "Africa/Algiers": "(GMT+01:00) Algiers",
  "Africa/Ceuta": "(GMT+01:00) Ceuta",
  "Africa/Lagos": "(GMT+01:00) Lagos",
  "Africa/Ndjamena": "(GMT+01:00) Ndjamena",
  "Africa/Tunis": "(GMT+01:00) Tunis",
  "Africa/Windhoek": "(GMT+02:00) Windhoek",
  "Europe/Amsterdam": "(GMT+01:00) Amsterdam",
  "Europe/Andorra": "(GMT+01:00) Andorra",
  "Europe/Belgrade": "(GMT+01:00) Central European Time - Belgrade",
  "Europe/Berlin": "(GMT+01:00) Berlin",
  "Europe/Brussels": "(GMT+01:00) Brussels",
  "Europe/Budapest": "(GMT+01:00) Budapest",
  "Europe/Copenhagen": "(GMT+01:00) Copenhagen",
  "Europe/Gibraltar": "(GMT+01:00) Gibraltar",
  "Europe/Luxembourg": "(GMT+01:00) Luxembourg",
  "Europe/Madrid": "(GMT+01:00) Madrid",
  "Europe/Malta": "(GMT+01:00) Malta",
  "Europe/Monaco": "(GMT+01:00) Monaco",
  "Europe/Oslo": "(GMT+01:00) Oslo",
  "Europe/Paris": "(GMT+01:00) Paris",
  "Europe/Prague": "(GMT+01:00) Central European Time - Prague",
  "Europe/Rome": "(GMT+01:00) Rome",
  "Europe/Stockholm": "(GMT+01:00) Stockholm",
  "Europe/Tirane": "(GMT+01:00) Tirane",
  "Europe/Vienna": "(GMT+01:00) Vienna",
  "Europe/Warsaw": "(GMT+01:00) Warsaw",
  "Europe/Zurich": "(GMT+01:00) Zurich",
  "Africa/Cairo": "(GMT+02:00) Cairo",
  "Africa/Johannesburg": "(GMT+02:00) Johannesburg",
  "Africa/Maputo": "(GMT+02:00) Maputo",
  "Africa/Tripoli": "(GMT+02:00) Tripoli",
  "Asia/Amman": "(GMT+02:00) Amman",
  "Asia/Beirut": "(GMT+02:00) Beirut",
  "Asia/Damascus": "(GMT+02:00) Damascus",
  "Asia/Gaza": "(GMT+02:00) Gaza",
  "Asia/Jerusalem": "(GMT+02:00) Jerusalem",
  "Asia/Nicosia": "(GMT+02:00) Nicosia",
  "Europe/Athens": "(GMT+02:00) Athens",
  "Europe/Bucharest": "(GMT+02:00) Bucharest",
  "Europe/Chisinau": "(GMT+02:00) Chisinau",
  "Europe/Helsinki": "(GMT+02:00) Helsinki",
  "Europe/Istanbul": "(GMT+03:00) Istanbul",
  "Europe/Kaliningrad": "(GMT+02:00) Moscow-01 - Kaliningrad",
  "Europe/Kyiv": "(GMT+02:00) Kyiv",
  "Europe/Riga": "(GMT+02:00) Riga",
  "Europe/Sofia": "(GMT+02:00) Sofia",
  "Europe/Tallinn": "(GMT+02:00) Tallinn",
  "Europe/Vilnius": "(GMT+02:00) Vilnius",
  "Africa/Khartoum": "(GMT+03:00) Khartoum",
  "Africa/Nairobi": "(GMT+03:00) Nairobi",
  "Antarctica/Syowa": "(GMT+03:00) Syowa",
  "Asia/Baghdad": "(GMT+03:00) Baghdad",
  "Asia/Qatar": "(GMT+03:00) Qatar",
  "Asia/Riyadh": "(GMT+03:00) Riyadh",
  "Europe/Minsk": "(GMT+03:00) Minsk",
  "Europe/Moscow": "(GMT+03:00) Moscow+00 - Moscow",
  "Asia/Tehran": "(GMT+03:30) Tehran",
  "Asia/Baku": "(GMT+04:00) Baku",
  "Asia/Dubai": "(GMT+04:00) Dubai",
  "Asia/Tbilisi": "(GMT+04:00) Tbilisi",
  "Asia/Yerevan": "(GMT+04:00) Yerevan",
  "Europe/Samara": "(GMT+04:00) Moscow+01 - Samara",
  "Indian/Mahe": "(GMT+04:00) Mahe",
  "Indian/Mauritius": "(GMT+04:00) Mauritius",
  "Indian/Reunion": "(GMT+04:00) Reunion",
  "Asia/Kabul": "(GMT+04:30) Kabul",
  "Antarctica/Mawson": "(GMT+05:00) Mawson",
  "Asia/Aqtau": "(GMT+05:00) Aqtau",
  "Asia/Aqtobe": "(GMT+05:00) Aqtobe",
  "Asia/Ashgabat": "(GMT+05:00) Ashgabat",
  "Asia/Dushanbe": "(GMT+05:00) Dushanbe",
  "Asia/Karachi": "(GMT+05:00) Karachi",
  "Asia/Tashkent": "(GMT+05:00) Tashkent",
  "Asia/Yekaterinburg": "(GMT+05:00) Moscow+02 - Yekaterinburg",
  "Indian/Kerguelen": "(GMT+05:00) Kerguelen",
  "Indian/Maldives": "(GMT+05:00) Maldives",
  "Asia/Calcutta": "(GMT+05:30) India Standard Time",
  "Asia/Colombo": "(GMT+05:30) Colombo",
  "Asia/Katmandu": "(GMT+05:45) Katmandu",
  "Antarctica/Vostok": "(GMT+06:00) Vostok",
  "Asia/Almaty": "(GMT+06:00) Almaty",
  "Asia/Bishkek": "(GMT+06:00) Bishkek",
  "Asia/Dhaka": "(GMT+06:00) Dhaka",
  "Asia/Omsk": "(GMT+06:00) Moscow+03 - Omsk, Novosibirsk",
  "Asia/Thimphu": "(GMT+06:00) Thimphu",
  "Indian/Chagos": "(GMT+06:00) Chagos",
  "Asia/Rangoon": "(GMT+06:30) Rangoon",
  "Indian/Cocos": "(GMT+06:30) Cocos",
  "Antarctica/Davis": "(GMT+07:00) Davis",
  "Asia/Bangkok": "(GMT+07:00) Bangkok",
  "Asia/Hovd": "(GMT+07:00) Hovd",
  "Asia/Jakarta": "(GMT+07:00) Jakarta",
  "Asia/Krasnoyarsk": "(GMT+07:00) Moscow+04 - Krasnoyarsk",
  "Asia/Saigon": "(GMT+07:00) Hanoi",
  "Asia/Ho_Chi_Minh": "(GMT+07:00) Ho Chi Minh",
  "Indian/Christmas": "(GMT+07:00) Christmas",
  "Antarctica/Casey": "(GMT+08:00) Casey",
  "Asia/Brunei": "(GMT+08:00) Brunei",
  "Asia/Choibalsan": "(GMT+08:00) Choibalsan",
  "Asia/Hong_Kong": "(GMT+08:00) Hong Kong",
  "Asia/Irkutsk": "(GMT+08:00) Moscow+05 - Irkutsk",
  "Asia/Kuala_Lumpur": "(GMT+08:00) Kuala Lumpur",
  "Asia/Macau": "(GMT+08:00) Macau",
  "Asia/Makassar": "(GMT+08:00) Makassar",
  "Asia/Manila": "(GMT+08:00) Manila",
  "Asia/Shanghai": "(GMT+08:00) China Time - Beijing",
  "Asia/Singapore": "(GMT+08:00) Singapore",
  "Asia/Taipei": "(GMT+08:00) Taipei",
  "Asia/Ulaanbaatar": "(GMT+08:00) Ulaanbaatar",
  "Australia/Perth": "(GMT+08:00) Western Time - Perth",
  "Asia/Pyongyang": "(GMT+08:30) Pyongyang",
  "Asia/Dili": "(GMT+09:00) Dili",
  "Asia/Jayapura": "(GMT+09:00) Jayapura",
  "Asia/Seoul": "(GMT+09:00) Seoul",
  "Asia/Tokyo": "(GMT+09:00) Tokyo",
  "Asia/Yakutsk": "(GMT+09:00) Moscow+06 - Yakutsk",
  "Pacific/Palau": "(GMT+09:00) Palau",
  "Australia/Adelaide": "(GMT+10:30) Central Time - Adelaide",
  "Australia/Darwin": "(GMT+09:30) Central Time - Darwin",
  "Antarctica/DumontDUrville": "(GMT+10:00) Dumont D'Urville",
  "Asia/Magadan": "(GMT+10:00) Moscow+07 - Magadan",
  "Asia/Vladivostok": "(GMT+10:00) Moscow+07 - Vladivostok",
  "Australia/Brisbane": "(GMT+10:00) Eastern Time - Brisbane",
  "Asia/Yuzhno-Sakhalinsk": "(GMT+11:00) Moscow+08 - Yuzhno-Sakhalinsk",
  "Australia/Hobart": "(GMT+11:00) Eastern Time - Hobart",
  "Australia/Sydney": "(GMT+11:00) Eastern Time - Melbourne, Sydney",
  "Pacific/Chuuk": "(GMT+10:00) Truk",
  "Pacific/Guam": "(GMT+10:00) Guam",
  "Pacific/Port_Moresby": "(GMT+10:00) Port Moresby",
  "Pacific/Efate": "(GMT+11:00) Efate",
  "Pacific/Guadalcanal": "(GMT+11:00) Guadalcanal",
  "Pacific/Kosrae": "(GMT+11:00) Kosrae",
  "Pacific/Norfolk": "(GMT+11:00) Norfolk",
  "Pacific/Noumea": "(GMT+11:00) Noumea",
  "Pacific/Pohnpei": "(GMT+11:00) Ponape",
  "Asia/Kamchatka": "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
  "Pacific/Auckland": "(GMT+13:00) Auckland",
  "Pacific/Fiji": "(GMT+13:00) Fiji",
  "Pacific/Funafuti": "(GMT+12:00) Funafuti",
  "Pacific/Kwajalein": "(GMT+12:00) Kwajalein",
  "Pacific/Majuro": "(GMT+12:00) Majuro",
  "Pacific/Nauru": "(GMT+12:00) Nauru",
  "Pacific/Tarawa": "(GMT+12:00) Tarawa",
  "Pacific/Wake": "(GMT+12:00) Wake",
  "Pacific/Wallis": "(GMT+12:00) Wallis",
  "Pacific/Apia": "(GMT+14:00) Apia",
  "Pacific/Enderbury": "(GMT+13:00) Enderbury",
  "Pacific/Fakaofo": "(GMT+13:00) Fakaofo",
  "Pacific/Tongatapu": "(GMT+13:00) Tongatapu",
  "Pacific/Kiritimati": "(GMT+14:00) Kiritimati",
};
const keys = Object.keys(timezoneObj);
export const timezoneArray = keys.map((key) => {
  return { value: key + " " + timezoneObj[key].split(" ")[0], label: timezoneObj[key] };
});
