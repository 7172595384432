import { Box, Dialog, Typography, Button } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { useGetDeleteUserMutation } from 'services/user-service';

const DeleteConformation = ({ onClose, open, userId, setRefetchUser }) => {
  const [deleteUser] = useGetDeleteUserMutation();

  const handleClose = () => {
    onClose();
  };
  const handleDeleteUser = async () => {
    await deleteUser({ userId });
    handleClose();
    setRefetchUser(true);
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          backdropFilter: 'blur(1px) sepia(3%)'
        }}
        PaperProps={{ sx: { borderRadius: '1rem', width: '25%', minWidth: "300px" } }}
      >
        <Box sx={{ width: '100%', height: '100%', p: 4 }}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-Between', alignItems: 'center', borderBottom: '2px solid rgb(228, 228, 229)' }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                color: 'rgba(40, 40, 123, 1)',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                pb: '4px'
              }}
            >
              Confirmation
            </Typography>

            <CloseOutlined sx={{ cursor: 'pointer', fontSize: '2rem' }} onClick={handleClose} />
          </Box>
          <Box sx={{mb: '16px'}}>
            <Typography sx={{ height: '70px', fontSize: '17px', padding: '20px 0px', color: '#000000' }}>
              Are you sure you want to delete ?
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
            <Button
              type="button"
              onClick={handleClose}
              sx={{
                backgroundColor: '#1890ff', //'#1890ff'
                color: '#ffffff',
                border: 'none',
                padding: '4px 12px',
                fontSize: '14px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'box-shadow 0.3s',
                '&:hover': {
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                  backgroundColor: '#1890ff'
                }
              }}
            >
              Close
            </Button>
            <Button
              type="button"
              onClick={handleDeleteUser}
              sx={{
                backgroundColor: '#ff0000',
                color: '#ffffff',
                border: 'none',
                padding: '4px 12px',
                fontSize: '14px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'box-shadow 0.3s',
                '&:hover': {
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                  backgroundColor: '#ff0000'
                }
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DeleteConformation;
