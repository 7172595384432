import React from 'react';
import Box from '@mui/material/Box';
import config from '../../config.js'

const MonitorQueues = () => {
  const base_url = config.BACKEND_BASE_URL;
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'white',
        color: '#262626',
        overflow: 'hidden',
        borderRadius: '8px',
        flexDirection: 'column',
        display: 'flex',
        mt: 1
    }}>
      <iframe
        src={`${base_url}/bull-board`}
        style={{ width: '100%', height: '85vh', border: 'none' }}
        title="Bull Board"
      >
      </iframe>
    </Box>

  );
};

export default MonitorQueues;
