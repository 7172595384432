import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  CircularProgress
} from '@mui/material';
import toast from 'react-hot-toast';
import CustomCheckbox from './CustomCheckbox';
import { OffCheckboxCustomIcon } from '../../../assets/general/OffCheckboxCustomIcon';
import { OnCheckboxCustomIcon } from '../../../assets/general/OnCheckboxCustomIcon';
import { SaveIconBlue } from '../../../assets/general/SaveIcon';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { FireIcon } from '../../../assets/general/FireIcon';
import { useGetAccountsMutation } from '../../../services/account-service.js';

import { useUpdateConfigurationsMutation, useCampaignLaunchMutation, useUpdateCampaignMutation } from '../../../services/campaign-service.js';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { accountsAdded, setAccounts } from 'store/reducers/account';


// const emailAccounts = ["rahul@oodles.com", "PdS@gmail.com", "shivanshu@gmail.com", "Jon@doe.com"];

const CampaignOptions = ({ campaign, handleReftch }) => {
  const options = campaign?.options;
  const [valueTabs, setValueTabs] = useState(0);
  const dispatch = useDispatch();
  let emailAccounts = [];
  const accounts = useSelector((state) => state.account);
  
  accounts?.forEach((element) => {
    emailAccounts.push(element?.email);
  });

  const [getAccounts] = useGetAccountsMutation();

  const [UpdateCampaign] = useUpdateCampaignMutation();

  // Search, filter and pagination
  const [isLoadingMoreAccounts, setIsLoadingMoreAccounts] = useState(false);
  const [search, setSearch] = useState('a');
  const [filter] = useState(null);
  const [total, setTotal] = useState(0);
  const [launch, setLaunch] = useState(false);
  const offset = accounts?.length;
  const limit = 15;

  useEffect(() => {
    const timer = setTimeout(async () => {
      const { docs, total } = await getAccounts({ search, filter: filter?.value, limit }).unwrap();
      dispatch(setAccounts(docs));
      setTotal(total);
    }, 500);
    return () => clearTimeout(timer);
  }, [search, filter, limit, getAccounts, dispatch]);

  useEffect(() => {
    const handler = async () => {
      if (isLoadingMoreAccounts) return;
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement;

      if (scrollHeight - scrollTop === clientHeight && offset < total) {
        setIsLoadingMoreAccounts(true);
        const { docs, total } = await getAccounts({
          search,
          filter: filter?.value,
          offset,
          limit
        }).unwrap();
        dispatch(accountsAdded(docs));
        setTotal(total);
        setIsLoadingMoreAccounts(false);
      }
    };

    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, [isLoadingMoreAccounts, search, filter, total, offset, limit, getAccounts, dispatch]);

  const [UpdateConfigurations] = useUpdateConfigurationsMutation();

  const formik = useFormik({
    initialValues: {
      emailAccounts: options?.emailAccounts,
      dailyMaxLimit: options?.dailyMaxLimit,
      stopOnReply: options?.stopOnReply,
      stopOnAutoReply: options?.stopOnAutoReply,
      trackOpen: options?.trackOpen,
      trackClickedLink: options?.trackClickedLink,
      textOnly: options?.textOnly
    },
    validationSchema: Yup.object({
      emailAccounts: Yup.array().min(1, 'At least one email account is required').of(Yup.string().required('Email account is required')),
      dailyMaxLimit: Yup.string()
        .matches(/^[0-9]+$/, 'Please enter numerical digits only')
        .required('Daily Max Limit is required')
    }),
    onSubmit: async (values) => {      
      try {

        if (values.dailyMaxLimit === '') {
          formik.setFieldValue('dailyMaxLimit', '20');
        }

        const { message } = await UpdateConfigurations({
          campaignID: campaign?._id,
          options: values
        }).unwrap();

        await UpdateCampaign({
          id:campaign?._id,
          data:values
        }).unwrap()
       

        handleReftch();
        toast.success(message);
      } catch (err) {
        toast.error(err.data.error.message);
      }
    }
  });

  const [campaignLaunch, { isLoading: isSendingCampaign }] = useCampaignLaunchMutation();

  const launchCampaign = async (formik) => {
    try {
      setLaunch(true);
      await formik.handleSubmit();
      const { message } = await campaignLaunch({ id: campaign?._id }).unwrap();
      toast.success(message);
    } catch (err) {
      toast.error(err?.data?.error?.message);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column'
        }}
      >

        <form noValidate onSubmit={formik.handleSubmit} style={{ width: '100%' }}>

          <Box
            sx={{
              boxShadow: '0px 12px 15px 0px #4B71970D',
              borderRadius: '12px',
              backgroundColor: 'white',
              width: '100%',
              mb: 4,
              p: 3
            }}
          >
            <Grid
              container
              sx={{
                backgroundColor: '#F2F4F6',
                width: '100%',
                borderRadius: '8px',
                p: 0.4,
                border: '1px solid #F2F4F7'
              }}
            >
              <Grid item xs={2.4}>
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: valueTabs === 0 ? 'white' : 'transparent',
                    color: valueTabs === 0 ? '#0071F6' : '#8181B0',
                    '&:hover': {
                      backgroundColor: valueTabs === 0 ? 'white' : 'transparent'
                    },
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    letterSpacing: '0em',
                    boxShadow: valueTabs === 0 && '0px 1px 2px 0px #1018280F',
                    borderRadius: '5px',
                    py: 1
                  }}
                  onClick={() => {
                    setValueTabs(0);
                  }}
                >
                  Accounts
                </Button>
              </Grid>
              <Grid item xs={2.4}>
                <Button
                  // variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: valueTabs === 1 ? 'white' : 'transparent',
                    color: valueTabs === 1 ? '#0071F6' : '#8181B0',
                    '&:hover': {
                      backgroundColor: valueTabs === 1 ? 'white' : 'transparent'
                    },
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    letterSpacing: '0em',
                    boxShadow: valueTabs === 1 && '0px 1px 2px 0px #1018280F',
                    borderRadius: '5px',
                    // mr: 0.5,
                    py: 1
                  }}
                  onClick={() => {
                    setValueTabs(1);
                  }}
                >
                  Reply Stops
                </Button>
              </Grid>
              <Grid item xs={2.4}>
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: valueTabs === 2 ? 'white' : 'transparent',
                    color: valueTabs === 2 ? '#0071F6' : '#8181B0',
                    '&:hover': {
                      backgroundColor: valueTabs === 2 ? 'white' : 'transparent'
                    },
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    letterSpacing: '0em',
                    boxShadow: valueTabs === 2 && '0px 1px 2px 0px #1018280F',
                    borderRadius: '5px',
                    py: 1
                  }}
                  onClick={() => {
                    setValueTabs(2);
                  }}
                >
                  Tracking
                </Button>
              </Grid>
              <Grid item xs={2.4}>
                <Button
                  // variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: valueTabs === 3 ? 'white' : 'transparent',
                    color: valueTabs === 3 ? '#0071F6' : '#8181B0',
                    '&:hover': {
                      backgroundColor: valueTabs === 3 ? 'white' : 'transparent'
                    },
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    letterSpacing: '0em',
                    boxShadow: valueTabs === 3 && '0px 1px 2px 0px #1018280F',
                    borderRadius: '5px',
                    py: 1
                  }}
                  onClick={() => {
                    setValueTabs(3);
                  }}
                >
                  Daily Limit
                </Button>
              </Grid>
              <Grid item xs={2.4}>
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: valueTabs === 4 ? 'white' : 'transparent',
                    color: valueTabs === 4 ? '#0071F6' : '#8181B0',
                    '&:hover': {
                      backgroundColor: valueTabs === 4 ? 'white' : 'transparent'
                    },
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    letterSpacing: '0em',
                    boxShadow: valueTabs === 4 && '0px 1px 2px 0px #1018280F',
                    borderRadius: '5px',
                    // ml: 0.5,
                    py: 1
                  }}
                  onClick={() => {
                    setValueTabs(4);
                  }}
                >
                  Optimized Sending
                </Button>
              </Grid>
            </Grid>

            <Box sx={{ mt: 3 }}>
              {valueTabs === 0 ? (
                <>
                  {' '}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      width: '100%',
                      height: 'fit-content'
                    }}
                  >
                    {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgb(33, 111, 237)",
                    borderRadius: "20px",
                    p: 1,
                  }}
                >
                  <StopCircleOutlined sx={{ color: "white" }} />
                </Box> */}
                    <Box sx={{}}>
                      {' '}
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#28287B'
                        }}
                      >
                        Accounts to use
                      </Typography>{' '}
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '22px',
                          letterSpacing: '0em',
                          color: '#8181B0'
                        }}
                      >
                        Select one or more accounts to send emails from
                      </Typography>
                    </Box>{' '}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                      }}
                    >
                      {' '}
                      <Autocomplete
                        freeSolo
                        multiple
                        disableCloseOnSelect
                        options={emailAccounts}
                        getOptionLabel={(option) => option}
                        filterSelectedOptions
                        value={formik.values.emailAccounts}
                        onChange={(_, newValue) => {
                          formik.setFieldValue('emailAccounts', newValue);
                        }}
                        onBlur={formik.handleBlur('emailAccounts')}
                        renderOption={(props, option, { selected }) => (
                          <li
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              px: 0
                            }}
                            {...props}
                          >
                            <Checkbox
                              icon={<OffCheckboxCustomIcon />}
                              checkedIcon={<OnCheckboxCustomIcon />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 500,
                                lineHeight: '16px',
                                color: '#28287B'
                              }}
                            >
                              {' '}
                              {option}
                            </Typography>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            onChange={(event) => {
                              setSearch(event.target.value);
                            }}
                            // onChange = {formik.handleChange}
                            {...params}
                            placeholder="Select..."
                            value={formik.values.emailAccounts}
                            variant="outlined"
                            error={!!(formik.touched.emailAccounts && formik.errors.emailAccounts)}
                            helperText={formik.touched.emailAccounts && formik.errors.emailAccounts}
                            name="emailAccounts"
                            sx={{
                              
                              backgroundColor: 'white',
                              '& div': { pl: 0.3 },
                              '& div fieldset': {
                                borderRadius: '8px',
                                border: '1px solid #E4E4E5'
                              },
                              '& div input': {
                                py: 1.5,
                                fontSize: '13px',
                                fontWeight: 400,
                                lineHeight: '16px',
                                letterSpacing: '0em',
                                '&::placeholder': {
                                  color: 'rgba(40, 40, 123, 0.5)'
                                }
                              },
                              '& label': {
                                fontSize: '14px',
                                fontWeight: 700,
                                lineHeight: '18px',
                                letterSpacing: '0px',
                                color: '#28287B'
                              }
                            }}
                            size="small"
                          />
                        )}
                        sx={{ width: '420px', height: '100%' }}
                      />
                    </Box>
                  </Box>
                  {/* <FormGroup sx={{ width: "100%", justifyContent: "flex-start", mt: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        icon={<OffCheckboxCustomIcon />}
                        checkedIcon={<OnCheckboxCustomIcon />}
                      />
                    }
                    label="Pause on Auto-Reply"
                    sx={{
                      "& span": {
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "16px",
                        letterSpacing: "0em",
                        color: "#28287B",
                      },
                    }}
                  />
                </FormGroup> */}
                </>
              ) : valueTabs === 1 ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgb(33, 111, 237)",
                    borderRadius: "20px",
                    p: 1,
                  }}
                >
                  <StopCircleOutlined sx={{ color: "white" }} />
                </Box> */}
                    <Box sx={{}}>
                      {' '}
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#28287B'
                        }}
                      >
                        Stop emails to a lead after they reply.
                      </Typography>{' '}
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '22px',
                          letterSpacing: '0em',
                          color: '#8181B0'
                        }}
                      >
                        Don not email a lead once they have responded.
                      </Typography>
                    </Box>{' '}
                    <CustomCheckbox
                      value={formik.values.stopOnReply}
                      onChange={(_, newValue) => {
                        formik.setFieldValue('stopOnReply', newValue);
                      }}
                      checked={formik.values.stopOnReply}
                    />
                  </Box>

                  <FormGroup sx={{ width: '100%', justifyContent: 'flex-start', mt: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={formik.values.stopOnAutoReply}
                          onChange={(_, newValue) => {
                            formik.setFieldValue('stopOnAutoReply', newValue);
                          }}
                          checked={formik.values.stopOnAutoReply}
                          icon={<OffCheckboxCustomIcon />}
                          checkedIcon={<OnCheckboxCustomIcon />}
                        />
                      }
                      label="Pause on Auto-Reply"
                      sx={{
                        '& span': {
                          fontSize: '13px',
                          fontWeight: 500,
                          lineHeight: '16px',
                          letterSpacing: '0em',
                          color: '#28287B'
                        }
                      }}
                    />
                  </FormGroup>
                </>
              ) : valueTabs === 2 ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgb(33, 111, 237)",
                    borderRadius: "20px",
                    p: 1,
                  }}
                >
                  <ShareLocationOutlined sx={{ color: "white" }} />
                </Box> */}

                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        color: '#28287B'
                      }}
                    >
                      Tracking
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '100%',
                      mt: 1
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // flexDirection: "column",

                        mr: 3
                      }}
                    >
                      {' '}
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '22px',
                          letterSpacing: '0em',
                          color: '#8181B0',
                          mr: 1.5
                        }}
                      >
                        Track Opened Emails
                      </Typography>
                      <CustomCheckbox
                        value={formik.values.trackOpen}
                        onChange={(_, newValue) => {
                          formik.setFieldValue('trackOpen', newValue);
                        }}
                        checked={formik.values.trackOpen}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {' '}
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '22px',
                          letterSpacing: '0em',
                          color: '#8181B0',
                          mr: 1.5
                        }}
                      >
                        Track Clicked Links
                      </Typography>
                      <CustomCheckbox
                        value={formik.values.trackClickedLink}
                        onChange={(_, newValue) => {
                          formik.setFieldValue('trackClickedLink', newValue);
                        }}
                        checked={formik.values.trackClickedLink}
                        handle
                      />
                    </Box>
                  </Box>
                </>
              ) : valueTabs === 3 ? (
                <>
                  {' '}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgb(33, 111, 237)",
                    borderRadius: "20px",
                    p: 1,
                  }}
                >
                  <PublishOutlined sx={{ color: "white" }} />
                </Box> */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        flexDirection: 'column'
                      }}
                    >
                      {' '}
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#28287B'
                        }}
                      >
                        Daily Max Limit
                      </Typography>{' '}
                    </Box>{' '}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: '22px',
                        letterSpacing: '0em',
                        color: '#8181B0',
                        mr: 1.5
                      }}
                    >
                      Maximum daily emails total number for this campaign.
                    </Typography>
                    <TextField
                      variant="outlined"
                      name="dailyMaxLimit"
                      value={formik.values.dailyMaxLimit}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.dailyMaxLimit && Boolean(formik.errors.dailyMaxLimit)}
                      helperText={formik.touched.dailyMaxLimit && formik.errors.dailyMaxLimit}
                      sx={{
                        width: 228,
                        // height: 40,
                        backgroundColor: 'white',
                        '& div': { pl: 0.3 },
                        '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                        '& div input': {
                          py: 1.3,
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '16px',
                          letterSpacing: '0em',
                          '&::placeholder': {
                            color: 'rgba(40, 40, 123, 0.5)'
                          }
                        }
                        // boxShadow: 10,
                      }}
                    />
                  </Box>
                </>
              ) : valueTabs === 4 ? (
                <>
                  {' '}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgb(33, 111, 237)",
                    borderRadius: "20px",
                    p: 1,
                  }}
                >
                  <PsychologyOutlined sx={{ color: "white" }} />
                </Box> */}

                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        color: '#28287B'
                      }}
                    >
                      Optimized Sending
                    </Typography>
                  </Box>
                  <FormGroup sx={{ width: '100%', justifyContent: 'flex-start', mt: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={formik.values.textOnly}
                          onChange={(_, newValue) => {
                            formik.setFieldValue('textOnly', newValue);
                          }}
                          checked={formik.values.textOnly}
                          icon={<OffCheckboxCustomIcon />}
                          checkedIcon={<OnCheckboxCustomIcon />}
                        />
                      }
                      label="Send as plain text, no HTML."
                      sx={{
                        '& span': {
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '22px',
                          letterSpacing: '0em',
                          color: '#8181B0'
                        }
                      }}
                    />
                  </FormGroup>
                </>
              ) : null}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              mt: 0,
              py: 3
            }}
          >
            <Button
             onClick={() => (formik)}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  boxShadow: 10
                },
                textAlign: 'left',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                letterSpacing: '0em',
                color: '#0071F6',
                backgroundColor: 'white',
                borderRadius: '8px',
                px: 1.5,
                py: 1.5,
                border: '1px solid #0071F6'
              }}
              variant="outlined"
              type="submit"
            >
              {formik.isSubmitting && !launch ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
                    <SaveIconBlue/>
                  Save
                  </Box>
                </>
              )}
            </Button>
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#164694',
                  boxShadow: 10
                },
                textAlign: 'left',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                letterSpacing: '0em',
                color: 'white',
                backgroundColor: '#0071F6',
                borderRadius: '8px',
                px: 1.5,
                py: 1.5,
                ml: 3
              }}
              variant="contained"
              onClick={() => launchCampaign(formik)}
            >
              {isSendingCampaign ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
                    <FireIcon />
                  </Box>
                  Launch
                </>
              )}
            </Button>
          </Box>
        </form>
      </Box>

      {/* Old design */}
      
    </>
  );
};

export default CampaignOptions;
