import {
    Box,
    Typography,
} from "@mui/material";

export const UserCard = ({ Icon, title, count }) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 2,
            borderRadius: '12px',
            border: '1px solid rgba(33, 111, 237, 0.5)',
            padding: 2,
            cursor: 'pointer',
            maxWidth: 230,
            width: '100%',

        }}
    >
        <Box>
            <Icon />
        </Box>
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
            }}
        >
            <Typography
                sx={{
                    color: '#28287B',
                    fontWeight: '600',
                    fontSize: '15px',
                }}
            >
                {title}
            </Typography>
            <Typography>{count}</Typography>
        </Box>
    </Box>
);
