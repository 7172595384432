import { Box, Dialog, Typography, Button, TextField } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { useGetUserAppSumoPlanUpdateMutation } from 'services/user-service';

const errors = {
  appSumoPlan: false,
  appSumoCode: false
};

const ChangeAppSumoPlan = ({ onClose, open, usageData, userId, setRefetchUser }) => {
  const [updateAppSumoPlan] = useGetUserAppSumoPlanUpdateMutation();
  const [selectedAppSumoPlan, setSelectedAppSumoPlans] = useState('');
  const [newAppSumoCode, setNewAppSumoCode] = useState('');
  const [error, setError] = useState(errors);

  const appSumoPlans = [
    {
      PlanName: 'AppSumo Tier 1',
      value: 'successai_tier1'
    },
    {
      PlanName: 'AppSumo Tier 2',
      value: 'successai_tier2'
    },
    {
      PlanName: 'AppSumo Tier 3',
      value: 'successai_tier3'
    },
    {
      PlanName: 'AppSumo Tier 4',
      value: 'successai_tier4'
    }
  ];
  const handleClose = () => {
    onClose();
    setSelectedAppSumoPlans('');
    setNewAppSumoCode('');
    setError(errors);
  };
  const handleChange = (event) => {
    setSelectedAppSumoPlans(event.target.value);
  };

  const handleChangeAppSumoPlan = async () => {
    let hasError = false;
    if (!selectedAppSumoPlan) {
      setError((prevState) => ({
        ...prevState,
        appSumoPlan: true
      }));
      hasError = true;
    }
    if (!(usageData?.appSumoCode || newAppSumoCode)) {
      setError((prevState) => ({
        ...prevState,
        appSumoCode: true
      }));
      hasError = true;
    }

    if (!hasError) {
      await updateAppSumoPlan({
        userId: userId,
        appsumoPlan: selectedAppSumoPlan,
        appSumoCode: usageData?.appSumoCode || newAppSumoCode,
        isNew: !usageData.appSumoCode
      });
      setError(errors);
      handleClose();
      setRefetchUser(true);
    }
  };

  const handleAppSumoCode = (e) => {
    setNewAppSumoCode(e.target.value);
  };

  useEffect(() => {
    if (!selectedAppSumoPlan?.length) {
      setSelectedAppSumoPlans(usageData?.Appsumo);
    }
    if (!newAppSumoCode) {
      setNewAppSumoCode(usageData?.appSumoCode);
    }
  }, [usageData]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          backdropFilter: 'blur(1px) sepia(3%)'
        }}
        PaperProps={{ sx: { borderRadius: '1rem', width: '35%' } }}
      >
        <Box sx={{ width: '100%', height: '100%', p: 4 }}>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-Between', alignItems: 'center', borderBottom: '2px solid rgb(228, 228, 229)' }}
          >
            <Typography
              variant="h4"
              component="h2"
              sx={{
                color: 'rgba(40, 40, 123, 1)',
                fontFamily: 'Plus Jakarta Sans, sans-serif',
                pb: '4px'
              }}
            >
              AppSumo plan
            </Typography>

            <CloseOutlined sx={{ cursor: 'pointer', fontSize: '2rem', color: 'rgba(40, 40, 123, 1)' }} onClick={handleClose} />
          </Box>
          <Box sx={{ paddingTop: '15px' }}>
            <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '16px' }}>
              Current plan :{' '}
              <Typography sx={{ paddingLeft: '5px', fontSize: '16px', fontWeight: 600, color: 'rgba(40, 40, 123, 1)' }}>
                {appSumoPlans?.find((plan) => plan?.value === usageData?.Appsumo)?.PlanName || '-'}
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ marginTop: '16px', fontSize: '16px' }}>
            <Typography sx={{ fontWeight: 700, fontSize: '16px' }}> Select plan* :</Typography>
            <Box sx={{ minWidth: 120, padding: '10px 0px' }}>
              <FormControl fullWidth error={error.appSumoPlan}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedAppSumoPlan || ''}
                  onChange={handleChange}
                >
                  {appSumoPlans?.map((item, index) => {
                    return (
                      <MenuItem value={item?.value} key={index}>
                        {item?.PlanName}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{error.appSumoPlan && 'This field is required.'}</FormHelperText>
              </FormControl>
            </Box>
            <Box sx={{ width: '100%', padding: '10px 0px' }}>
              <Typography sx={{ fontWeight: 700, fontSize: '16px' }}> AppSumo code* :</Typography>
              <TextField
                error={error.appSumoCode}
                disabled={usageData?.appSumoCode}
                defaultValue={usageData?.appSumoCode}
                placeholder={'Enter AppSumo code'}
                sx={{ width: '100%' }}
                onChange={handleAppSumoCode}
                helperText={error.appSumoCode && 'AppSumo code is required.'}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: '16px', marginTop: '8px' }}>
            <Button
              type="button"
              onClick={handleClose}
              sx={{
                backgroundColor: '#1890ff', //'#1890ff'
                color: '#ffffff',
                border: 'none',
                padding: '4px 12px',
                fontSize: '14px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'box-shadow 0.3s',
                '&:hover': {
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                  backgroundColor: '#1890ff'
                }
              }}
            >
              Close
            </Button>
            <Button
              type="button"
              onClick={handleChangeAppSumoPlan}
              sx={{
                backgroundColor: '#1890ff', //'#1890ff'
                color: '#ffffff',
                border: 'none',
                padding: '4px 12px',
                fontSize: '14px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'box-shadow 0.3s',
                '&:hover': {
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                  backgroundColor: '#1890ff'
                }
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ChangeAppSumoPlan;
