import { Box, Grid, Button, TextField, Typography, Dialog } from '@mui/material';
// import {  Checkbox, FormControlLabel, } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
// import { OnCheckboxCustomIcon } from 'assets/general/OnCheckboxCustomIcon';
// import { OffCheckboxCustomIcon } from 'assets/general/OffCheckboxCustomIcon';
// import Setting from 'pages/components/campaign/Setting';
import BasicWarmup from 'pages/components/emailWarmup/BasicWarmup';
import AdvanceWarmup from 'pages/components/emailWarmup/AdvanceWarmup';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomCheckbox from 'components/CustomCheckbox';
import { useEmailDKIMMutation, useUpdateEmailAccountsMutation } from 'services/campaign-service';
import { RxCross1 } from 'react-icons/rx';
import { FaCheck } from 'react-icons/fa6';
import { useState, useRef, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useCodeMirror } from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';

const SettingsModal = (props) => {
  const { onClose, open, userData, setRefetch, selectedValue } = props;

  const [updateAccount] = useUpdateEmailAccountsMutation();
  const [emailDKIM] = useEmailDKIMMutation();
  const [isActive, setIsActive] = useState(false);
  const [dkim, setDKIM] = useState(null);
  const sendData = async (updatedData) => {
    try {
      await updateAccount({
        id: userData.id,
        data: updatedData
      });
      setRefetch(true);
      setIsActive(false);
      setDKIM(null);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: {
        first: userData?.name?.first,
        last: userData?.name?.last
      },
      campaign: {
        dailyLimit: userData?.campaign?.dailyLimit,
        waitTime: userData?.campaign?.waitTime
      },
      replyTo: userData?.email,
      customDomain: {
        isEnable: userData?.customDomain?.isEnable
      },
      warmup: {
        basicSetting: {
          increasePerDay: userData?.warmup?.basicSetting?.increasePerDay,
          slowWarmupDisabled: userData?.warmup?.basicSetting?.slowWarmupDisabled,
          limitPerDay: userData?.warmup?.basicSetting?.limitPerDay,
          replyRate: userData?.warmup?.basicSetting?.replyRate,
          alertBlock: userData?.warmup?.basicSetting?.alertBlock
        },
        advanceSetting: {
          weekdayOnly: userData?.warmup?.advanceSetting?.weekdayOnly,
          readEmulation: userData?.warmup?.advanceSetting?.readEmulation,
          customTrackingDomain: userData?.warmup?.advanceSetting?.customTrackingDomain,
          openRate: userData?.warmup?.advanceSetting?.openRate,
          spamProtectionRate: userData?.warmup?.advanceSetting?.spamProtectionRate,
          markImportantRate: userData?.warmup?.advanceSetting?.markImportantRate
        },
        status: userData?.warmup?.status
      }
    },
    validationSchema: Yup.object({
      // name: Yup.object({
      //   first: Yup.string().required('First Name is required'),
      //   last: Yup.string().required('Last Name is required')
      // }),
      campaign: Yup.object({
        dailyLimit: Yup.number().integer('Must be an integer').required('Daily Limit is required'),
        waitTime: Yup.number().integer('Must be an integer').required('Daily Limit is required')
      }),
      replyTo: Yup.string().email().required().label('Reply to')
      // warmup: Yup.object({
      //   basicSetting: Yup.object({
      //     increasePerDay: Yup.number().integer().min(1).max(4).required().label('Increase per day'),
      //     limitPerDay: Yup.number().integer().min(20).max(200).required().label('Daily warmup limit'),
      //     replyRate: Yup.number().integer().min(1).max(100).required().label('Reply rate')
      //   })
      // })
    }),
    onSubmit: async (values) => {
      const finalUserData = { ...values, _id: userData?._id };
      await sendData({ accountInfo: finalUserData });
      onClose();
    }
  });
  const handleClose = () => {
    onClose(selectedValue);
    setDKIM(null);
    setIsActive(false);
  };

  const handleEmailDKIM = async () => {
    setIsActive(true);

    if (!dkim) {
      const { data } = await emailDKIM({ email: userData?.email });
      if (data?.status) {
        setDKIM(data?.data);
      }
    }
  };

  const handleFormikChange = (fieldName, value, formik) => {
    formik.setFieldValue(fieldName, value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          maxWidth="sm"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 'full',
            margin: '1rem',
            bgcolor: 'white',
            padding: '1rem'
          }}
        >
          <GlobalStyles
            styles={{
              '*::-webkit-scrollbar': {
                width: '8px',
                height: '8px'
              },
              '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#c3c3c7',
                borderRadius: '10px',
                border: '1px solid rgba(0, 0, 0, 0)'
              },
              '*::-webkit-scrollbar-track': {
                borderRadius: '60px',
                width: '4px',
                backgroundColor: 'rgb(242, 244, 246)'
              }
            }}
          />

          <Box>
            <Typography
              sx={{
                color: '#28287B',
                fontSize: '22px',
                fontWeight: 700,
                lineHeight: '28px'
              }}
            >
              Email Account Setting
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                color: '#28287B',
                textAlign: 'left',
                width: '100%',
                margin: '24px 0px',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '20px'
              }}
            >
              {userData?.email}
            </Box>
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                fontWeight: 700,
                height: 36,
                width: 175
              }}
              variant="contained"
              type="button"
              onClick={handleEmailDKIM}
            >
              Show DKIM Details
            </Button>
          </Box>
          <Box>
            <ShowDKIMDetails dkim={dkim} isActive={isActive} userData={userData} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: '-26px',
              mt: '16px',
              gap: '8px'
            }}
          >
            <Typography
              sx={{
                color: '#28287B',
                fontSize: '17px',
                fontWeight: 700,
                lineHeight: '16px'
              }}
            >
              Warmup status:
            </Typography>
            <CustomCheckbox
              name="warmup.status"
              checked={formik.values.warmup.status === 'enabled'}
              onChange={(e) => {
                handleFormikChange('warmup.status', e.target.checked ? 'enabled' : 'paused', formik);
              }}
              value={formik.values.warmup.status}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 'full'
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '26px',
                color: 'rgba(40, 40, 123, 1)'
              }}
            >
              Name
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              width: 'full',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Grid container spacing={2} sx={{ my: 0.5 }}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="name.first"
                  placeholder="First name"
                  value={formik.values.name.first}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!(formik.touched.name?.first && formik.errors.name?.first)}
                  helperText={formik.touched.name?.first && formik.errors.name?.first}
                  sx={{
                    '& div': { pl: 0.3, borderRadius: '0.7rem' },
                    '& div fieldset': {
                      border: '1px solid #E4E4E5'
                    },

                    '& div input': {
                      py: 1.3,
                      fontSize: '13px',
                      fontWeight: 400,
                      lineHeight: '16px',
                      letterSpacing: '0em',
                      '&::placeholder': {
                        color: 'rgba(40, 40, 123, 0.5)'
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={formik.values.name.last}
                  name="name.last"
                  placeholder="Last name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!(formik.touched.name?.last && formik.errors.name?.last)}
                  helperText={formik.touched.name?.last && formik.errors.name?.last}
                  sx={{
                    width: 'full',
                    height: 48,
                    backgroundColor: 'white',
                    '& div': { pl: 0.3, borderRadius: '0.7rem' },
                    '& div fieldset': {
                      border: '1px solid #E4E4E5'
                    },
                    '& div input': {
                      py: 1.3,
                      fontSize: '13px',
                      fontWeight: 400,
                      lineHeight: '16px',
                      letterSpacing: '0em',
                      '&::placeholder': {
                        color: 'rgba(40, 40, 123, 0.5)'
                      }
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* <Setting formik={formik} /> */}

          {/* <Box
            sx={{
              borderRadius: '12px',
              p: 3,
              mt: 3,
              border: '1px solid #E4E4E5'
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: 'full',
                    borderBottom: '1px solid #E4E4E5',
                    pb: 2
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      color: '#28287B'
                    }}
                  >
                    Custom Tracking Domain
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width: 'full'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<OffCheckboxCustomIcon />}
                        checkedIcon={<OnCheckboxCustomIcon />}
                        checked={formik.values.customDomain.isEnable}
                        name="customDomain.isEnable"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Enable Custom Tracking Domain"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '13px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: '#28287B',
                        ml: 1
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box> */}

          <BasicWarmup formik={formik} />
          <AdvanceWarmup formik={formik} />

          <SaveButton onClick={() => {
            formik.handleSubmit;
            handleClose();
            }} />
        </Box>
      </form>
    </Dialog>
  );
};

const SaveButton = ({ onClick }) => {
  return (
    <Button
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '18px',
        py: 2,
        px: 3
      }}
      variant="contained"
      type="submit"
      onClick={onClick}
    >
      Save
    </Button>
  );
};

const ShowDKIMDetails = ({ dkim, isActive, userData }) => {
  const errors = ['SPF', 'DKIM', 'MX', 'TXT', 'DMARC', 'ssl', 'Custom Tracking'];
  const [showDetails, setShowDetails] = useState(false);
  const cross = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '24px',
        height: '24px',
        color: 'white',
        backgroundColor: 'red',
        borderRadius: '50%'
      }}
    >
      <RxCross1 />
    </Box>
  );

  const checked = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '24px',
        height: '24px',
        color: 'white',
        backgroundColor: 'green',
        borderRadius: '50%'
      }}
    >
      <FaCheck />
    </Box>
  );

  const editor = useRef(null);
  const jsonString = JSON.stringify(dkim, null, 2);
  const { setContainer } = useCodeMirror({
    container: editor.current,
    extensions: [json()],
    basicSetup: {
      lineNumbers: true
    },
    value: jsonString
  });

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
  }, [editor.current, dkim, showDetails]);
  return (
    <>
      {isActive && (
        <Box
          sx={{
            borderRadius: '12px',
            p: 3,
            border: '1px solid #E4E4E5'
          }}
        >
          {dkim ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    alignItems: { xs: 'start', sm: 'center' },
                    width: 'full',
                    borderBottom: '1px solid #E4E4E5',
                    pb: 2,
                    gap: '8px'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      color: '#28287B'
                    }}
                  >
                    Email Records
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      window.open(`${dkim?.websiteData?.websiteDomain}`, '_blank');
                    }}
                  >
                    <LanguageIcon sx={{ color: '#28287B', fontSize: '20px' }} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          color: '#28287B'
                        }}
                      >
                        Domain:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          color: '#28287B',
                          paddingLeft: '5px'
                        }}
                      >
                        {dkim?.websiteData?.websiteDomain}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', mt: '16px', flexWrap: 'wrap' }}>
                  {errors.map((error) => {
                    const isErrorActive =
                      (error === 'SPF' && (!dkim || !dkim.records || !dkim.records.SPF || !dkim.records.SPF.length)) ||
                      (error === 'DKIM' && (!dkim || dkim.dkimDetails === 'Not able to find DKIM Record')) ||
                      (error === 'MX' && (!dkim || dkim.summary.find((item) => item.MX === 'Not Found'))) ||
                      (error === 'TXT' && (!dkim || dkim.summary.find((item) => item.TXT === 'Not Found'))) ||
                      (error === 'DMARC' && (!dkim || dkim.summary.find((item) => item.DMARC === 'Not Found'))) ||
                      (error === 'Custom Tracking' && (!userData || !userData.customDomain.isEnable)) ||
                      (error === 'ssl' && (!dkim || dkim.websiteData.ssl === 'not found'));

                    return (
                      <Box
                        key={error}
                        xs={4}
                        sm={2}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          height: 45,
                          borderRadius: '8px',
                          border: `1px solid ${isErrorActive ? 'red' : 'green'}`,
                          px: '5px'
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#28287B',
                            fontWeight: 700
                          }}
                        >
                          {error}
                        </Typography>
                        <Box sx={{ marginLeft: '8px' }}>{isErrorActive ? <>{cross}</> : <>{checked}</>}</Box>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ m: 0 }}>
                <Button
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    fontWeight: 700,
                    height: 36,
                    width: 175,
                    marginBottom: '8px',
                    marginLeft: 'auto'
                  }}
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    setShowDetails(!showDetails);
                  }}
                >
                  {showDetails ? 'Hide' : 'Show'} Advance Details
                </Button>
                {showDetails && <Box ref={editor} />}
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default SettingsModal;
