import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config.js';
import { getAuthToken } from './localstorage.helper.js';

export const baseQuery = fetchBaseQuery({
  baseUrl: config.API_BASE_URL,
  prepareHeaders: (headers) => {
    const token = getAuthToken();
    const authorization = headers.get('Authorization');
    if (token && !authorization) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});
