import React from 'react';

export const BulkUploadIcon = () => {
  return (
    <>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#DAEFDF" />
        <path
          d="M22.8016 33.5998H18.0016C16.6761 33.5998 15.6016 32.5253 15.6016 31.1998L15.6017 16.7999C15.6017 15.4744 16.6762 14.3999 18.0017 14.3999H28.8019C30.1274 14.3999 31.2019 15.4744 31.2019 16.7999V23.3999M19.8019 19.1999H27.0019M19.8019 22.7999H27.0019M19.8019 26.3999H23.4019"
          stroke="#00AA38"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.3984 31.1573L29.9442 33.5999M29.9442 33.5999L27.5984 31.2677M29.9442 33.5999L29.9442 27.5999"
          stroke="#00AA38"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
