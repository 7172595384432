import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
  Stack,
  Popover,
  useTheme,
  useMediaQuery,
  DialogActions,
  InputLabel,
} from "@mui/material";
import { ArrowDropDown, CloseOutlined,  PersonRemove } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { HeroIcon } from "../../../assets/campaignSequence/HeroIcon";
import { BoltIcon } from "../../../assets/general/BoltIcon";
import { DeleteIconBlack } from "../../../assets/general/DeleteIcon";
import { EditIcon } from "../../../assets/general/EditIcon";
import { CopyIcon } from "../../../assets/general/CopyIcon";
import { DragIcon } from "../../../assets/general/DragIcon";
import { OpenAiIcon } from "../../../assets/general/OpenAiIcon";
import { Editor } from "@tinymce/tinymce-react";
import {
  useWriteEmailMutation,
  useOptimizeEmailMutation,
  useCreateSequenceMutation,
  useUpdateSequenceMutation,
  useDeleteSequenceMutation,
  useUpdateSequenceOrderMutation,
  useCopySequenceMutation,
  useGetCampaignVariablesQuery,
} from "../../../services/campaign-service.js";
import CustomCounterProgress from "../emailChecker/CustomCounterProgress";
import { useRef } from "react";
import checkSpamWords from "../emailChecker/utils/checkSpamTree";

const [maxSubjectCount, maxWordCount, maxReadingTime, maxLinks, maxQuestions, maxSpams] = [
  15, 500, 210, 3, 4, 7,
];

const DraggableSequence = ({ sequence, index, showWaitDays, showDelete, onEditClick }) => {
  const [waitDays, setWaitDays] = useState(sequence.waitDays);
  const [error, setError] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);

  const [deleteSequence, { isLoading: isDeleteSequenceLoading }] = useDeleteSequenceMutation();
  const [updateSequence, { isLoading: isUpdateSequenceLoading }] = useUpdateSequenceMutation();
  const [copySequence, { isLoading: isCopySequenceLoading }] = useCopySequenceMutation();

  useEffect(() => {
    if (waitDays && parseInt(waitDays) !== sequence.waitDays) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [sequence.waitDays, waitDays]);

  const handleDelete = async () => {
    const { message } = await deleteSequence(sequence._id).unwrap();
    toast.success(message);
  };

  const handleEdit = () => {
    onEditClick(sequence._id);
  };

  const handleCopy = async () => {
    const { message } = await copySequence(sequence._id).unwrap();
    toast.success(message);
  };

  const handleSaveWaitDays = async () => {
    if (waitDays > 0) {
      const { message } = await updateSequence({ id: sequence._id, data: { waitDays } }).unwrap();
      toast.success(message);
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Draggable draggableId={sequence._id} index={index}>
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              userSelect: "none",
              margin: "0 0 8px 0",
              borderRadius: 4,
              ...provided.draggableProps.style,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <StepLabel>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "18px",
                      letterSpacing: "0px",
                      color: "#28287B",
                    }}
                  >
                    Step {sequence.step}
                  </Typography>
                  <Box
                    sx={{
                      display: showWaitDays ? "flex" : "none",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "15px",
                        letterSpacing: "0px",
                        color: "#28287B",
                        ml: 2,
                      }}
                    >
                      After
                    </Typography>
                    <TextField
                      type="number"
                      inputProps={{ min: 0 }}
                      variant="outlined"
                      InputProps={{ inputProps: { min: 0 } }}
                      sx={{
                        width: 75,
                        "& div": { pl: 0.3 },
                        "& div fieldset": { borderRadius: "8px", border: "1px solid #E4E4E5" },
                        "& div input": {
                          backgroundColor: "white",
                          fontSize: "13px",
                          fontWeight: 400,
                          lineHeight: "16px",
                          letterSpacing: "0em",
                          "&::placeholder": {
                            color: "rgba(40, 40, 123, 0.5)",
                          },
                        },
                        ml: 1,
                      }}
                      size="small"
                      value={waitDays}
                      onChange={(e) => setWaitDays(e.target.value)}
                      error={error}
                      helperText={error ? "Invalid" : ""}
                    />
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "15px",
                        letterSpacing: "0px",
                        color: "#28287B",
                        ml: 1,
                      }}
                    >
                      days
                    </Typography>
                    {isUpdateSequenceLoading ? (
                      <CircularProgress size={20} sx={{ ml: 2 }} />
                    ) : (
                      <Button
                        sx={{
                          display: !showSaveButton && "none",
                          color: "#0071F6",
                          cursor: "pointer",
                          "&:hover": {
                            color: "#164694",
                          },
                          ml: 2,
                          fontSize: "13px",
                          fontWeight: 700,
                          lineHeight: "16px",
                        }}
                        variant="outlined"
                        onClick={handleSaveWaitDays}
                      >
                        Save
                      </Button>
                    )}
                  </Box>
                </Box>
              </StepLabel>
            </Box>
            <StepContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "12px",
                  width: "100%",
                  backgroundColor: "white",
                  px: 2,
                  boxShadow: "0px 12px 15px 0px #4B71970D",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "grab",
                  }}
                >
                  <DragIcon />
                </Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    width: "100%",
                    pl: 3,
                    py: 2.5,
                  }}
                >
                  {sequence.subject
                    ? sequence.subject
                    : sequence.step > 1
                    ? "<Previous email's subject>"
                    : "<Empty subject>"}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {showDelete && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      {isDeleteSequenceLoading ? (
                        <CircularProgress size={20} sx={{ color: "#28287b" }} />
                      ) : (
                        <IconButton onClick={handleDelete}>
                          <DeleteIconBlack />
                        </IconButton>
                      )}
                    </Box>
                  )}
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <IconButton onClick={handleEdit}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {isCopySequenceLoading ? (
                      <CircularProgress size={20} sx={{ color: "#28287b" }} />
                    ) : (
                      <IconButton onClick={handleCopy}>
                        <CopyIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Box>
            </StepContent>
          </Box>
        )}
      </Draggable>
    </>
  );
};

const CampaignSequences = ({ campaign }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [sequenceId, setSequenceId] = useState(null);
  const [isEditorDialogOpen, setIsEditorDialogOpen] = useState(false);
  const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState(false);
  const [unsubscribeText, setUnsubscribeText] = useState("Click here to unsubscribe");
  const [wordCount, setWordCount] = useState(0);
  const [subjectCount, setSubjectCount] = useState(0);
  const [spamCount, setSpamCount] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [readingTime, setReadingTime] = useState(0);
  const [urlCount, setUrlCount] = useState(0);
  const [editorSubject, setEditorSubject] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [contentLength, setContentLength] = useState(false);
  const [cursorLocation, setCursorLoaction] = useState(1);

  const editorRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [writeEmail, { isLoading: isWriteEmailLoading }] = useWriteEmailMutation();
  const [optimizeEmail, { isLoading: isOptimizeEmailLoading }] = useOptimizeEmailMutation();
  const [createSequence, { isLoading: isCreateSequenceLoading }] = useCreateSequenceMutation();
  const [updateSequence, { isLoading: isUpdateSequenceLoading }] = useUpdateSequenceMutation();
  const [updateSequenceOrder] = useUpdateSequenceOrderMutation();
  const [sequenceStepCount, setSequenceStepCount] = useState(0);

  const handleAddStepClick = () => {
    setSequenceStepCount(campaign?.sequences?.length + 1);
    setSequenceId(null);
    setEditorSubject("");
    setEditorContent("");
    setIsEditorDialogOpen(true);
  };

  const handleEditClick = (id) => {
    const sequence = campaign.sequences.find((s) => s._id === id);
    setSequenceStepCount(sequence.step);
    setSequenceId(id);
    setEditorSubject(sequence.subject);
    setEditorContent(sequence.body);
    setIsEditorDialogOpen(true);
  };

  const getEmailBodyFromPrompt = async (prompt) => {
    try {
      if (prompt === "") toast.error("Template body cannot be empty.");
      else {
        const body = await writeEmail({ prompt }).unwrap();
        setEditorContent(body);
      }
    } catch (err) {
      toast.error(err.data.error.message);
    }
  };

  const handleSaveSequenceClick = async () => {
    if (editorContent === "") {
      toast.error("Please enter body to continue");
      return;
    }
    const data = { subject: editorSubject, body: editorContent };
    try {
      if (sequenceId) {
        const { message } = await updateSequence({ id: sequenceId, data }).unwrap();
        toast.success(message);
      } else {
        const { message } = await createSequence({ id: campaign._id, data }).unwrap();
        window.Intercom("trackEvent", "Campaign step added");
        toast.success(message);
      }
    } catch (error) {
      toast.error(error.data.error.message);
    } finally {
      setIsEditorDialogOpen(false);
    }
  };

  function hasMoreThanFiveWords(str) {
    const specialCharsRegex = /[!@#$%^&*()_+{}/[/]:;<>,.?~\\/-/]/g;
    str = str.replace(specialCharsRegex, "").replace("  ", " ");
    const words = str.split(/\s+/); // Split the string by whitespace characters
    if (words?.length >= 5) setContentLength(true); // Check if the number of words is greater than 5
    else setContentLength(false);
  }

  const handleOptimizeClick = async () => {
    if (!editorContent) return toast.error("Template body cannot be empty.");
    try {
      const optimized = await optimizeEmail({ email: editorContent }).unwrap();
      setEditorContent(optimized);
    } catch (err) {
      toast.error(err.data.error.message);
    }
  };

  const handleDragEnd = async ({ source, destination }) => {
    try {
      const fromStep = source.index + 1;
      const toStep = destination.index + 1;

      if (fromStep === toStep) return;

      await updateSequenceOrder({ id: campaign._id, data: { fromStep, toStep } }).unwrap();
    } catch (err) {
      toast.error(err.data.error.message);
    }
  };

  function handleReadingTime(paragraph, wordsPerMinute = 200) {
    const wordsArray = paragraph?.trim()?.split(/\s+/);
    const totalWords = wordsArray?.length;
    const readingTimeMinutes = totalWords / wordsPerMinute;
    const readingTime = Math.ceil(readingTimeMinutes * 60);
    return readingTime;
  }

  function handleQuestions(paragraph) {
    const questionMarks = paragraph?.match(/\?+/g);
    return questionMarks ? questionMarks?.length : 0;
  }

  function highlightSpam(spamArray) {
    const iframe = document.getElementsByClassName("tox-edit-area__iframe")[0];
    var box = iframe.contentWindow.document.getElementById("tinymce");

    let text = box.innerHTML;
    text = text.replace(
      /(<span class="spam-word" style="border-bottom:3px solid red;">|<\/span>)/gim,
      ""
    );

    let newText = text;
    for (let i = 0; i < spamArray?.length; i++) {
      const regex = new RegExp(`\\b${spamArray[i]}\\b`, "gi");

      newText = newText.replace(
        regex,
        '<span class="spam-word" style="border-bottom:3px solid red;">$&</span>'
      );
    }

    box.innerHTML = newText;
    return;
  }
  function handleSpamCount(subject, paragraph) {
    const string_to_check = paragraph + " " + subject;
    const spamObj = checkSpamWords(string_to_check);

    highlightSpam(spamObj.spam);
    return spamObj.count;
  }

  function handleUrlCount(paragraph) {
    let urlCount = 0;
    const urlRegex =
      /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}|(?:\d{1,3}\.){3}\d{1,3})(?::\d{2,5})?(?:\/[^\s]*)?$/;
    const wordsArray = paragraph?.trim()?.split(/\s+/);
    wordsArray?.forEach((ele) => {
      if (urlRegex.test(ele)) urlCount += 1;
    });
    return urlCount;
  }

  function handleSubmit(event) {
    event.preventDefault();
    const subjectCountBar = editorSubject;
    const wordCountBar = editorContent;
    setSubjectCount(subjectCountBar.split(/\s+/).filter(Boolean)?.length);
    setWordCount(wordCountBar.split(/\s+/).filter(Boolean)?.length);
    setReadingTime(() => handleReadingTime(wordCountBar));
    setUrlCount(() => handleUrlCount(wordCountBar));
    setQuestionCount(() => handleQuestions(wordCountBar));
    setSpamCount(() => handleSpamCount(subjectCountBar, wordCountBar));
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { data: variables } = useGetCampaignVariablesQuery(campaign._id);

  const handleEditorClick = () => {
    setCursorLoaction(1);
  };

  const handleSubjectClick = () => {
    setCursorLoaction(0);
  };

  const insertContent = (value) => {
    if (cursorLocation === 0) {
      setEditorSubject(editorSubject + value);
    } else {
      if (editorRef.current) {
        const editor = editorRef.current;
        editor.insertContent(value);
      }
    }
  };

  const insertUnsubscribeLink = () => {
    if (editorRef.current) {
      const editor = editorRef.current;
      editor.insertContent(`<a href='/'>${unsubscribeText}</a>`);
      setUnsubscribeText("Click here to unsubscribe");
    }
    setIsUnsubscribeOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: campaign?.sequences?.length ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "20px",
              letterSpacing: "0px",
              color: "#28287B",
            }}
          >
            Total Steps in Sequence: {campaign?.sequences?.length}
          </Typography>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#164694",
                boxShadow: 10,
              },
              textAlign: "left",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "18px",
              letterSpacing: "0em",
              color: "white",
              backgroundColor: "#0071F6",
              borderRadius: "8px",
              px: 1.5,
              py: 1.5,
            }}
            variant="outlined"
            size="large"
            onClick={handleAddStepClick}
          >
            Add step
          </Button>
        </Box>
        <Box sx={{ width: "100%", mt: 2 }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                    {campaign?.sequences?.map((sequence, index) => (
                      <Step
                        key={sequence._id}
                        onClick={() => {
                          setActiveStep(index);
                        }}
                        expanded={true}
                        sx={{ "& div": { mb: 0 } }}
                      >
                        <DraggableSequence
                          sequence={sequence}
                          index={index}
                          showWaitDays={index !== 0}
                          showDelete={campaign?.sequences?.length > 1}
                          onEditClick={handleEditClick}
                        />
                      </Step>
                    ))}
                  </Stepper>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Box>
      {!campaign?.sequences?.length && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              flexDirection: "column",
              backgroundColor: "white",
              height: "calc(100vh - 220px)",
              boxShadow: "0px 12px 15px 0px #4B71970D",
              borderRadius: "12px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <HeroIcon />
            </Box>

            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "25px",
                letterSpacing: "0em",
                color: "#28287B",
                mt: 3,
              }}
            >
              Start by creating a new sequence here
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0em",
                color: "#8181B0",
                mt: 2,
              }}
            >
              Try to keep yours emails short, sweet and personal
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                mt: 4,
              }}
            >
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "#164694",
                    boxShadow: 10,
                  },
                  textAlign: "left",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "18px",
                  letterSpacing: "0em",
                  color: "white",
                  backgroundColor: "#0071F6",
                  borderRadius: "8px",
                  px: 1.5,
                  py: 1.5,
                }}
                variant="outlined"
                size="large"
                onClick={handleAddStepClick}
              >
                <Box
                  sx={{ mr: 1, display: "flex", justifyContent: "center", alignItems: "center" }}
                ></Box>
                Add step
              </Button>
            </Box>
          </Box>
        </>
      )}
      <Dialog
        open={isEditorDialogOpen}
        onClose={() => setIsEditorDialogOpen(false)}
        fullWidth
        maxWidth="md"
        sx={{ backgroundColor: "rgba(4, 4, 30, 0.5)" }}
        disableEnforceFocus={true}
        fullScreen={isMobile}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "25px",
            letterSpacing: "0em",
            color: "#28287B",
            position: "relative",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>Send automatic email</Typography>
          <IconButton
            sx={{ position: "absolute", right: 0, top: 0 }}
            onClick={() => setIsEditorDialogOpen(false)}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              borderRadius: 2,
              border: "1px solid rgba(0,0,0,0.1)",
              p: 2,
              pb: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <form onSubmit={handleSubmit} style={{ width: "100%" }} >
              <Box
                sx={{
                  width: "100%",

                  justifyContent: "center",
                  alignContent: "center",
                  display: "flex",
                }}
              >
                <Grid
                  container
                  // spacing={3}
                  maxWidth={"md"}
                  sx={{ position: "relative", ml: 0, mt: 0 }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      px: 2,
                      py: 1,
                      backgroundColor: theme.palette.grey[100],
                      borderRadius: "16px",
                    }}
                  >
                    <Grid item xs={12} md={8} sx={{ borderRadius: "10px" }}>
                      <Stack spacing={2}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#28287B",
                              mr: 2,
                            }}
                          >
                            Subject:
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            sx={{
                              "& div input": {
                                border: "none",
                                fontWeight: 600,
                              },
                              "& div fieldset": {
                                border: "none",
                              },
                            }}
                            placeholder={
                              sequenceStepCount > 1
                                ? "Leave empty to use previous step's subject"
                                : "Your subject"
                            }
                            name="subject"
                            value={editorSubject}
                            onChange={(e) => setEditorSubject(e.target.value)}
                            onClick={handleSubjectClick}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={4} sx={{ display: { xs: "none", md: "block" } }}>
                      <Box
                        sx={{
                          // borderLeft: "1px solid rgba(0,0,0,0.1)",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          pl: 1,
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Tooltip title={"Insert Unsubscribe link"} arrow placement="top">
                          <Button
                            variant="contained"
                            sx={{
                              mr: 1,
                            }}
                            onClick={() => setIsUnsubscribeOpen(true)}
                          >
                            <PersonRemove />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title={
                            cursorLocation === 1
                              ? "Insert variables in body"
                              : "Insert variables in subject"
                          }
                          arrow
                          placement="top"
                        >
                          <Button
                            variant="contained"
                            disabled={variables && variables?.length === 0}
                            sx={{
                              backgroundColor: "#E7F0FF",
                              "&:hover": {
                                backgroundColor: "#E7F0FF",
                              },
                              mr: 1,
                            }}
                            onClick={handleClick}
                          >
                            <BoltIcon
                              color={
                                variables && variables?.length === 0
                                  ? theme.palette.primary.contrastText
                                  : theme.palette.primary.main
                              }
                            />
                          </Button>
                        </Tooltip>

                        <Tooltip title="" arrow placement="top">
                          <Button
                            onClick={() => {
                              getEmailBodyFromPrompt(editorContent);
                            }}
                            variant="outlined"
                            sx={{
                              mr: 1,
                              borderColor: "#28287B",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: 24,
                                height: 24,
                              }}
                            >
                              {isWriteEmailLoading ? (
                                <CircularProgress size={16} thickness={5} />
                              ) : (
                                <OpenAiIcon />
                              )}
                            </Box>
                          </Button>
                        </Tooltip>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                            onClick={handleSaveSequenceClick}
                          >
                            {isCreateSequenceLoading || isUpdateSequenceLoading ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  py: 0.55,
                                }}
                              >
                                {" "}
                                <CircularProgress size={16} thickness={5} sx={{ color: "white" }} />
                              </Box>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              px: 0.5,
                              py: "8.3px",
                              minWidth: "auto",
                            }}
                          >
                            <ArrowDropDown fontSize="small" />
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  {isMobile && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          // borderLeft: "1px solid rgba(0,0,0,0.1)",
                          display: "flex",
                          justifyContent: { xs: "space-between", sm: "flex-end" },
                          alignItems: "center",

                          width: "100%",
                          height: "100%",
                          mt: 2,
                        }}
                      >
                        <Tooltip title={"Insert Unsubscribe link"} arrow placement="top">
                          <Button
                            variant="contained"
                            sx={{
                              mr: 1,
                            }}
                            onClick={() => setIsUnsubscribeOpen(true)}
                          >
                            <PersonRemove />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title={
                            cursorLocation === 1
                              ? "Insert variables in body"
                              : "Insert variables in subject"
                          }
                          arrow
                          placement="top"
                        >
                          <Button
                            variant="contained"
                            disabled={variables && variables?.length === 0}
                            sx={{
                              backgroundColor: "#E7F0FF",
                              "&:hover": {
                                backgroundColor: "#E7F0FF",
                              },
                              mr: 1,
                            }}
                            onClick={handleClick}
                          >
                            <BoltIcon
                              color={
                                variables && variables?.length === 0
                                  ? theme.palette.primary.contrastText
                                  : theme.palette.primary.main
                              }
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip title="" arrow placement="top">
                          <Button
                            onClick={() => {
                              getEmailBodyFromPrompt(editorContent);
                            }}
                            variant="outlined"
                            sx={{
                              mr: 1,
                              borderColor: "#28287B",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: 24,
                                height: 24,
                              }}
                            >
                              {isWriteEmailLoading ? (
                                <CircularProgress size={16} thickness={5} />
                              ) : (
                                <OpenAiIcon />
                              )}
                            </Box>
                          </Button>
                        </Tooltip>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                            onClick={handleSaveSequenceClick}
                          >
                            {isCreateSequenceLoading || isUpdateSequenceLoading ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  py: 0.55,
                                }}
                              >
                                {" "}
                                <CircularProgress size={16} thickness={5} sx={{ color: "white" }} />
                              </Box>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              px: 0.5,
                              py: "8.3px",
                              minWidth: "auto",
                            }}
                          >
                            <ArrowDropDown fontSize="small" />
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{ py: 1, minHeight: { xs: "500px", sm: "fit-content" } }}
                  >
                    <Editor
                      onEditorChange={(value) => {
                        setEditorContent(value);
                        hasMoreThanFiveWords(value);
                      }}
                      onClick={handleEditorClick}
                      value={editorContent}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      init={{
                        height: "100%",
                        selector: "textarea",
                        init_instance_callback: function () {
                          var freeTiny = document.querySelector(".tox .tox-notification--in");
                          freeTiny.style.display = "none";
                        },
                        menubar: false,
                        plugins: [
                          "mentions advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media paste code help wordcount",
                          "autolink",
                          "link",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | link | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | emoticons| help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        emoticons_append: {
                          custom_mind_explode: {
                            keywords: ["brain", "mind", "explode", "blown"],
                            char: "🤯",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Stack spacing={3} sx={{ p: 2 }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "20px",
                          color: "#28287B",
                        }}
                      >
                        Email template insights
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#28287B",
                              
                            }}
                          >
                            Subject Count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#8181B0",
                            }}
                          >
                            {subjectCount}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={subjectCount}
                          maxCountOf={maxSubjectCount}
                          minRange={3}
                          maxRange={5}
                          barColor={
                            subjectCount > 8 || subjectCount < 3
                              ? "red"
                              : subjectCount > 5
                              ? "orange"
                              : "green"
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#28287B",
                            }}
                          >
                            Word Count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#8181B0",
                            }}
                          >
                            {wordCount}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={wordCount}
                          maxCountOf={maxWordCount}
                          minRange={16}
                          maxRange={150}
                          barColor={
                            wordCount > 300 || wordCount < 16
                              ? "red"
                              : wordCount > 150
                              ? "orange"
                              : "green"
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#28287B",
                            }}
                          >
                            Reading time
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#8181B0",
                            }}
                          >
                            {readingTime}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={readingTime}
                          maxCountOf={maxReadingTime}
                          minRange={11}
                          maxRange={60}
                          barColor={
                            readingTime >= 70
                              ? "red"
                              : readingTime > 60 && readingTime < 70
                              ? "yellow"
                              : "green"
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#28287B",
                            }}
                          >
                            URL Count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#8181B0",
                            }}
                          >
                            {urlCount}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={urlCount}
                          maxCountOf={maxLinks}
                          minRange={0}
                          maxRange={1}
                          barColor={
                            urlCount > 2 || urlCount < 0 ? "red" : urlCount > 1 ? "orange" : "green"
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#28287B",
                            }}
                          >
                            Question Count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#8181B0",
                            }}
                          >
                            {questionCount}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={questionCount}
                          maxCountOf={maxQuestions}
                          minRange={0}
                          maxRange={2}
                          barColor={
                            questionCount > 3 || questionCount < 0
                              ? "red"
                              : questionCount > 2
                              ? "orange"
                              : "green"
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#28287B",
                            }}
                          >
                            Spam word count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 700,
                              lineHeight: "16px",
                              color: "#8181B0",
                            }}
                          >
                            {spamCount}
                          </Typography>
                        </Box>

                        <CustomCounterProgress
                          countOf={spamCount}
                          maxCountOf={maxSpams}
                          minRange={0}
                          maxRange={15}
                          barColor={
                            spamCount > 10 || spamCount < 0
                              ? "red"
                              : spamCount > 7
                              ? "orange"
                              : "green"
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          mt: 1,
                          width: "100%",
                        }}
                      >
                        <Button
                          sx={{ px: 2, width: "30px" }}
                          color="primary"
                          variant="outlined"
                          type="submit"
                          id="submit-btn"
                          // onClick={handleSubmit}
                        >
                          Check
                        </Button>
                        {editorContent && contentLength && (
                          <Button
                            sx={{ px: 1, py: 1, width: "auto", ml: 2 }}
                            color="primary"
                            variant="contained"
                            type="Button"
                            onClick={handleOptimizeClick}
                            disabled={isOptimizeEmailLoading}
                          >
                            {isOptimizeEmailLoading ? (
                              <CircularProgress size={25} thickness={5} />
                            ) : (
                              "Optimize"
                            )}
                          </Button>
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // width: "fit-content",
            p: 1,
            width: "280px",
          }}
        >
          {variables?.map((item) => {
            return (
              <Button
                key={item.value}
                value={item.value}
                onClick={(e) => insertContent(e.currentTarget.getAttribute("value"))}
                fullWidth
                sx={{
                  py: 1,
                  px: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "#101828",
                  fontSize: "13px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                  <Typography
                    sx={{
                      color: "#28287B",
                      fontSize: "13px",
                      fontWeight: 700,
                      linHeight: "16px",
                      letterSpacing: "0px",
                    }}
                    value={item.value}
                  >
                    {item.key}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#8181B0",
                      fontSize: "11px",
                      fontWeight: 700,
                      linHeight: "14px",
                      letterSpacing: "0px",
                      ml: 1,
                    }}
                    value={item.value}
                  >
                    {item.value}
                  </Typography>
                </Box>
              </Button>
            );
          })}
        </Box>
      </Popover>
      <Dialog
        open={isUnsubscribeOpen}
        onClose={() => setIsUnsubscribeOpen(false)}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "28px",
            color: "#28287B",
          }}
        >
          Insert Unsubscribe Link
        </DialogTitle>
        <DialogContent>
          <InputLabel>Display as</InputLabel>
          <TextField
            id="standard-basic"
            variant="standard"
            value={unsubscribeText}
            fullWidth
            onChange={(e) => setUnsubscribeText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsUnsubscribeOpen(false)}>Cancel</Button>
          <Button onClick={insertUnsubscribeLink}>Insert Unsubscribe Link</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampaignSequences;
