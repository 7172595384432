import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useGetAllEmailAnalyticsQuery } from 'services/user-service';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableSortLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Button,
  Tooltip
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import GlobalStyles from '@mui/material/GlobalStyles';
import LoaderCircle from 'components/LoaderCircle';
import EmailAnalyticsChart from './EmailAnalyticsChart';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Search from '../../layout/MainLayout/Header/HeaderContent/Search';

function createData(email, name, count, type, createdAt, updatedAt, userId, _id) {
  return {
    email,
    name,
    count,
    type,
    createdAt,
    updatedAt,
    userId,
    _id
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getFormaDate(dateTimeString) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const date = new Date(dateTimeString);
  const formattedDate = `${monthNames[String(date.getMonth())]} ${String(date.getDate()).padStart(2, '0')}, ${date.getFullYear()}`;
  return formattedDate;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'email',
    align: 'center',
    disablePadding: false,
    label: 'EMAIL'
  },
  {
    id: 'name',
    align: 'center',
    disablePadding: false,
    label: 'USER NAME'
  },
  {
    id: 'count',
    align: 'center',
    disablePadding: true,
    label: 'COUNT'
  },
  {
    id: 'type',
    align: 'center',
    disablePadding: true,
    label: 'TYPE'
  },
  {
    id: 'createdAt',
    align: 'center',
    disablePadding: false,
    label: 'CREATED AT'
  },
  {
    id: 'updatedAt',
    align: 'center',
    disablePadding: true,
    label: 'UPDATED AT'
  },
  {
    id: 'action',
    align: 'center',
    disablePadding: true,
    label: 'ACTION'
  }
];

export default function EmailAnalytics() {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState('createdAt');
  const { searchData } = useSelector((state) => state.search);
  const [order, setOrder] = useState('desc');
  const [dateRange, setDateRange] = useState({});
  const [page, setPage] = useState(0);
  const [totalUser, setTotaluser] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [refetchUser, setRefetchUser] = useState();
  const [userId, setUserId] = useState(null);
  const [rows, setRows] = useState([]);
  const { id } = useParams();
  const {
    data,
    refetch,
    isLoading: refetchLoader
  } = useGetAllEmailAnalyticsQuery({
    sortBy: orderBy,
    page: page + 1,
    order,
    ...(userId && { id: userId }),
    ...(dateRange?.start && { start: dateRange?.start }),
    ...(dateRange?.end && { end: dateRange?.end }),
    ...(searchData?.length && { search: searchData })
  });

  function OrderTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => {
            if (id && headCell.id === 'action') {
              return null;
            }

            return (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  backgroundColor: 'rgb(242, 244, 246)',
                  color: 'rgb(40, 40, 123)',
                  fontSize: '13px',
                  fontWeight: '500'
                }}
              >
                {headCell.id !== 'action' ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id && (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    )}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  OrderTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string
  };

  useEffect(() => {
    setIsLoading(true);
    setPage(0);
    refetch();
  }, [orderBy, order, userId, dateRange, searchData]);

  const handleChangePage = (_event, newPage) => {
    setIsLoading(true);
    setPage(newPage);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (refetchUser) {
      setIsLoading(true);
      setPage(0);
      refetch();
      setRefetchUser(false);
    }
  }, [refetchUser, refetch, setIsLoading, setPage, setRefetchUser]);

  useEffect(() => {
    if (typeof refetchLoader !== 'undefined') {
      setIsLoading(refetchLoader);
    }
  }, [refetchLoader, setIsLoading]);

  const handleUserDataView = (id) => {
    setUserId(id);
    let encoded = window.btoa(id);
    navigate(`/manage/emailAnalytics/${encoded}`);
  };

  useEffect(() => {
    if (data?.emailAnalytics?.length) {
      const rows = data?.emailAnalytics?.map((cur) => {
        return createData(
          cur?.user?.email || '-',
          cur?.user?.name?.first + ' ' + cur?.user?.name?.last || '-',
          cur?.count || '-',
          cur?.type,
          cur?.createdAt,
          cur?.updatedAt,
          cur?.user?._id,
          cur?._id
        );
      });
      setIsLoading(false);
      setTotaluser(Number(data?.total) || 0);
      setRows(rows);
    } else {
      setRows([]);
      setTotaluser(0);
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (id) {
      setUserId(window.atob(id));
    } else {
      setUserId(null);
    }
  }, [id]);

  return (
    <>
      {isLoading && refetchLoader && <LoaderCircle />}
      <>
        <Box sx={{ display: 'flex', alignItem: 'center' }}>
          <Typography
            sx={{
              color: '#28287B',
              fontSize: '30px',
              fontWeight: 600,
              lineHeight: '28px',
              letterSpacing: '0px',
              margin: '5px 0px 20px 0px'
            }}
          >
            Email Analytics
          </Typography>

          <Tooltip
            title="It displays analytics for warm-up and campaign-sent emails with respect to the added email accounts."
            placement="right"
          >
            <InfoOutlinedIcon
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                color: '#28287B'
              }}
            />
          </Tooltip>
        </Box>
        <EmailAnalyticsChart userId={userId} setDateRange={setDateRange} />
        <Box sx={{ width: { xs: '97%', sm: '30%' }, margin: '10px' }}>
          <Search />
        </Box>
        <Box
          sx={{
            border: '1px solid #ebebeb',
            borderRadius: '12px'
          }}
        >
          <TableContainer
            sx={{
              width: '100%',
              // height: 'calc(100vh - 200px)',
              overflowx: 'auto',
              maxHeight: '100vh', // 80vh
              transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              boxShadow: 'rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
              borderRadius: '6px',
              color: 'rgb(16, 24, 40)',
              '& td, & th': { whiteSpace: 'nowrap' }
            }}
          >
            <GlobalStyles
              styles={{
                '*::-webkit-scrollbar': {
                  width: '8px',
                  height: '8px'
                },
                '*::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgb(228, 228, 229)',
                  borderRadius: '10px',
                  border: '1px solid rgba(0, 0, 0, 0)'
                },
                '*::-webkit-scrollbar-track': {
                  borderRadius: '60px',
                  width: '4px',
                  backgroundColor: 'rgb(242, 244, 246)'
                }
              }}
            />
            <Table
              aria-labelledby="tableTitle"
              sx={{
                '& .MuiTableCell-root:first-of-type': {
                  pl: 2
                },
                '& .MuiTableCell-root:last-of-type': {
                  pr: 3
                }
              }}
              stickyHeader
            >
              <OrderTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0
                        }
                      }}
                      tabIndex={-1}
                      key={row._id}
                    >
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.count}</TableCell>
                      <TableCell align="center">{row.type}</TableCell>
                      <TableCell align="center">{getFormaDate(row.createdAt)}</TableCell>
                      <TableCell align="center">{getFormaDate(row.updatedAt)}</TableCell>
                      {!id && (
                        <TableCell align="center">
                          {
                            <Button
                              type="button"
                              className="py-3 px-4 flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white b hover:bg-blue-700"
                              sx={{
                                padding: '8px 25px',
                                border: 'none',
                                backgroundColor: '#1890ff',
                                color: 'white',
                                '&:hover': {
                                  backgroundColor: '#0b7be3'
                                }
                              }}
                              onClick={() => {
                                handleUserDataView(row.userId);
                              }}
                            >
                              View
                            </Button>
                          }
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              border: '1px solid #f0f0f0',
              borderRadius: 2
            }}
            component="div"
            rowsPerPageOptions={[10]}
            rowsPerPage={10}
            count={totalUser}
            page={page}
            onPageChange={handleChangePage}
          />
        </Box>
      </>
    </>
  );
}
