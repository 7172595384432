import jwtDecode from 'jwt-decode';
import { getAuthToken } from './localstorage.helper';

export default function isLoggedIn() {
  const authToken = getAuthToken();
  return authToken && !isJwtExpired(authToken);
}

function isJwtExpired(token) {
  if (typeof token !== 'string' || !token) throw new Error('Invalid token provided');

  let isJwtExpired = false;
  const { exp } = jwtDecode(token);
  const currentTime = new Date().getTime() / 1000;

  if (currentTime > exp) isJwtExpired = true;

  return isJwtExpired;
}
