export const EditIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8001 19.5517H19.8001M4.2002 19.5517L8.56618 18.672C8.79796 18.6253 9.01077 18.5111 9.17791 18.3439L18.9516 8.56486C19.4202 8.096 19.4199 7.33602 18.9509 6.86755L16.8805 4.79948C16.4117 4.33121 15.6521 4.33153 15.1837 4.80019L5.40896 14.5802C5.24214 14.7471 5.12824 14.9595 5.0815 15.1908L4.2002 19.5517Z"
          stroke="#28287B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
